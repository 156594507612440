/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Division } from '../models/division';
import { PostDivision } from '../models/post-division';
import { PatchDivision } from '../models/patch-division';

/**
 * Divisions lookup.
 */
@Injectable({
  providedIn: 'root',
})
class DivisionsService extends __BaseService {
  static readonly getAllDivisionsApiPath = '/companies/{companyId}/divisions';
  static readonly postAllDivisionsApiPath = '/companies/{companyId}/divisions';
  static readonly getSingleDivisionApiPath = '/companies/{companyId}/divisions/{divisionId}';
  static readonly patchSingleDivisionApiPath = '/companies/{companyId}/divisions/{divisionId}';
  static readonly deleteDivisionsChildrenApiPath = '/companies/{companyId}/divisions/{divisionId}/children';
  static readonly getDivisionsChildrenApiPath = '/companies/{companyId}/divisions/{divisionId}/children';
  static readonly patchDivisionsChildrenApiPath = '/companies/{companyId}/divisions/{divisionId}/children';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DivisionsService.GetAllDivisionsApiParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `type`:
   *
   * - `q`: https://confluence.zonarsystems.net/display/VHDS/Entity+API+Query+String+Syntax
   *
   * - `legacyAccountCode`:
   *
   * - `divisionIds`:
   *
   * - `df`: https://confluence.zonarsystems.net/display/VHDS/Entity+API+Query+String+Syntax
   *
   * - `default_operator`: https://confluence.zonarsystems.net/display/VHDS/Entity+API+Query+String+Syntax
   *
   * @return All divisions in the given company.
   */
  getAllDivisionsApiResponse(params: DivisionsService.GetAllDivisionsApiParams): __Observable<__StrictHttpResponse<Array<Division>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.type != null) __params = __params.set('type', params.type.toString());
    if (params.q != null) __params = __params.set('q', params.q.toString());
    if (params.legacyAccountCode != null) __params = __params.set('legacyAccountCode', params.legacyAccountCode.toString());
    (params.divisionIds || []).forEach(val => {if (val != null) __params = __params.append('divisionIds', val.toString())});
    if (params.df != null) __params = __params.set('df', params.df.toString());
    if (params.defaultOperator != null) __params = __params.set('default_operator', params.defaultOperator.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/companies/${encodeURIComponent(String(params.companyId))}/divisions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Division>>;
      })
    );
  }
  /**
   * @param params The `DivisionsService.GetAllDivisionsApiParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `type`:
   *
   * - `q`: https://confluence.zonarsystems.net/display/VHDS/Entity+API+Query+String+Syntax
   *
   * - `legacyAccountCode`:
   *
   * - `divisionIds`:
   *
   * - `df`: https://confluence.zonarsystems.net/display/VHDS/Entity+API+Query+String+Syntax
   *
   * - `default_operator`: https://confluence.zonarsystems.net/display/VHDS/Entity+API+Query+String+Syntax
   *
   * @return All divisions in the given company.
   */
  getAllDivisionsApi(params: DivisionsService.GetAllDivisionsApiParams): __Observable<Array<Division>> {
    return this.getAllDivisionsApiResponse(params).pipe(
      __map(_r => _r.body as Array<Division>)
    );
  }

  /**
   * @param params The `DivisionsService.PostAllDivisionsApiParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `companyId`:
   *
   * @return Division created.
   */
  postAllDivisionsApiResponse(params: DivisionsService.PostAllDivisionsApiParams): __Observable<__StrictHttpResponse<Division>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/companies/${encodeURIComponent(String(params.companyId))}/divisions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Division>;
      })
    );
  }
  /**
   * @param params The `DivisionsService.PostAllDivisionsApiParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `companyId`:
   *
   * @return Division created.
   */
  postAllDivisionsApi(params: DivisionsService.PostAllDivisionsApiParams): __Observable<Division> {
    return this.postAllDivisionsApiResponse(params).pipe(
      __map(_r => _r.body as Division)
    );
  }

  /**
   * @param params The `DivisionsService.GetSingleDivisionApiParams` containing the following parameters:
   *
   * - `divisionId`:
   *
   * - `companyId`:
   *
   * @return Division returned.
   */
  getSingleDivisionApiResponse(params: DivisionsService.GetSingleDivisionApiParams): __Observable<__StrictHttpResponse<Division>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/companies/${encodeURIComponent(String(params.companyId))}/divisions/${encodeURIComponent(String(params.divisionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Division>;
      })
    );
  }
  /**
   * @param params The `DivisionsService.GetSingleDivisionApiParams` containing the following parameters:
   *
   * - `divisionId`:
   *
   * - `companyId`:
   *
   * @return Division returned.
   */
  getSingleDivisionApi(params: DivisionsService.GetSingleDivisionApiParams): __Observable<Division> {
    return this.getSingleDivisionApiResponse(params).pipe(
      __map(_r => _r.body as Division)
    );
  }

  /**
   * @param params The `DivisionsService.PatchSingleDivisionApiParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `divisionId`:
   *
   * - `companyId`:
   */
  patchSingleDivisionApiResponse(params: DivisionsService.PatchSingleDivisionApiParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;


    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/companies/${encodeURIComponent(String(params.companyId))}/divisions/${encodeURIComponent(String(params.divisionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `DivisionsService.PatchSingleDivisionApiParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `divisionId`:
   *
   * - `companyId`:
   */
  patchSingleDivisionApi(params: DivisionsService.PatchSingleDivisionApiParams): __Observable<null> {
    return this.patchSingleDivisionApiResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Delete relationship between divisionId and the child(ren) division(s) contained in request json
   * @param params The `DivisionsService.DeleteDivisionsChildrenApiParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `divisionId`:
   *
   * - `companyId`:
   */
  deleteDivisionsChildrenApiResponse(params: DivisionsService.DeleteDivisionsChildrenApiParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/companies/${encodeURIComponent(String(params.companyId))}/divisions/${encodeURIComponent(String(params.divisionId))}/children`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete relationship between divisionId and the child(ren) division(s) contained in request json
   * @param params The `DivisionsService.DeleteDivisionsChildrenApiParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `divisionId`:
   *
   * - `companyId`:
   */
  deleteDivisionsChildrenApi(params: DivisionsService.DeleteDivisionsChildrenApiParams): __Observable<null> {
    return this.deleteDivisionsChildrenApiResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `DivisionsService.GetDivisionsChildrenApiParams` containing the following parameters:
   *
   * - `divisionId`:
   *
   * - `companyId`:
   *
   * - `allChildren`:
   *
   * @return Child divisions.
   */
  getDivisionsChildrenApiResponse(params: DivisionsService.GetDivisionsChildrenApiParams): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.allChildren != null) __params = __params.set('allChildren', params.allChildren.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/companies/${encodeURIComponent(String(params.companyId))}/divisions/${encodeURIComponent(String(params.divisionId))}/children`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * @param params The `DivisionsService.GetDivisionsChildrenApiParams` containing the following parameters:
   *
   * - `divisionId`:
   *
   * - `companyId`:
   *
   * - `allChildren`:
   *
   * @return Child divisions.
   */
  getDivisionsChildrenApi(params: DivisionsService.GetDivisionsChildrenApiParams): __Observable<Array<string>> {
    return this.getDivisionsChildrenApiResponse(params).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * @param params The `DivisionsService.PatchDivisionsChildrenApiParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `divisionId`:
   *
   * - `companyId`:
   */
  patchDivisionsChildrenApiResponse(params: DivisionsService.PatchDivisionsChildrenApiParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;


    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/companies/${encodeURIComponent(String(params.companyId))}/divisions/${encodeURIComponent(String(params.divisionId))}/children`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `DivisionsService.PatchDivisionsChildrenApiParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `divisionId`:
   *
   * - `companyId`:
   */
  patchDivisionsChildrenApi(params: DivisionsService.PatchDivisionsChildrenApiParams): __Observable<null> {
    return this.patchDivisionsChildrenApiResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module DivisionsService {

  /**
   * Parameters for getAllDivisionsApi
   */
  export interface GetAllDivisionsApiParams {
    companyId: string;
    type?: string;

    /**
     * https://confluence.zonarsystems.net/display/VHDS/Entity+API+Query+String+Syntax
     */
    q?: string;
    legacyAccountCode?: string;
    divisionIds?: Array<string>;

    /**
     * https://confluence.zonarsystems.net/display/VHDS/Entity+API+Query+String+Syntax
     */
    df?: string;

    /**
     * https://confluence.zonarsystems.net/display/VHDS/Entity+API+Query+String+Syntax
     */
    defaultOperator?: 'and' | 'or';
  }

  /**
   * Parameters for postAllDivisionsApi
   */
  export interface PostAllDivisionsApiParams {
    payload: PostDivision;
    companyId: string;
  }

  /**
   * Parameters for getSingleDivisionApi
   */
  export interface GetSingleDivisionApiParams {
    divisionId: string;
    companyId: string;
  }

  /**
   * Parameters for patchSingleDivisionApi
   */
  export interface PatchSingleDivisionApiParams {
    payload: PatchDivision;
    divisionId: string;
    companyId: string;
  }

  /**
   * Parameters for deleteDivisionsChildrenApi
   */
  export interface DeleteDivisionsChildrenApiParams {
    payload: Array<string>;
    divisionId: string;
    companyId: string;
  }

  /**
   * Parameters for getDivisionsChildrenApi
   */
  export interface GetDivisionsChildrenApiParams {
    divisionId: string;
    companyId: string;
    allChildren?: boolean;
  }

  /**
   * Parameters for patchDivisionsChildrenApi
   */
  export interface PatchDivisionsChildrenApiParams {
    payload: Array<string>;
    divisionId: string;
    companyId: string;
  }
}

export { DivisionsService }
