<div class="edit-modal mat-app-background zonar-default-theme">
    <div class="edit-modal-title">
      <span class="edit-title">{{config.title}} for {{date | zonarTimestamp: 'date'}}</span>
        <zui-icon class="exit-icon" icon="close" (click)="close()"></zui-icon>
    </div>
    <hr class="separator">

    <div class="edit-modal-input-row">
        <mat-form-field appearance="outline" color="accent">
            <mat-label>Start time</mat-label>
            <input matInput
                   [formControl]="timeFormControl"
                   type="time"
                   step="1"
                   (keyup.enter)="convertInputToEpoch()" (blur)="convertInputToEpoch()">
            <mat-error *ngIf="timeFormControl.hasError('required')">
                You must enter time.
            </mat-error>
            <mat-error *ngIf="!timeFormControl.hasError('required') && timeFormControl.hasError('timeRange')">
                Can not insert at time
            </mat-error>
        </mat-form-field>

        <!-- Deferral version of the duty status select -->
        <mat-form-field appearance="outline" color="accent" *ngIf="showDeferralSelect">
            <mat-label>Deferral</mat-label>
            <mat-select [disabled]="true" [(ngModel)]="dutyStatus" class="deferral-select">
                <mat-option value="DEFERRAL_DAY_1">Deferral Day 1</mat-option>
                <mat-option value="DEFERRAL_DAY_2">Deferral Day 2</mat-option>
                <mat-option value="DEFERRAL_NONE">None</mat-option>
            </mat-select>
        </mat-form-field>

        <!-- Operating zone version of the duty status select -->
        <mat-form-field appearance="outline" color="accent" *ngIf="showOperatingZoneSelect">
            <mat-label>Operating zone</mat-label>
            <mat-select [disabled]="true" [(ngModel)]="dutyStatus" class="operating-zone-select">
                <mat-option value="N_60">North of 60</mat-option>
                <mat-option value="S_60">South of 60</mat-option>
                <mat-option value="US">United States</mat-option>
                <mat-option value="UNKNOWN_OPERATING_ZONE">Unknown operating zone</mat-option>
            </mat-select>
        </mat-form-field>

        <!-- Default version of the duty status select -->
        <mat-form-field appearance="outline" color="accent" *ngIf="showDefaultDutyStatusSelect">
            <mat-label>Duty status</mat-label>
            <mat-select [disabled]="dsDisabled()" [(ngModel)]="dutyStatus" (ngModelChange)="updateValues()" class="duty-status-select">
                <mat-option value="OFF_DUTY">OFF</mat-option>
                <mat-option value="SB">SB</mat-option>
                <mat-option value="DRIVING">D</mat-option>
                <mat-option value="ON_DUTY">ON</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="edit-modal-input-row">
        <mat-form-field appearance="outline" color="accent">
            <mat-label>Total vehicle {{distanceLabel}}</mat-label>
            <input matInput
                   [formControl]="odoFormControl" type="number"
                   (ngModelChange)="updateValues()" required>
            <mat-error *ngIf="odoFormControl.hasError('min')">
                Distance must be a positive number.
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" color="accent">
            <mat-label>Location</mat-label>
            <input matInput
                   [formControl]="locationFormControl"
                   (ngModelChange)="updateValues()" required  maxlength="60">
            <mat-error *ngIf="locationFormControl.hasError('required')">
                You must enter location.
            </mat-error>
            <mat-error *ngIf="!locationFormControl.hasError('required') && locationFormControl.hasError('minlength')">
                Location must be at least 5 characters.
            </mat-error>
        </mat-form-field>
    </div>

    <div class="edit-modal-input-row" *ngIf="showDeferralTime">
        <mat-form-field appearance="outline">
            <mat-label>Time Deferred</mat-label>
            <input matInput disabled="true" [value]="deferralTime" class="deferral-time">
        </mat-form-field>
    </div>

    <div class="edit-modal-input-row">
        <mat-form-field appearance="outline" id="full-input" color="accent">
            <mat-label>Notes</mat-label>
            <input matInput
                   [formControl]="notesFormControl"
                   (ngModelChange)="updateValues()" maxlength="60" required>
            <mat-error *ngIf="notesFormControl.hasError('required')">
                You must enter notes.
            </mat-error>
            <mat-error *ngIf="!notesFormControl.hasError('required') && notesFormControl.hasError('minlength')">
                4 - 60 characters required.
            </mat-error>
        </mat-form-field>
    </div>

    <hr class="separator">
    <app-driver-event-card [driverEvent]="previewEvent"></app-driver-event-card>
    <hr class="separator">
    <div class="button-section">
        <button mat-stroked-button color="accent" class="submit-button" (click)="close()">cancel</button>
        <button mat-button color="accent" class="contained-button submit-button {{this.pendoClass}} event-edit-submit-button" (click)="submitEvent()" [disabled]="disableEdit()">submit</button>
    </div>
</div>
