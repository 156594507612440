<div id='card-container'>
  <div id='card'>

    <div class='front {{clickedClass}} card {{hiddenClass}}' >
      <h2 class='card-content-title'>{{this.cardmodel.title}}</h2>

      <div class='card-content'>
        <div>
          <div class='card-content-media'>
            <div class='circle {{cardmodel.mediaContent.state}} {{buttonClickClass}} {{circleDisabledClass}}'>
              <div class='circle-text {{circleDisabledClass}}' *ngIf="cardmodel.mediaType === 'status'"
                   (click)="onClick($event, cardmodel.mediaContent.state)" id={{cardmodel.mediaContent.state}}>
                <span class='circle-text-content-title'>{{cardmodel.mediaContent.title}}</span>
                <span class='circle-text-content-sub-title transparent'>{{cardmodel.mediaContent.subTitle}}</span>
              </div>
              <div class='circle-with-media'  *ngIf="cardmodel.mediaType === 'image'">
                <img class='circle-image' src='{{cardmodel.mediaContent.imgUrl}}'/>
              </div>
              <div class=' circle-button md-raised ' *ngIf="cardmodel.mediaType === 'button'">
              <div class='button-wrapper'>
                  <div class='circle-on ON' (click)="onClick($event, null)">
                    <span>ON</span>
                  </div>
                  <div class='circle-or'>
                    <span>OR</span>
                  </div>
                  <div class='circle-off OFF' (click)="onClick($event, null)">
                    <span>OFF</span>
                  </div>
              </div>
              </div>
            </div>
          </div>
        </div>

        <div class='card-content-button'>

          <div class="change-status-disabled-info" *ngIf="changeStatusDisabledInfoVisible">
              <div class="change-status-disabled-info-text">
                  <div class="change-status-disabled-info-headline">
                      {{ headlineText}}
                  </div>
                  <div class="change-status-disabled-info-description">
                      {{ descriptionText }}
                  </div>
              </div>
          </div>
          <zui-icon
            *ngIf="changeStatusDisabled"
            icon="info"
            class="material-icons center-icon change-status-disabled-icon"
            inline="true" (mouseover)="showChangeStatusDisabledInfo()"
            (mouseout)="hideChangeStatusDisabledInfo()"
          ></zui-icon>

          <button type="button" (click)="onClick($event, cardmodel.mediaContent.state)" class="status-button circle-on {{changeStatusDisabledClass}}"
          [disabled]="isDisabled">
            <span class="change-status" style="padding:0;">Change Status</span>
          </button>
        </div>
      </div>
    </div>

    <div class='middle {{clickedClass}} card {{middleClass}}' >
      <div class='options-container'>
        <div class="options-text">
          <div>
            <span class='options-title'>Change duty status</span>
          </div>
          <div style="padding-top: 5px;">
            <span class="circle-text-content-sub-title">Do you want to go ON</span>
          </div>
          <div>
            <span class="circle-text-content-sub-title">duty or OFF duty?</span>
          </div>
        </div>
        <div class="duty-status-button-container">
          <button type="button" (click)="onClick($event, 'ON DUTY')" class="on-off">
            <span class="duty-text" style="padding:0;">ON DUTY</span>
          </button>
          <button type="button" (click)="onClick($event, 'OFF DUTY')" class="on-off">
            <span class="duty-text" style="padding:0;">OFF DUTY</span>
          </button>
        </div>
      </div>
      <hr class="container-divider">
      <div class="button-container">
        <button mat-stroked-button class="cancel mat-stroked-button" color="accent" (click)="cancelStatusUpdate()">Back</button>

        <!--
          This is only enabled when there's nothing invalid with the current status. If either notes or location is invalid, clicking
        'ON DUTY' or 'OFF DUTY' will send the user to the third('back') view, and clicking 'Back' will cancel and send them to
        the first('front') view.
        -->
        <button mat-button class='save mat-raised-button' color="accent" (click)="saveStatusUpdate()" [disabled]=submitDisabled()>OK</button>
      </div>
    </div>

    <div class='back {{clickedClass}} card {{hiddenClass}}' >
      <div>
        <div class='options-container'>
          <div class="options-text">
            <h6 class='options-title'>You are going {{newStatus}} duty.</h6>
          </div>

          <form class="data-form" style="text-align: center">
            <div style="display:flex; flex-direction: column; align-items: center">
              <mat-form-field appearance="outline" color="accent" style="height: 40px; padding-top: 12px; font-size: 14px;">
                <mat-label>Location</mat-label>
                <input name='location' [(ngModel)]="outputModel.manual_loc" style="font-size: 14px;" matInput required maxlength="60" minlength="5"  value="">
              </mat-form-field>
              <mat-form-field appearance="outline" color="accent" style="height: 96px; padding-top: 16px; font-size: 14px;">
                <mat-label>Notes</mat-label>
                <textarea maxlength="59" style="resize:none;" rows="4" matInput minlength="4" required name='notes' [(ngModel)]="outputModel.notes" (click)="clearText()">
                </textarea>
              </mat-form-field>
            </div>
          </form>
        </div>

        <hr class="container-divider">

        <div class='button-container'>
          <button mat-stroked-button class="cancel mat-stroked-button" color="accent" (click)="cancelStatusUpdate()">Back</button>
          <button mat-button class='save mat-raised-button' color="accent" (click)="saveStatusUpdate()" [disabled]=submitDisabled()>OK</button>
        </div>
      </div>
    </div>

  </div>
</div>
