export class DriverEvent {
    is_editable:  boolean;
    reduced_precision: boolean;
    odo:  number;
    accum_odo: number;
    id: string;
    driver_ts: string;
    is_manual_odo: boolean;
    comments: string;
    editable_fields: any;
    epoch: number;
    location: string;
    has_pending_edits: boolean;
    oid: string;
    update_epoch: any;
    local_ts: string;
    event_type_name: string;
    is_manual_loc: boolean;
    event_code_name: string;
    event_type_id: number;
    event_record_origin_name: string;
    update_user_id: string;
    data: any;
    event_record_origin_id: number;
    engine_hrs: number;
    event_code_id: number;
    accum_hrs: number;
    mcode_name?: string;
    dcode_name?: string;
    single_line?: boolean;
    display_icon?: boolean;
    display_location?: boolean;
    display_accum_miles?: boolean;
    display_total_miles?: boolean;
    display_comment?: boolean;
    display_edit?: boolean;
    parent_oid?: string;
    historical_events?: any[];
    was_system?: boolean;
    parent_event?: DriverEvent;
    is_preview_event?: boolean;
}

