import {Action} from '@ngrx/store';

export const SET_DATE = 'SET_DATE';
export const SET_DRIVER_UUID = 'SET_DRIVER_UUID';
export const SET_KILOMETERS = 'SET_KILOMETERS';

export class SetDate implements Action {
    readonly type = SET_DATE;
    constructor(public date: string) {}
}

export class SetDriverUuid implements Action {
    readonly type = SET_DRIVER_UUID;
    constructor(public driverId: string) {}
}

export class SetKilometers implements Action {
    readonly type = SET_KILOMETERS;
    constructor(public isKilometers: boolean) {}
}

export type Actions =
    | SetDate
    | SetDriverUuid
    | SetKilometers;
