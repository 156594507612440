<style>
    .pulsate {
        animation-name: pulse_animation;
        animation-duration: 1000ms;
        transform-origin:70% 70%;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
    @keyframes pulse_animation {
        0% { transform: scale(1); }
        30% { transform: scale(1); }
        40% { transform: scale(1.08); }
        50% { transform: scale(1); }
        60% { transform: scale(1); }
        70% { transform: scale(1.05); }
        80% { transform: scale(1); }
        100% { transform: scale(1); }
    }
</style>
<div class="loading">
    <div class='loader'
    style='
    text-align: center; margin: 1em 0;
    white-space: nowrap;
    '
    >
    <img
        class='pulsate'
        style='
        position: fixed;
        top: 50%;
        left: 50%;
        width: 300px;
        height: 300px;
        margin-top: -150px; /* Half the height */
        margin-left: -150px; /* Half the width */'
        src='/static/img/DD-Logo-DarkBG.svg'
    />
    </div>
</div>
<div class="row" *ngIf="profileJson">
    <pre
      class="rounded"
    ><code class="json" [highlight]="profileJson"></code></pre>
</div>
