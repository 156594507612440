import {
    GET_DRIVER_EVENTS,
    GET_DRIVER_EVENTS_SUCCESS, GET_FALLBACK_EVENT, POST_DRIVER_ADD_MANUAL_EVENT,
    POST_DRIVER_ADD_MANUAL_EVENT_FAILURE, POST_DRIVER_ADD_MANUAL_EVENT_SUCCESS,
    POST_DRIVER_EDIT_EXISTING_EVENT, POST_DRIVER_EDIT_EXISTING_EVENT_SUCCESS
} from '../actions/driver-events.actions';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {catchError, map, delay, switchMap} from 'rxjs/operators';
import {combineLatest, EMPTY} from 'rxjs';
import {DriverLogsService} from '../driver-logs.service';
import {DriverLogsParams, GET_DEFERRAL_STATE, GET_DRIVER_CLOCKS, GET_DRIVER_CLOCKS_SUCCESS} from '../actions/driver-clocks.actions';
import {GET_DRIVER_GRID_DATA, GET_DRIVER_GRID_DATA_SUCCESS} from '../actions/driver-grid-data.actions';
import {Store} from '@ngrx/store';
import {DriverModuleState} from '../reducers/driver-logs.reducer';
import {selectCurrentDate, selectDriverId} from '../driver-logs.selector';

@Injectable()
export class GetDriverLogsEffects {

    constructor(
        private actions$: Actions,
        private driverLogsService: DriverLogsService,
        private driverLogsStore: Store<DriverModuleState>
    ) {
        combineLatest([
            this.driverLogsStore.select(selectDriverId),
            this.driverLogsStore.select(selectCurrentDate)]).subscribe(([driverId, date]) => {
            if (driverId && date) {
                this.params = {
                    driverProfileUuid: driverId,
                    day: date
                };
            }
        });
    }
    driverProfileUuid: string;
    day: string;
    params: DriverLogsParams;

    @Effect()
    DriverEvents$ = this.actions$.pipe(
        ofType(
            GET_DRIVER_EVENTS,
            POST_DRIVER_EDIT_EXISTING_EVENT_SUCCESS,
            POST_DRIVER_ADD_MANUAL_EVENT_SUCCESS
        ),
        switchMap(() => {
          if (this.params && this.params.day && this.params.driverProfileUuid) {
            return this.driverLogsService.getDriverEvents(this.params)
            .pipe(
                map(events => ({
                    type: GET_DRIVER_EVENTS_SUCCESS, payload: {
                        events: this.driverLogsService.driverEventTransform(events),
                    }
                })),
                catchError(() => EMPTY)
            );
          }
        })
    );

    @Effect()
    DriverClocks$ = this.actions$.pipe(
        ofType(GET_DRIVER_CLOCKS,
            POST_DRIVER_EDIT_EXISTING_EVENT_SUCCESS,
            POST_DRIVER_ADD_MANUAL_EVENT_SUCCESS),
        switchMap(() => this.driverLogsService.getDriverClocks(this.params)
            .pipe(
                map(summary => ({
                    type: GET_DRIVER_CLOCKS_SUCCESS, payload: {
                        clocks: summary['summary']['clocks'],
                        rulesetId: summary['summary']['ruleset'],
                        deferralInfo: {
                            deferral_code: summary['summary']['deferral_state']['deferral_code'],
                            deferral_seconds: summary['summary']['deferral_state']['deferral_seconds'],
                            is_available: summary['summary']['deferral_state']['is_available'],
                            is_eligible: summary['summary']['deferral_state']['is_eligible'],
                            reason: summary['summary']['deferral_state']['reason'],
                            deferral_code_next: summary['summary']['deferral_state']['deferral_code_next']
                        },
                        zone: this.mapZoneIntsToStrings(summary['summary']['zone'])
                    }
                })),
                catchError(() => EMPTY)
            ))
    );

    @Effect()
    DriverGridData$ = this.actions$.pipe(
        ofType(GET_DRIVER_GRID_DATA,
            POST_DRIVER_EDIT_EXISTING_EVENT_SUCCESS,
            POST_DRIVER_ADD_MANUAL_EVENT_SUCCESS),
        switchMap(() => this.driverLogsService.getDriverSummary(this.params)
            .pipe(
                switchMap(summary => [{
                    type: GET_DRIVER_GRID_DATA_SUCCESS, payload: {
                        params: this.params,
                        grid_events: summary['summary']['duty_statuses'],
                        duty_status_totals: summary['summary']['duty_status_totals'],
                        driver_time_zone: summary['details']['timezone'],
                        isCertified: summary['details']['certified'],
                        zone: this.mapZoneIntsToStrings(summary['summary']['zone'])
                    }
                }, {
                    type: GET_FALLBACK_EVENT, payload: {
                        radius_monitor: summary['summary']['radius_monitor'],
                        fallback_history: summary['summary']['fallback_history'],
                        fallback_state: summary['summary']['fallback_state'],
                    }
                }]),
                catchError(() => EMPTY)
            ))
    );

    @Effect()
    EditExistingDriverEvent = this.actions$.pipe(
        ofType(POST_DRIVER_EDIT_EXISTING_EVENT),
        switchMap((editParams: any) => this.driverLogsService.postDriverEditEvent({
                ...editParams.params,
                driverProfileUuid: this.params.driverProfileUuid
            })
                .pipe(
                    delay(1000),
                    map(data => ({
                            type: POST_DRIVER_EDIT_EXISTING_EVENT_SUCCESS,
                            payload: {}
                        })
                    ),
                    catchError(() => EMPTY)) // todo figure out error
        )
    );
    @Effect()
    ManualAddDriverEvent = this.actions$.pipe(
        ofType(POST_DRIVER_ADD_MANUAL_EVENT),
        switchMap((addParams: any) => this.driverLogsService.postAddDriverEvent({
                ...addParams.params,
                driverProfileUuid: this.params.driverProfileUuid
            })
                .pipe(
                    delay(1000),
                    map(data => ({
                            type: POST_DRIVER_ADD_MANUAL_EVENT_SUCCESS,
                            payload: {}
                        })
                    ),
                    catchError(() => EMPTY)) // todo figure out error
        )
    );

    mapZoneIntsToStrings(zone: number): string {
        switch (zone) {
            case 1:
                return 'S_60';

            case 2:
                return 'N_60';

            case 3:
                return 'US';

            case -1: // zone not set, one of the expected values
            default:
                return null;
        }
    }
}
