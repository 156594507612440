import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {EditConfig} from '../driver-event-edit.component';
import {combineLatest} from 'rxjs';
import {selectCurrentDate} from '../../state/driver-logs.selector';
import {Store} from '@ngrx/store';
import {DriverModuleState} from '../../state/reducers/driver-logs.reducer';
import {FormControl, Validators} from '@angular/forms';
import {DriverEvent} from '../../model/driver-event';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);


@Component({
    selector: 'app-driver-cycle-change-edit',
    templateUrl: './driver-cycle-change-edit.component.html',
    styleUrls: ['./driver-cycle-change-edit.component.scss', '../driver-event-edit.component.scss']
})
export class DriverCycleChangeEditComponent implements OnInit {
    config: EditConfig;
    event: DriverEvent;
    pendoClass: string;
    date: string; // mm dd yyyy
    cycle: any;

    notesFormControl: FormControl;

    constructor(private dialogRef: MatDialogRef<DriverCycleChangeEditComponent>,
                @Inject(MAT_DIALOG_DATA) public data: EditConfig,
                private driverLogsStore: Store<DriverModuleState>) {
        this.config = data;
        this.pendoClass = data.pendoClass;
        this.event = data.event;
        this.cycle = this.event.event_code_name;
        combineLatest([
            this.driverLogsStore.select(selectCurrentDate)
        ]).subscribe(([date]) => {
            this.date = date;
        });
    }

    ngOnInit(): void {
        this.notesFormControl = new FormControl(
            {value: this.event.comments, disabled: false},
            [Validators.required, Validators.minLength(4)]
        );
    }

    close() {
        this.dialogRef.close();
    }

    submitEvent() {
        const params = {
            oid: this.event.oid,
            currentEventCodeName: this.event.event_code_name,
            payload: {
                new_event_code_name: this.cycle,
                comments: this.notesFormControl.value
            }
        };
        this.driverLogsStore.dispatch({type: this.config.action, params: params});
        this.close();
    }

    disableSubmit() {
        return this.event.comments === this.notesFormControl.value || this.notesFormControl.status === 'INVALID';
    }

    isCycleChangeDisabled() {
        const whenEventWasCreated = dayjs.unix(this.event.epoch);
        const twentyFourHoursLater = dayjs.unix(this.event.epoch).add(24, 'hour');
        const result = dayjs().isBetween(whenEventWasCreated, twentyFourHoursLater);
        return !result;
    }

}
