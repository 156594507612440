<div class="edit-modal mat-app-background zonar-default-theme">
    <div class="edit-modal-title">
        <span class="edit-title">{{config.title}} for {{date | zonarTimestamp: 'date'}}</span>
        <img class="exit-icon" (click)="close()" src="../../../static/img/ic-clear.svg">
    </div>
    <div class="driver-cycle-edit-time">
        Time: {{this.event.epoch | zonarTimestamp: 'time'}}
    </div>
    <div>
        <mat-form-field appearance="outline">
            <mat-label>Canada Cycle</mat-label>
            <mat-select [disabled]="true" [(ngModel)]="cycle" class="operating-zone-select">
                    <mat-option value="CYCLE_1">Cycle 1</mat-option>
                <mat-option value="CYCLE_2">Cycle 2</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="edit-modal-input-row">
        <mat-form-field appearance="outline" color="accent" id="full-input">
            <mat-label>Notes</mat-label>
            <input matInput
                   [formControl]="notesFormControl"
                   maxlength="60" required>
            <mat-error *ngIf="notesFormControl.hasError('required')">
                You must enter notes.
            </mat-error>
            <mat-error *ngIf="!notesFormControl.hasError('required') && notesFormControl.hasError('minlength')">
                4 - 60 characters required.
            </mat-error>
        </mat-form-field>
    </div>
    <div class="button-section">
        <button mat-button class="submit-button" color="accent" (click)="close()">cancel</button>
        <button mat-button color="accent" class="mat-raised-button submit-button {{this.pendoClass}} event-edit-submit-button" (click)="submitEvent()" [disabled]="disableSubmit()">submit</button>
    </div>
</div>
