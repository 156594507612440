import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {DriverEvent} from '../model/driver-event';
import {getEventHeader} from './driver-event-status.util';

@Component({
    selector: 'app-driver-event-generic-card',
    template: `
        <div class="driver-event-card" role="listitem">
            <div class="timestamp driver-event-container driver-icon-container">
                <div *ngIf="(driverEvent.display_icon && driverEvent.event_type_name === 'FALLBACK')">
                    <img src="../../../static/img/fallback-icon.svg">
                </div>
                {{driverEvent.driver_ts | zonarTimestamp: 'time'}}
            </div>
            <div class="driver-event-title driver-event-container font-heavy">
                {{this.getHeader()}}
            </div>
        </div>
    `,
    styleUrls: ['./driver-event-card.component.scss']
})
export class DriverEventGenericCardComponent implements OnInit {
    @Input() driverEvent: DriverEvent;

    constructor() { }

    ngOnInit() {}

    public getHeader(): string {
        return getEventHeader(this.driverEvent);
    }
}
