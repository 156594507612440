import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Store} from '@ngrx/store';
import {CertifyLogsState} from '../state/certify-logs.reducer';
import {selectOldestUncertifiedDay, selectUncertifiedDays} from '../state/certify-logs.selectors';
import {GetUncertifiedDays} from '../state/certify-logs.actions';
import {combineLatest} from 'rxjs';
import {MAX_DAYS, MIN_DAYS} from '../certify-logs-warning/certify-logs-warning.component';

@Component({
    selector: 'app-certify-button',
    templateUrl: './certify-button.component.html',
    styleUrls: ['../certify-logs.scss']
})
export class CertifyButtonComponent implements OnInit {

    @Output() toggleCertify: EventEmitter<any> = new EventEmitter();
    @Input() isDisabled: boolean;
    oldestDay;

    constructor(private certifyLogsStore: Store<CertifyLogsState>) {
        this.certifyLogsStore.dispatch(new GetUncertifiedDays());
    }

    ngOnInit() {
        combineLatest([
            this.certifyLogsStore.select(selectOldestUncertifiedDay),
            this.certifyLogsStore.select(selectUncertifiedDays)])
            .subscribe(([oldest, days]) => {
                this.isDisabled = days.length < 1;
                this.oldestDay = oldest;
            });
    }

    openCertify() {
        this.toggleCertify.emit(true);
        this.isDisabled = true;
    }

    warningState(day: number) {
        if (day < MIN_DAYS) {
            return 'hidden';
        }
        if (day >= MIN_DAYS && day < MAX_DAYS) {
            return 'warn';
        } else {
            return 'crit';
        }
    }
}
