import {DriverEvent} from '../model/driver-event';
import {EditFormValues} from '../model/edit-form-values';
import { EventIds } from '../../enums';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(timezone);

export interface EventParams {
    location?: string;
    comments: string;
    odo?: number;
    duty_status?: string;
    created?: number;
}

export interface DriverModalController {
    validate(fieldName: string, formValues?: object): boolean;

    disabled(fieldName: string): boolean;

    validateTime(blockedOutTime?: any[], inputTime?: any, date?: any, timezone?: string): boolean;

    buildParams(values: EventParams): object;

    getDefaultValue(ds: string): string;

    modelChanged(values: any): boolean;
}

export class AddModalController implements DriverModalController {
    protected readonly event: DriverEvent;

    // todo: change to switch statement, so there's a default?
    protected readonly event_code_map = {
        'OFF_DUTY': 'OFF_DUTY',
        'PC': 'OFF_DUTY',
        'WT': 'OFF_DUTY',
        'SB': 'SB',
        'DRIVING': 'DRIVING',
        'ON_DUTY': 'ON_DUTY',
        'YM': 'ON_DUTY',
        'HR': 'ON_DUTY',
        'S_60': 'S_60',
        'N_60': 'N_60',
        'US': 'US',
        'UNKOWN_OPERATING_ZONE': 'UNKOWN_OPERATING_ZONE',
        'DEFERRAL_NONE': 'DEFERRAL_NONE',
        'DEFERRAL_DAY_1': 'DEFERRAL_DAY_1',
        'DEFERRAL_DAY_2': 'DEFERRAL_DAY_2',
    };

    constructor(event: DriverEvent) {
        this.event = event;
    }

    protected dsValid(formValues: object) {
        return true;
    }

    protected milesValid(formValues: EditFormValues) {
        return formValues.odo !== undefined && formValues.odo !== null && formValues.odo >= 0 && formValues.odo < 10000000;
    }

    protected locationValid(formValues: EditFormValues) {
        return formValues.location !== undefined && formValues.location !== null && formValues.location.length >= 5
            && formValues.location.length < 61;
    }

    protected annotationValid(formValues: EditFormValues) {
        return formValues.annotation !== undefined && formValues.annotation !== null && formValues.annotation.length >= 4;
    }

    validate(fieldName: string, formValues?: EditFormValues): boolean {
        switch (fieldName) {
            case 'ds':
                return this.dsValid(formValues);
            case 'miles':
                return this.milesValid(formValues);
            case 'location':
                return this.locationValid(formValues);
            case 'annotation':
                return this.annotationValid(formValues);
            default:
                return false;
        }
    }

    // todo: delete?
    disabled(fieldName: string): boolean {
        return false;
    }

    validateTime(blockedOutTime?: any[], inputTime?: any, date?: any, tz?: string): boolean {
        const insertTime = date + ' ' + inputTime;
        const newTime = dayjs.tz(insertTime, tz).unix();
        const now = dayjs().tz(tz).unix();
        let canInsert = false;
        if (newTime < now) {
          const isBetweenTime = (t) => newTime === t.start_epoch
            || (newTime > t.start_epoch && newTime < t.end_epoch);
          canInsert = !blockedOutTime.some(isBetweenTime);
        }
        return canInsert;

    }

    buildParams(values: EventParams): object {
        const params = {};
        Object.keys(values).forEach((key) => {
            params[this.mapKey(key)] = values[key];
        });
        return {
            payload: params
        };
    }

    getDefaultValue(ds: string): string {
        return this.event_code_map[ds];
    }

    modelChanged(values: any): boolean {
        return true;
    }

    protected mapKey(key: string): string {
        switch (key) {
            case 'event_code_name':
                return 'duty_status';
            case 'epoch':
                return 'created';
            default:
                return key;
        }
    }
}

export class EditModalController extends AddModalController {
    constructor(event: DriverEvent) {
        super(event);
    }

    validateTime(): boolean {
        return true;
    }

    isAutoD(): boolean {
        return this.event.event_record_origin_id === 1 &&
            this.event.event_type_id === 1 &&
            this.event.event_code_id === 3;
    }

    isSDS(): boolean {
        return this.event.event_type_name === 'SDS';
    }

    isUnidentified(): boolean {
        return this.event.event_record_origin_id === 4;
    }

    isCanadianEvent(): boolean {
        return this.event.event_type_id === EventIds.CanadaDeferral
            || this.event.event_type_id === EventIds.CanadaOperatingZone
            || this.event.event_type_id === EventIds.CanadaAdditionalHours;
    }

    private timeDisabled() {
        return true;
    }

    // if true, the duty status select in the edit modal will be disabled
    private dsDisabled() {
        return this.isSDS() || this.isAutoD() || this.isUnidentified() || this.isCanadianEvent();
    }

    private milesDisabled() {
        return !this.event.is_manual_odo;
    }

    private locationDisabled() {
        return !this.event.is_manual_loc;
    }

    private annotationDisabled() {
        return false;
    }

    disabled(fieldName: string): boolean {
        switch (fieldName) {
            case 'ds':
                return this.dsDisabled();
            case 'time':
                return this.timeDisabled();
            case 'miles':
                return this.milesDisabled();
            case 'location':
                return this.locationDisabled();
            case 'annotation':
                return this.annotationDisabled();
            default:
                return false;
        }
    }

    buildParams(values: EventParams): object {
        const oid = this.event.parent_oid ? this.event.parent_oid : this.event.oid;
        const payload = this.findChangedParams(values);
        if (payload.event_code_name) {
            delete payload.event_code_name;
        }
        if (!this.event.is_manual_odo) {
            delete payload.odo;
        }
        if (!this.event.is_manual_loc) {
            delete payload.location;
        }

        return {
            oid: oid,
            currentEventCodeName: this.getDefaultValue(this.event.event_code_name),
            payload: {
                ...payload,
                comments: values.comments,
                new_event_code_name: values.duty_status
            }
        };
    }

    modelChanged(values: EventParams): boolean {
        return Object.keys(this.findChangedParams(values)).length > 0;
    }

    findChangedParams(values: EventParams): any {
        const possibleParams = {};
        Object.keys(values).filter((key: string) => {
            return values[key] !== this.event[key];
        }).forEach((key: string) => {
            possibleParams[this.mapKey(key)] = values[key];
        });
        return possibleParams;
    }
}
