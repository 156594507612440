<div
    [ngClass]="{
        'skeleton': true,
        'circle': variant === 'circle',
        'wave': animation === 'wave',
        'pulse': animation === 'pulse'
    }"
    [ngStyle]="{
        'width.px': width,
        'height.px': height
    }"
>
</div>