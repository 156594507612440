import {DriverEvent} from '../../model/driver-event';
import {
    Actions,
    GET_DRIVER_EVENTS,
    GET_DRIVER_EVENTS_SUCCESS, GET_FALLBACK_EVENT,
    POST_DRIVER_EDIT_EXISTING_EVENT_SUCCESS
} from '../actions/driver-events.actions';

export interface FallbackState {
    reason: number;
    start_epoch: number;
    end_epoch?: number;
    to_ruleset: number;
    from_ruleset: number;
    triggered_epoch?: number;
}

export interface FallbackInfo {
    radius_monitor: {
        meters_from_center: number;
        miles_from_center: number
    };
    fallback_history: FallbackState[];
    fallback_state: FallbackState;
}

export interface DriverEventsState {
    events: DriverEvent[];
    isLoading: boolean;
    error: any;
    fallbackInfo: FallbackInfo;
}

export const initialState: DriverEventsState = {
    events: [],
    isLoading: true,
    error: null,
    fallbackInfo: null,
};

export function driverEventsReducer(state = initialState, action: Actions): DriverEventsState {
    switch (action.type) {
        case GET_DRIVER_EVENTS:
            return {
                events: [],
                isLoading: true,
                error: null,
                fallbackInfo: null
            };
        case GET_DRIVER_EVENTS_SUCCESS:
            return {
                ...action.payload,
                isLoading: false,
                error: null,
                fallbackInfo: null
            };
        case POST_DRIVER_EDIT_EXISTING_EVENT_SUCCESS:
            return state;
        case GET_FALLBACK_EVENT:
            return {
                ...state,
                fallbackInfo: action.payload
            };
        default:
            return state;
    }
}
