import {createFeatureSelector, createSelector} from '@ngrx/store';
import {CertifyLogsState} from './certify-logs.reducer';

export const selectCertifyLogsState = createFeatureSelector<CertifyLogsState>('certify-logs');

export const getOldestUncertifiedDay = (state: CertifyLogsState) => state.oldest_uncertified_day;
export const getUncertifiedDays = (state: CertifyLogsState) => state.days;
export const getIsInCertifyMode = (state: CertifyLogsState) => state.isInCertifyMode;

export const selectOldestUncertifiedDay = createSelector(
    selectCertifyLogsState, getOldestUncertifiedDay
);

export const selectUncertifiedDays = createSelector(
    selectCertifyLogsState, getUncertifiedDays
);

export const selectCertifyMode = createSelector(
    selectCertifyLogsState, getIsInCertifyMode
);
