import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import { GET_SYSVAR, GetSysvar, SysvarAction, GET_SYSVAR_SUCCESS, SysvarEndpointParams } from './app.actions';
import {catchError, delay, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {EMPTY, of} from 'rxjs';
import { ByDriverProfileService } from '../api-middleware/api-service/compliance/services';

@Injectable()
export class SysvarEffects {
    driverProfileUuid: string;
    constructor(
        private actions$: Actions,
        private dutyStatusService: ByDriverProfileService,
    ) {}

    @Effect()
    Sysvars = this.actions$.pipe(
        ofType(
            GET_SYSVAR,
            GET_SYSVAR_SUCCESS
        ),
        switchMap((action: GetSysvar) => {
            if (action.params) {
                return this.dutyStatusService.postGetSysvar(action.params)
                    .pipe(
                        map(response => ({
                            type: GET_SYSVAR_SUCCESS,
                            payload: response,
                            sysvarName: action.sysvarName
                        })),
                        catchError(() => EMPTY));
            } else {
                return of({ type: 'NO_ACTION' });
            }

        })
    );
}
