import {Action} from '@ngrx/store';

export const GET_SYSVAR = '[Get] GET_SYSVAR';
export const GET_SYSVAR_SUCCESS = '[Response] GET_SYSVAR_SUCCESS';

export const GET_DRIVER_PROFILE_UUID_SUCCESS = '[Response] GET_DRIVER_PROFILE_UUID_SUCCESS';
export const GET_DRIVER_DIVISION_SUCCESS = '[Response] GET_DRIVER_DIVISION_SUCCESS';
export const GET_DRIVER_USERNAME_SUCCESS = '[Response] GET_DRIVER_USERNAME_SUCCESS';
export const GET_DRIVER_COMPANY_SUCCESS = '[Response] GET_DRIVER_COMPANY_SUCCESS';

export interface SysvarEndpointParams {
    driverProfileUuid: string;
    payload: SysvarEndpointPayload;
}

export interface SysvarEndpointPayload {
    sysvar_name: string;
    convert?: 'bool' | 'int' | 'float';
}

export interface SysvarEndpointResponse {
    code: string;
    data: any; // this can change depending on the value of SysvarEndpointPayload's convert value
}

export class GetSysvar implements Action {
    readonly type = GET_SYSVAR;
    constructor(public params: SysvarEndpointParams, public sysvarName: string) { }
}

export class GetSysvarSuccess implements Action {
    readonly type = GET_SYSVAR_SUCCESS;
    constructor(public payload: SysvarEndpointResponse, public sysvarName: string) { }
}

export class GetDriverProfileUuidSucess implements Action {
  readonly type = GET_DRIVER_PROFILE_UUID_SUCCESS;
  constructor(public payload: any, public driverProfileUuid: string) { }
}

export class GetDriverDivisionSucess implements Action {
  readonly type = GET_DRIVER_DIVISION_SUCCESS;
  constructor(public payload: any, public division: string) { }
}

export class GetDriverUserNameSucess implements Action {
  readonly type = GET_DRIVER_USERNAME_SUCCESS;
  constructor(public payload: any, public userName: string) { }
}

export class GetDriverCompanySucess implements Action {
  readonly type = GET_DRIVER_COMPANY_SUCCESS;
  constructor(public payload: any, public company: string) { }
}

export type SysvarAction =
    | GetSysvar
    | GetSysvarSuccess
    | GetDriverProfileUuidSucess
    | GetDriverDivisionSucess
    | GetDriverUserNameSucess
    | GetDriverCompanySucess;
