import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountSwitcherComponent } from './account-switcher.component';
import { MaterialModule } from '../material.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule
  ],
  declarations: [AccountSwitcherComponent],
  exports: [AccountSwitcherComponent]
})
export class AccountSwitcherModule { }
