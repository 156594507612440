import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {FormControl, Validators, FormGroup, ValidationErrors, FormArray} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {ZONAR_TIME_FORMAT} from '../driver-logs/pipe/zonar-timestamp.pipe';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import { FormBuilder } from '@angular/forms';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(timezone);

@Component({
    selector: 'app-export-content',
    templateUrl: `./export-log-content.html`,
    styleUrls: ['./export-log.scss']
})
export class ExportLogContentComponent {
    private exportLimit = 15;
    private millsToDay = (1000 * 60 * 60 * 24);
    private formattedDate = ZONAR_TIME_FORMAT.yearMonthDay;

    public exportFormGroup = this.formBuilder.group({
        exportType: this.formBuilder.array([], [this.exportTypeValidator.bind(this)]),
        email: new FormControl('', Validators.compose([
            Validators.required,
            Validators.email
        ])),
        emailConfirm: new FormControl('', Validators.compose([
            Validators.required,
            Validators.email,
            this.emailMatchValidator.bind(this)
        ]))
    });

    public comment = new FormControl();

    public startDate: Date;
    public endDate: Date;
    public maxDate;
    public minDate;

    public exportStartMinDate;
    public exportEndMaxDate;
    public exportStartMaxDate;
    public exportEndMinDate;

    public exportTypes: Array<any> = [
      {name: 'US PDF', value: 'US'},
      {name: 'Canada PDF', value: 'Canada'}
    ];

    constructor(
        private dialogRef: MatDialogRef<ExportLogContentComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private http: HttpClient,
        private formBuilder: FormBuilder
    ) {
        this.maxDate = dayjs().format();
        this.minDate = dayjs().subtract(6, 'months').format();
        this.calendarReset();
    }

    close() {
        this.dialogRef.close();
    }

    export() {
        if (this.areDatesValid()) {
            const obj = {
                start_day: dayjs(this.startDate).format(this.formattedDate),
                end_day: dayjs(this.endDate).format(this.formattedDate),
                email: this.exportFormGroup.get('email').value,
                name: this.data.userName,
                driverUUID: this.data.driverUUID,
                comments: this.comment && this.comment.value || '',
                export_type: this.exportFormGroup.get('exportType').value
            };
            this.http.post('/api/v1/email_pdf_export', obj).subscribe(data => {
                    // console.log(data);
                    // TODO: Implement activity indicator (see ZLOGS-10361)
                },
                err => {
                    // console.log(err);
                    // TODO: Implement activity indicator (see ZLOGS-10361)
                }
            );
        }
        this.close();
    }

    getErrorMessage(field: string) {
        if (this.exportFormGroup.get('email').value !== this.exportFormGroup.get('emailConfirm').value && field === 'emailConfirm') {
          return 'Emails must match';
        } else if (this.exportFormGroup.get(field).hasError('required')) {
          return 'You must enter a value';
        } else if (this.exportFormGroup.get(field).hasError('email')) {
          return 'Invalid e-mail';
        }

        return 'Error';
    }

    getDate(type: string, event: MatDatepickerInputEvent<Date>) {
        if (type === 'end') {
            this.endDate = event.value;
        } else {
            this.startDate = event.value;
        }
        this.calculate2Weeks(type, event.value);
    }

    areDatesValid() {
        if (!this.startDate || !this.endDate) {
            return false;
        }
        const amount: number = (this.endDate.valueOf() - this.startDate.valueOf()) / this.millsToDay;
        return amount < this.exportLimit && amount > -1;
    }

    disableExport(): boolean {
        const exportTypeValidation = !this.exportFormGroup.get('exportType').hasError('noExportTypeSelected');

        const emailValidation =
            !this.exportFormGroup.get('email').hasError('required') &&
            !this.exportFormGroup.get('email').hasError('email') &&
            !this.exportFormGroup.get('emailConfirm').hasError('required') &&
            !this.exportFormGroup.get('emailConfirm').hasError('email') &&
            !this.exportFormGroup.hasError('emailsDoNotMatch');

        if (this.areDatesValid() && emailValidation && exportTypeValidation) {
            return false;
        }
        return true;
    }

    private calendarReset() {
        this.exportStartMinDate = this.minDate;
        this.exportEndMaxDate = this.maxDate;
        this.exportStartMaxDate = this.maxDate;
        this.exportEndMinDate = this.minDate;
    }

    private calculate2Weeks(dateType: string, dateValue: any) {
        const date = dayjs(dateValue);
        if (dateValue === null) {
            this.calendarReset();
        } else if (dateType === 'start') {
            // set end date
            this.exportEndMinDate = date.format();
            const fourteenDaysOutFromMin = date.clone().add(14, 'day');
            if (fourteenDaysOutFromMin.isSameOrAfter(this.maxDate)) {
                this.exportEndMaxDate = this.maxDate;
            } else {
                this.exportEndMaxDate = date.add(14, 'day').format();
            }
        } else {
            // set start date
            this.exportStartMaxDate = date.format();
            const fourteenDaysBackFromMax = date.clone().subtract(14, 'day');
            if (fourteenDaysBackFromMax.isSameOrBefore(this.minDate)) {
                this.exportStartMinDate = this.minDate;
            } else {
                this.exportStartMinDate = date.subtract(14, 'day').format();
            }
        }
    }

    public emailMatchValidator(formGroup: FormGroup): ValidationErrors | null {
        let email;
        let emailConfirm;
        if (this.exportFormGroup) {
            email = this.exportFormGroup.get('email');
            emailConfirm = this.exportFormGroup.get('emailConfirm');
        }

        return (email && emailConfirm && email.value === emailConfirm.value) ? null : { 'emailsDoNotMatch': true };
    }

    public showEmailsMustMatchError(): boolean {
        return (
            this.exportFormGroup.errors?.emailsDoNotMatch &&
            (this.exportFormGroup.get('email').dirty || this.exportFormGroup.get('email').touched) &&
            (this.exportFormGroup.get('emailConfirm').dirty || this.exportFormGroup.get('emailConfirm').touched)
        );
    }

    public exportTypeValidator(formGroup: FormGroup): ValidationErrors | null {
      if (this.exportFormGroup) {
        return (
          !this.data.canadaSysvarEnabled || (this.exportFormGroup.get('exportType') as FormArray).length > 0
            ? null
            : {'noExportTypeSelected': true}
        );
      } else {
        return null;
      }
    }

    public onCheckboxChange(e): void {
      const checkArray: FormArray = this.exportFormGroup.get('exportType') as FormArray;

      if (e.checked) {
        checkArray.push(new FormControl(e.source.value));
      } else {
        let i = 0;
        checkArray.controls.forEach((item: FormControl) => {
          if (item.value === e.source.value) {
            checkArray.removeAt(i);
            return;
          }
          i++;
        });
      }
    }
}
