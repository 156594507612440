<div class="clock-display">
  <div class="clock-header-section">
    <h3 class="clock-header-text">
      Today's Clocks
    </h3>
    <span class="clock-header-text ruleset-text" [ngStyle]="{display: isHidden ? 'none': 'block'}">
      Ruleset: <span class="title">{{getRulesetText()}}</span>
    <ng-container *ngIf=canadaSysvar>
      Zone: <span class="title">{{getZoneText()}}</span>
    </ng-container>
    </span>
  </div>
  <zui-hos-clock-list
    [driverClocks]="driverClocks"
    [ruleset]="ruleset"
    [deferralState]="deferralState"
  ></zui-hos-clock-list>
</div>

