// todo create lang json for the const

export const ORIGIN = {
    SYSTEM: 'ELD',
    DRIVER: 'Driver',
    NON_DRIVER: 'Carrier',
    UNIDENTIFIED_DRIVING: 'Unidentified'
};

export const DUTY_STATUS = {
    header: ':origin :act duty status to :duty_status',
    OFF_DUTY: {
        act: 'changed',
        duty_status: 'Off Duty',
        abbr: 'OFF'
    },
    NEW: {
        act: 'added',
        duty_status: '{Choose DS}',
        abbr: '*'
    },
    SB: {
        act: 'changed',
        duty_status: 'Sleeper Berth',
        abbr: 'SB'
    },
    DRIVING: {
        act: 'changed',
        duty_status: 'Drive',
        abbr: 'D'
    },
    ON_DUTY: {
        act: 'changed',
        duty_status: 'On Duty',
        abbr: 'ON'
    },
    PC: {
        abbr: 'OFF'
    },
    YM: {
        abbr: 'ON'
    },
    WT: {
        abbr: 'OFF'
    },
    HR: {
        abbr: 'ON'
    }
};

export const INTERMEDIATE = {
    header: 'Intermediate location captured',
};

export const SDS = {
    on_sds: {
        header: ':origin :act duty status to :ds - :status :action',
        PC: {
            status: 'Authorized Personal Use of CMV',
            action: 'started',
            act: 'changed',
            ds: 'Off Duty'
        },
        YM: {
            status: 'Yard Move',
            action: 'started',
            act: 'changed',
            ds: 'On Duty'
        },
        WT: {
            status: 'Oil Well Wait Time',
            action: 'started',
            act: 'changed',
            ds: 'Off Duty'
        },
        HR: {
            status: 'Yard Move (Hi Rail)',
            action: 'started',
            act: 'changed',
            ds: 'On Duty'
        },
    },
    clear_sds: {
        header: ':origin indicated :status :action',
        CLEAR_PC: {
            status: 'Authorized Personal Use of CMV',
            action: 'cleared'
        },
        CLEAR_YM: {
            status: 'Yard Move',
            action: 'cleared'
        },
        CLEAR_WT: {
            status: 'Oil Well Wait Time',
            action: 'cleared'
        },
        CLEAR_HR: {
            status: 'Yard Move (Hi Rail)',
            action: 'cleared'
        }
    }
};

export const CERTIFICATION = {
    header: 'Driver certified daily log: :date'
};

export const AUTH_STATUS = {
    header: ':origin logged :action ELD',
    LOGIN: {
        action: 'into'
    },
    LOGOUT: {
        action: 'out of'
    }
};

export const ENGINE_STATUS = {
    header: 'Engine :action',
    POWER_UP_CONVENTIONAL_LOC: {
        action: 'power-up'
    },
    POWER_UP_REDUCED_LOC: {
        action: 'power-up'
    },
    POWER_DOWN_CONVENTIONAL_LOC: {
        action: 'shut-down'
    },
    POWER_DOWN_REDUCED_LOC: {
        action: 'shut-down'
    },
};

export const DIAG_MAL = {
    MALFUNCTION: {
        set: 'ELD :name created',
        clear: 'ELD :name cleared',
        POWER: {
            name: 'Power Compliance Malfunction'
        },
        ENGINE_SYNCH: {
            name: 'Engine Synchronization Compliance Malfunction'
        },
        POSITIONING: {
            name: 'Positioning Compliance Malfunction'
        },
        DATA_RECORDING: {
            name: 'Data Recording Compliance Malfunction'
        },
        DATA_TRANSFER: {
            name: 'Data Transfer Compliance Malfunction'
        },
        TIMING: {
            name: 'Timing Compliance Malfunction'
        },
        OTHER: {
            name: 'Other ELD Detected Malfunction'
        }
    },
    DIAGNOSTIC: {
        set: 'ELD :name created',
        clear: 'ELD :name cleared',
        POWER: {
            name: 'Power Data Diagnostic Event',
        },
        ENGINE_SYNCH: {
            name: 'Engine Synchronization Data Diagnostic Event'
        },
        MISSING_REQUIRED_DATA: {
            name: 'Missing Required Data Elements Data Diagnostic Event'
        },
        DATA_TRANSFER: {
            name: 'Data Transfer Data Diagnostic Event'
        },
        UNIDENTIFIED_DRIVING: {
            name: 'Unidentified Driving Record Data Diagnostic Event'
        },
        OTHER: {
            name: 'Other ELD Identified Diagnostic Event'
        }
    }
};

//eventType: 105
export const SLEEPER_BERTH_SPLIT = {
  name: 'SleeperBerth Split Toggle',
  SLEEPER_BERTH_SPLIT_OFF: {
    header: 'Apply SleeperBerth Split Disabled',
  },
  SLEEPER_BERTH_SPLIT_ON: {
    header: 'Apply SleeperBerth Split Enabled',
  }
};

export const DRIVER_DAY_START_CHANGE = {
  DRIVER_DAY_START_CHANGE: {
    header: 'Driver Day Start Changed',
    special: 'Driver Day Start Time changed to :data'
  }
};

export const SYSTEM_DESCRIPTOR = {
    OUTSIDE_RADIUS: {
        header: 'Driver is approaching max radius from home base',
        special: 'Exceeded :data mile radius'
    },
    MAX_TIME_UNDOCKED: {
        header: 'Tablet not connected while vehicle in motion for 5 mins.',
        special: 'Tablet not connected while vehicle in motion for 5 min. Ruleset changed to :data'
    },
    GPS_LOCK: {
        header: 'ELD captured location from GPS'
    },
    AUTO_INSERT: {
        header: 'Midnight log captured'
    },
    BORDER_CROSSING: {
        header: 'Driver is crossing state or international border',
        special: 'Now entering :data'
    },
    TABLET_UNDOCKED: {
        header: 'Tablet undocked'
    },
    TABLET_DOCKED: {
        header: 'Tablet docked'
    },
    TABLET_DISCONNECTED: {
        header: 'Tablet disconnected'
    },
    TABLET_CONNECTED: {
        header: 'Tablet connected'
    },
    DRIVER_DAY_START_CHANGE: {
      header: 'Driver Day Start Changed',
      special: 'Driver Day Start Time changed to :data'
    },
};

export const APPLIED_USER_DESCRIPTOR = {
    header: ':origin applied :code exemption',
    BIGDAY: {
        code: 'Big Day'
    },
    ADVERSE_WEATHER: {
        code: 'Adverse Weather'
    },
    RESTART: {
        code: '34 hour restart'
    },
    SHORT_HAUL_14_HOUR: {
        code: 'Short haul 14'
    },
    ON_DUTY_REST_BREAK: {
        code: 'On duty rest break'
    }
};

export const RULESET_CHANGE = {
    header: ':origin ruleset changed to :name',
    US_PROPERTY_60: {
        name: 'Property 60'
    },
    US_PROPERTY_70: {
        name: 'Property 70'
    },
    US_PASSENGER_60: {
        name: 'Passenger 60'
    },
    US_PASSENGER_70: {
        name: 'Passenger 70'
    },
    US_PROPERTY_70_OIL_WELL: {
        name: 'Oilwell 70'
    },
    US_PROPERTY_70_OIL_FIELD: {
        name: 'Oilfield 70'
    },
    US_PROPERTY_60_BIGDAY: {
        name: '16 Hour Exempt 60'
    },
    US_PROPERTY_70_BIGDAY: {
        name: '16 Hour Exempt 70'
    },
    CA_SOUTH60_CYCLE1: {
        name: 'Cycle 1 S. 60'
    },
    CA_SOUTH60_CYCLE2: {
        name: 'Cycle 2 S. 60'
    },
    CA_NORTH60_CYCLE1: {
        name: 'Cycle 1 N. 60'
    },
    CA_NORTH60_CYCLE2: {
        name: 'Cycle 2 N. 60'
    },
    US_PROPERTY_60_SHORTHAUL: {
        name: 'Property Short Haul 60'
    },
    US_PROPERTY_70_SHORTHAUL: {
        name: 'Property Short Haul 70'
    },
    US_UNIVERSAL_7: {
        name: 'Universal 7 Day'
    },
    US_UNIVERSAL_8: {
        name: 'Universal 8 Day'
    },
    US_PROPERTY_60_CONSTRUCTION: {
        name: 'Construction 60'
    },
    US_PROPERTY_70_CONSTRUCTION: {
        name: 'Construction 70'
    },
    US_PROPERTY_150_AIR_MILE_RADIUS: {
        name: '150 Air Mile Radius'
    },
    US_PROPERTY_70_TX_OIL_WELL: {
        name: 'Texas oil'
    },
    US_PROPERTY_70_TX: {
        name: 'Texas Intrastate'
    },
    US_PROPERTY_80_CA: {
        name: 'California Intrastate'
    },
    US_PROPERTY_80_CA_FLAMMABLE: {
        name: 'California Intrastate Flammable'
    },
    US_PROPERTY_70_FL: {
        name: 'Florida 70 Intrastate'
    },
    US_PROPERTY_80_FL: {
        name: 'Florida 80 Intrastate'
    },
    US_PROPERTY_70_FL_CONSTRUCTION: {
        name: 'Florida 70 Intrastate Construction'
    },
    US_PROPERTY_80_FL_CONSTRUCTION: {
        name: 'Florida 80 Intrastate Construction'
    },
    US_PROPERTY_70_AK: {
        name: 'Alaska 70 Intrastate'
    },
    US_PROPERTY_80_AK: {
        name: 'Alaska 80 Intrastate'
    },
    US_PASSENGER_70_AK: {
        name: 'Alaska 70 Passenger'
    },
    US_PASSENGER_80_AK: {
        name: 'Alaska 80 Passenger'
    }
};

export const METADATA = {
    TRAILER_DOLLY_LOAD: {
        header: ':origin :action :item :name',
        TRAILER_START: {
            origin: 'Driver',
            action: 'added',
            item: 'trailer'
        },
        TRAILER_END: {
            origin: 'Driver',
            action: 'removed',
            item: 'trailer'
        },
        DOLLY_START: {
            origin: 'Driver',
            action: 'added',
            item: 'dolly'
        },
        DOLLY_END: {
            origin: 'Driver',
            action: 'removed',
            item: 'dolly'
        },
        LOAD_START: {
            origin: 'Driver',
            action: 'added',
            item: 'load'
        },
        LOAD_END: {
            origin: 'Driver',
            action: 'removed',
            item: 'load'
        },
    },
    CO_DRIVER: {
        header: 'Co-Driver :name logged :action',
        CO_DRIVER_START: {
            action: 'in'
        },
        CO_DRIVER_END: {
            action: 'out'
        }
    }
};

export const ASSET_CHANGE = {
    header: ':origin updated power unit number.',
    login: 'Driver logged into new asset.',
    udl: 'Assumed UDL from another asset.',
    asset: ':origin updated asset information.'
};

export const EFFECT_USER_DESCRIPTOR = {
    header: ':origin Effect User Descriptor changed to :code',
    EFFECT_BIGDAY: {
        code: 'Big Day'
    },
    EFFECT_ADVERSE_WEATHER: {
        code: 'Adverse Weather'
    },
    EFFECT_RESTART: {
        code: '34 hour restart'
    }
};

export const FALLBACK_REASONS = {
    0: 'Unknown fallback reason. Recording as :ruleset from :time',
    1: 'Exceeded :distance air mile radius. Recording as :ruleset from :time',
    2: 'Undocked driving over five minutes. Recording as :ruleset from :time',
    3: 'Exceeded allowed number of hours. Recording as :ruleset from :time',
    4: 'Exceeded allowed number of hours. Recording as :ruleset from :time',
    5: 'Start & end location do not match. Recording as :ruleset from :time',
    6: 'Exceeded maximum work shifts allowed. Recording as :ruleset from :time',
};

export const CYCLE_CHANGE = {
    header: ':origin Cycle change to :cycle',
    CYCLE_1: { cycle: 'Cycle 1'},
    CYCLE_2: { cycle: 'Cycle 2'},
};

export const CANADA_OPERATING_ZONE = {
    header: ':origin changed the operating zone to :operating_zone',
    S_60: 'Canada South of 60',
    N_60: 'Canada North of 60'
};
