import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ByDriverProfileService} from '../api-middleware/api-service/compliance/services/by-driver-profile.service';

@Injectable({
    providedIn: 'root'
})
export class CertifyLogsService {

    constructor(private dutyStatusService: ByDriverProfileService) { }

    getDriverUncertifiedDays(driver_uuid: string): Observable<any> {
        return this.dutyStatusService.getGetUncertifiedDays(driver_uuid);
    }

    postDriverCertifyDays(driver_uuid: string, payload: any): Observable<any> {
        const params = {
            driverProfileUuid: driver_uuid,
            payload: {
                certified_days: [payload]
            }
        };
        return this.dutyStatusService.postCertifyLogs(params);
    }
}
