import {Action} from '@ngrx/store';
import {DriverGridDataState} from '../reducers/driver-grid-data.reducer';

export const GET_DRIVER_GRID_DATA = '[Get] CALL_DRIVER_GRID_DATA_API';
export const GET_DRIVER_GRID_DATA_SUCCESS = '[Get] GET_DRIVER_GRID_DATA_SUCCESS';


export class GetDriverGridData implements Action {
    readonly type = GET_DRIVER_GRID_DATA;
    constructor() {}
}

export class GetDriverGridDataSuccess implements Action {
    readonly type = GET_DRIVER_GRID_DATA_SUCCESS;
    constructor(public payload: DriverGridDataState) {}
}

export type Actions =
    | GetDriverGridData
    | GetDriverGridDataSuccess;
