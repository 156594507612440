import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {CardModel} from './content-card';
import {isNumber} from 'util';
import { Store } from '@ngrx/store';
import { DriverModuleState } from '../driver-logs/state/reducers/driver-logs.reducer';
import { selectDriverEventsState, selectDriverGridState } from '../driver-logs/state/driver-logs.selector';
import { DriverEvent } from '../driver-logs/model/driver-event';
import { FallbackInfo } from '../driver-logs/state/reducers/driver-events.reducer';
import { DriverGridDataState } from '../driver-logs/state/reducers/driver-grid-data.reducer';
import {UserInfoService} from '../user-info.service';

@Component({
    selector: 'app-content-card',
    templateUrl: './content-card.component.html',
    styleUrls: ['./content-card.component.scss']
})
export class ContentCardComponent implements OnInit {
    @Input() cardmodel: CardModel;
    @Input() type: any;
    @Input() key: any;
    @Input() loadClassActive: boolean;

    @Output() outClick = new EventEmitter<any>();

    hiddenClass = 'hidden-back';
    clickedClass = '';
    middleClass = 'hidden-back';

    buttonClickClass = '';

    outputModel: any;
    loaded = true;
    newStatus = '';

    circleDisabledClass: 'circle-disabled' | '' = '';
    changeStatusDisabledClass: 'change-status-disabled' | 'top-spacer' | 'change-status-disabled top-spacer' | ''= 'top-spacer';
    changeStatusDisabled = false;
    changeStatusDisabledInfoVisible = false;
    headlineText = '';
    descriptionText = '';
    isDisabled = false;

    NOTES_PREFIX = 'Odometer & Engine Hours are zero due to not being a vehicle';

    constructor(private driverLogsStore: Store<DriverModuleState>) {
        this.outputModel = {
            notes: this.NOTES_PREFIX
        };
    }

    ngOnInit() {
        this.driverLogsStore.select(selectDriverGridState).subscribe((gridstate: DriverGridDataState) => {
            if (!gridstate) { return; }

            if (gridstate.zone === 'N_60' || gridstate.zone === 'S_60') {
                if (this.cardmodel.mediaContent?.title === 'YM' || this.cardmodel.mediaContent?.title === 'PC') { // not sure if there's a better way to determine this
                    this.circleDisabledClass = 'circle-disabled';
                    this.changeStatusDisabledClass = 'change-status-disabled';
                    this.changeStatusDisabled = true;

                    if (this.cardmodel.mediaContent?.title === 'YM') {
                        this.headlineText = 'Unable to exit YM while undocked';
                        this.descriptionText = 'Per Canada Transport rules, drivers in Canada can’t exit YM unless logged into a docked tablet.';
                    }

                    if (this.cardmodel.mediaContent?.title === 'PC') {
                        this.headlineText = 'Unable to exit PC while undocked';
                        this.descriptionText = 'Per Canada Transport rules, drivers in Canada can’t exit PC unless logged into a docked tablet.';
                    }
                }
            };
            UserInfoService.driverWithNoDS.subscribe( val => {
              this.changeStatusDisabledClass = val ? 'change-status-disabled top-spacer' : this.changeStatusDisabledClass;
              this.isDisabled = val;
            });
        });
    }

    submitDisabled() {
        const locationValid = !!(this.outputModel.manual_loc && this.outputModel.manual_loc.length >= 5);
        const notesValid = !!(this.outputModel.notes && this.outputModel.notes.length >= 4);
        const locationOrNotesInvalid = !(locationValid && notesValid);
        return locationOrNotesInvalid;
    }

    onClick(event, id) {
        if (this.changeStatusDisabled) { return; }

        this.outClick.emit({'value': 'clicked', 'key': this.key});
        this.buttonClickClass = 'clicked';

        switch (id) {

            // from cardmodel.mediaContent.state
            case 'ON':
            case 'ON_DUTY':
                this.showBack();
                this.newStatus = 'OFF';
                break;

            // from cardmodel.mediaContent.state
            case 'OFF':
            case 'OFF_DUTY':
                this.showBack();
                this.newStatus = 'ON';
                break;

            default:
                // first click on the widget
                if (this.hiddenClass && this.middleClass) {
                    this.showMiddle();

                // clicking on either 'ON DUTY' or 'OFF DUTY' in the second('middle') view
                } else if (this.hiddenClass && !this.middleClass) {
                    switch (id) {
                        case 'ON DUTY':
                            this.newStatus = 'ON';
                            break;

                        case 'OFF DUTY':
                            this.newStatus = 'OFF';
                            break;

                        default:
                            // nothing; this value is hard-coded
                    }

                    this.showBack();
                    this.outClick.emit({ 'value': this.newStatus, 'key': this.key });
                }
        }
    }

    cancelStatusUpdate() {
        this.buttonClickClass = '';
        this.outClick.emit({'value': 'CANCEL', 'key': this.key});
        try {
            this.clickedClass = 'clicked';
            this.showFront();
            if (this.cardmodel.mediaContent.state) {
                this.cardmodel.mediaType = 'status';
            }
        } catch (Exception) {
            // pass
        }
    }

    saveStatusUpdate() {
        this.buttonClickClass = '';
        this.showFront();
        const nowStamp = new Date();
        this.outputModel.created = nowStamp.toISOString();
        this.outputModel.duty_status = this.newStatus;

        // leaving the logic on flipping the status out to simplify data model

        this.outClick.emit({'value': this.outputModel, 'key': this.key});
        this.outputModel = {};
    }

    showFront() {
        this.clickedClass = 'animate-front-' + (this.hiddenClass && !this.middleClass ? 1 : 2);
        this.hiddenClass = 'hidden-back';
        this.middleClass = 'hidden-middle';
    }

    showMiddle() {
        this.clickedClass = 'animate-middle';
        this.hiddenClass = 'hidden-back';
        this.middleClass = '';
    }

    showBack() {
        this.clickedClass = 'animate-back';
        this.hiddenClass = '';
        this.middleClass = 'hidden-middle';

        // todo: this... doesn't look like a best practice
        const widthChange = Array.from(document.getElementsByClassName('mat-form-field-outline-start') as HTMLCollectionOf<HTMLElement>);
        widthChange.forEach((element) => {
            element.outerHTML = '<div class="mat-form-field-outline-start" style="width: 0px;"></div>';
        });

        // todo: this... doesn't look like a best practice
        const styleChange = Array.from(document.getElementsByClassName('mat-form-field-infix') as HTMLCollectionOf<HTMLElement>);
        styleChange.forEach((element: HTMLElement) => {
            element.style.padding = '10px';
            element.style.borderTop = '0';
        });
    }

    clearText() {
        if (this.outputModel.notes === this.NOTES_PREFIX) {
            this.outputModel.notes = '';
        }
    }

    showChangeStatusDisabledInfo() {
        this.changeStatusDisabledInfoVisible = true;
    }

    hideChangeStatusDisabledInfo() {
        this.changeStatusDisabledInfoVisible = false;
    }
}
