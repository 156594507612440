import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MaterialModule } from '../material.module';
import { NotificationModule } from './notification/notification.module';
import { AccountSwitcherModule } from '../account-switcher/account-switcher.module';

import { AccountSwitcherComponent } from '../account-switcher/account-switcher.component';
import { NotificationComponent } from './notification/notification.component';
import { ContentHeroComponent } from './content-hero/content-hero.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import {TitleComponent} from './title/title.component';
import { ExportLogsModule } from '../export-logs/export-logs.module';
import {DriverLogsModule} from '../driver-logs/driver-logs.module';
import {ZonarUiIconModule} from '@zonar-ui/icon';

export {
  HeaderComponent,
  FooterComponent,
  NotificationComponent,
  NotificationModule,
  ContentHeroComponent,
  TitleComponent,
};
@NgModule({
  imports: [
    CommonModule,
    NotificationModule,
    MatMenuModule,
    MaterialModule,
    AccountSwitcherModule,
    ZonarUiIconModule
  ],
  declarations: [
    ContentHeroComponent,
    HeaderComponent,
    FooterComponent,
    TitleComponent,
  ],
  exports: [
    NotificationComponent,
    ContentHeroComponent,
    HeaderComponent,
    FooterComponent,
    NotificationModule,
    TitleComponent,
    ExportLogsModule,
    DriverLogsModule,
  ]
})
export class LayoutModule { }
