import {
    CertifyLogsAction,
    GET_UNCERTIFIED_DAYS,
    GET_UNCERTIFIED_DAYS_SUCCESS,
    POST_DRIVER_CERTIFY_DAY_SUCCESS,
    SET_CERTIFY_MODE
} from './certify-logs.actions';

export const CertifyLogsReducer = setReducer;

export interface CertifyLogsState {
    days: string[];
    oldest_uncertified_day: number;
    isInCertifyMode: boolean;
}

export const initialState: CertifyLogsState = {
    days: [],
    oldest_uncertified_day: 0,
    isInCertifyMode: false,
};

export function setReducer(state = initialState, action: CertifyLogsAction ): CertifyLogsState {
    switch (action.type) {
        case GET_UNCERTIFIED_DAYS:
            return state;
        case GET_UNCERTIFIED_DAYS_SUCCESS:
            return {
                ...state,
                days: action.payload.days,
                oldest_uncertified_day: action.payload.oldest_uncertified_day
            };
        case POST_DRIVER_CERTIFY_DAY_SUCCESS:
            return {
                ...state,
            };
        case SET_CERTIFY_MODE:
            return {
                ...state,
                isInCertifyMode: action.isInCertifyMode
            };
        default:
            return state;
    }
}
