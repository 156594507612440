import {Component, Input, OnInit} from '@angular/core';
import {DriverEventGenericCardComponent} from './driver-event-generic-card.component';
import {DUTY_STATUS} from './driver-event-header';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {DriverEventEditComponent} from '../driver-event-edit/driver-event-edit.component';
import {POST_DRIVER_EDIT_EXISTING_EVENT} from '../state/actions/driver-events.actions';
import {DriverCycleChangeEditComponent} from '../driver-event-edit/driver-cycle-change-edit/driver-cycle-change-edit.component';
import {Store} from '@ngrx/store';
import {DriverModuleState} from '../state/reducers/driver-logs.reducer';
import {selectIsKilometers} from '../state/driver-logs.selector';
import {ZonarConvertPipe} from '../pipe/zonar-convert.pipe';
import dayjs from 'dayjs';

@Component({
  selector: 'app-driver-event-card',
  templateUrl: './driver-event-card.component.html',
  styleUrls: ['./driver-event-card.component.scss']
})
export class DriverEventCardComponent extends DriverEventGenericCardComponent implements OnInit {

  public showNotes = false;
  public showDeferredTime = false;
  public showCmvNumber = false;
  public distanceLabel = 'Miles';

  @Input() isInCertifyMode: Boolean = false;
  constructor(private dialog: MatDialog,
              private driverLogsStore: Store<DriverModuleState>) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.driverEvent && this.driverEvent.parent_event) {
      const parentEvent = this.driverEvent.parent_event;
      this.driverEvent.location = parentEvent.location;
      this.driverEvent.comments = parentEvent.comments;
      this.driverEvent.event_record_origin_name = parentEvent.event_record_origin_name;
      this.driverEvent.event_record_origin_id = parentEvent.event_record_origin_id;
    }

    this.setShowBooleans();
    this.driverLogsStore.select(selectIsKilometers).subscribe((isKms) => {
      if (isKms) {
        this.distanceLabel = 'Kilometers';
      } else {
        this.distanceLabel = 'Miles';
      }
    });
  }

  // todo: these should probably be in driver-logs.service
  public setShowBooleans() {
    if (this.driverEvent.display_comment) {
      this.showNotes = true;
    }

    if (this.driverEvent.data && this.driverEvent.data.time_deferred && this.driverEvent.event_code_id === 1) {
      this.showDeferredTime = true;
    }

    if (this.driverEvent.data && this.driverEvent.data.cmv_order_number) {
      this.showCmvNumber = true;
    }
  }

  public dutyStatusAbbr(eventCodeName: string): string {
    return DUTY_STATUS[eventCodeName].abbr;
  }

  public openEditModal() {
    const dialogConfig = new MatDialogConfig();
    let component;
    if (this.driverEvent.event_type_name === 'CYCLE_CHANGE') {
      component = DriverCycleChangeEditComponent;
    } else {
      component = DriverEventEditComponent;
    }
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      event: this.driverEvent,
      action: POST_DRIVER_EDIT_EXISTING_EVENT,
      title: 'Edit Event',
      pendoClass: 'edit-event-submit'
    };
    this.dialog.open(component, dialogConfig);
  }

  public getComments() {
    if (this.driverEvent.event_code_name === 'ADVERSE_WEATHER') {
      return 'Exemption Applied to Drive time at ' + dayjs(this.driverEvent.driver_ts).format('MM/DD/YYYY HH:mm:ss');
    } else {
      return this.driverEvent.comments;
    }
  }

  public locationText(location: string): string {
    // separate the distance value and units from the location text
    const texts = location.split(' ');
    const textLength = texts[0].length;
    const distUnits = texts[0].substring(textLength - 2);
    const distValue = Number(texts[0].substring(0, textLength - 2));
    if ((this.distanceLabel === 'Miles' && distUnits === 'mi') ||
        (this.distanceLabel === 'Kilometers' && distUnits === 'km' ||
        !distValue) ) {
      return location; }
    texts[0] = ZonarConvertPipe.convertDistance(this.distanceLabel, distValue, distUnits);
    return texts.join(' ');
  }

  public showLocation(): boolean {
    if (this.driverEvent.location && this.driverEvent.event_type_name !== 'CYCLE_CHANGE') {
      return true;
    }
    return false;
  }

  public showEventCard(): boolean {
    if (this.driverEvent.display_accum_miles === true || this.driverEvent.event_type_name === 'DEFERRAL_STATE') {
      return true;
    }
    return false;
  }

  public manualOdoFunction(odo: number): number {
    const converter = new ZonarConvertPipe();
    if (this.distanceLabel === 'Kilometers' && this.driverEvent.is_manual_odo) {
      odo = converter.transform(odo, 'kilometers', 'miles') as number;
    }
    return odo;
  }

}
