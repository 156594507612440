import { Component, OnInit } from '@angular/core';
import {Store} from '@ngrx/store';
import {CertifyLogsState} from '../state/certify-logs.reducer';
import {selectOldestUncertifiedDay} from '../state/certify-logs.selectors';

export const MIN_DAYS = 7;
export const MAX_DAYS = 14;

@Component({
    selector: 'app-certify-logs-warning',
    templateUrl: './certify-logs-warning.component.html',
    styleUrls: ['../certify-logs.scss']
})
export class CertifyLogsWarningComponent implements OnInit {
    oldestUncertifiedDay: number;
    warningNumber = MIN_DAYS;
    showWarning = false;
    maxWarning = false;

    constructor(private certifyLogsStore: Store<CertifyLogsState>) { }

    ngOnInit() {
        this.certifyLogsStore.select(selectOldestUncertifiedDay).subscribe((oldest) => {
            this.oldestUncertifiedDay = oldest;
            this.warningState(this.oldestUncertifiedDay);
        });
    }

    warningState(day: number) {
        if (day < MIN_DAYS) {
            this.showWarning = false;
            return;
        }
        this.showWarning = true;
        if (day >= MIN_DAYS && day < MAX_DAYS) {
            this.warningNumber = day;
        } else {
            this.maxWarning = true;
        }
    }

}
