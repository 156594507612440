<div class="violation-summary mat-app-background zonar-default-theme">
  <div class="bold violation-title">Violation Data<zui-icon class="exit-button" icon="close" (click)="close()"></zui-icon></div>
  <div *ngFor="let dutyStatus of dutyStatuses; first as isFirst" class="violation-block">
    <hr *ngIf="!isFirst">
    <div class="duty-status-small">{{readableDutyStatus[dutyStatus.dutyStatusName]}}
      at {{dutyStatus.startTime | zonarTimestamp: 'time':false:driverTimezone}}</div>
    <div *ngFor="let violation of dutyStatus.violations" class="violation">
      <div class="bold readable-violation">{{violationsLang[violation.code]}}</div>
      <div *ngIf="violation.startEpoch" class="start-time">Start: <span
        class="bold"> {{violation.startEpoch | zonarTimestamp: 'yearMonthDayTime':false:driverTimezone}}</span>
        <span class="violation-duration"> (Duration: <span class="bold">{{durationText(violation)}}</span>)</span>
      </div>
    </div>
  </div>
</div>
