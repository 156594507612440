import {  DriversService } from './api-service/driver/services';
import { DriverprofilesService } from './api-service/driver/services';
import { ApiService as UserService } from './api-service/user/services/api.service';
import {PostDutyStatus, PostDriverPDFExport, DutyStatus} from './api-service/compliance/models';
import { CompaniesService as CompanyService } from './api-service/company/services';
import { DivisionsService } from './api-service/company/services';
import { ByDriverProfileService } from './api-service/compliance/services';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ApiMiddlewareService {
    constructor(
        public userService: UserService,
        public matSnackBar: MatSnackBar,
        public driverService: DriversService,
        public driverProfileService: DriverprofilesService,
        public companyService: CompanyService,
        public dutyStatusService: ByDriverProfileService,
        public divisionService: DivisionsService,
    ) {}
    private getDriverPromise(userId: string) {
         return new Promise(resolve => {
            this.driverService.getDriversApiList(userId).subscribe(e => {
                resolve(e);
              });
         });
    }
    private getUserPromise(iProviderId: string) {
        return new Promise(resolve => {
            this.userService.findUser({'identityProviderId': iProviderId, 'exactMatch': 'true'}).subscribe(e => {
                resolve(e);
              });
         });
    }
    private getDriverProfilePromise(driverId: string) {
        return new Promise(resolve => {
            this.driverProfileService.getDriverProfilesApiList({'driverId': driverId}).subscribe(e => {
                resolve(e);
              });
         });
    }

    private getCompanyProfilePromise(companyId: string) {
        return new Promise(resolve => {
            this.companyService.getSingleCompanyApi(companyId).subscribe(e => {
                resolve(e);
            }, (err) => { resolve(err);
            });
        });
    }
    private getDivisionPromise(companyId: string, divisionIds: any) {
        return new Promise(resolve => {
            const args = {companyId: companyId, divisionIds: divisionIds};
            this.divisionService.getAllDivisionsApi(args).subscribe(e => {
                resolve(e);
            }, (err) => { resolve(err);
            });
        });
    }
    private setDutyStatusPromise(driverProfileId: string, user: PostDutyStatus, type: 'ELD' | 'AOBRD') {
        return new Promise(resolve => {
            const driverDetails = {'driverProfileUuid': driverProfileId, 'payload': user, 'type': type };
            this.dutyStatusService.postDutyStatusByTypeResource(driverDetails).subscribe(e => {
                resolve(e);
            }, err => {
                console.log(err);
                this.matSnackBar.open('failed to save duty status', '', { duration: 2000, panelClass: ['red-snackbar']});
            });
            });
    }
    public async getCompanyProfile(companyId: string) {
        return await this.getCompanyProfilePromise(companyId).then((data: any) => data).catch((err) => {console.log(err); });
    }
    public async getDivisions(companyId: string, divisionIds: any) {
        return await this.getDivisionPromise(companyId, divisionIds).then((data: any) => data).catch((err) => {console.log(err); });
    }
    public async getDriver(driverId: string) {
        return await this.getDriverPromise(driverId).then((data: any) => data[0]).catch((err) => {console.log(err); });
    }
    public async getUser(iProviderId: string) {
        return await this.getUserPromise(iProviderId).then(
            (data: any) => data).catch((err) => {console.log(err); });
    }
    public async getDriverProfile(driverId: string) {
        return await this.getDriverProfilePromise(driverId);
    }

    public async setDutyStatus(driverProfileId: string, user: PostDutyStatus, type: 'ELD' | 'AOBRD') {
        return await this.setDutyStatusPromise(driverProfileId, user, type);
    }

    private getPDFExportPromise(driverProfileId: string, emailAddress: string, name: string,
                                startDay: string, endDay: string, showViolations: boolean = true) {
        return new Promise(resolve => {
            const driverDetails = {
                driverProfileUuid: driverProfileId,
                startDay: startDay,
                endDay: endDay,
                payload: {
                    show_violations: showViolations,
                    start_day: startDay,
                    end_day: endDay
                } as PostDriverPDFExport
            };
            this.dutyStatusService.postPdfExportByDriverResource(driverDetails).subscribe(e => {
                resolve(e);
            }, err => {
                this.matSnackBar.open('failed to send pdf', '', { duration: 2000, panelClass: ['red-snackbar']});
            });
        });
    }
    public async getPDFExport(id, emailAddress: string, name: string, startDay: string, endDay: string, showViolations: boolean = true) {
        return await this.getPDFExportPromise(id, emailAddress, name, startDay, endDay, showViolations);
    }

    private logDriverOutOfTabletPromise(driverProfileId: string) {
        return new Promise(resolve => {
            this.dutyStatusService.postLogDriverOutByDriverResource(driverProfileId).subscribe(e => {
                resolve(e);
            });
        });
    }
    public async logDriverOutOfTablet(id: string) {
        return this.logDriverOutOfTabletPromise(id);
    }
}
