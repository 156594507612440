import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {AuthService} from '@auth0/auth0-angular';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css']
})
export class CallbackComponent implements OnInit {
  profileJson

  constructor(
    public router: Router,
    public auth: AuthService
  ) {
    this.auth.user$.subscribe((userProfile) => {
      this.router.navigate(['/dashboard']);
    });
  }

  async ngOnInit() {
  }

}
