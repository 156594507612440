import {Pipe, PipeTransform} from '@angular/core';


@Pipe({name: 'zonarConvert'})
export class ZonarConvertPipe implements PipeTransform {
    static MI2M = 1609.344;
    static MI2KM = 1.609344;
    static KM2MI = 0.621371;

    public static convertDistance(distanceLabel: string, distanceValue: number, distanceUnits: string) {
      let length;
      let returnUnit;
      if (distanceUnits === 'km') {
        length = Math.floor(+distanceValue * ZonarConvertPipe.KM2MI);
        returnUnit = 'mi';
      } else {
        length = Math.floor(+distanceValue * ZonarConvertPipe.MI2KM);
        returnUnit = 'km';
      }
      if (length < 1) { length = 1; }
      return length + returnUnit;
    }

    public transform(input?: number, format = '', inputFormat = '') {
        if (!Number.isInteger(input)) {
            return 'Not Available';
        }
        // odo are in meters
        // manual odo are in miles
        format = format.toLowerCase();
        inputFormat = inputFormat.toLowerCase();
        // convert everything to meters
        switch (inputFormat) {
            case 'miles':
                input = this.convertMilesToMeters(input);
                break;
            case 'kilometers':
                input = this.convertKilometersToMeters(input);
                break;
            default:
                break;
        }
        // input in meters

        switch (format) {
            case 'miles':
                return this.convertMetersToMiles(input);
            case 'hrs':
                return this.convertToHrs(input);
            case 'kilometers':
                return this.convertMetersToKilometers(input);
            case 'meters':
                return input;
            default:
                return;
        }
    }

    private convertToHrs(input: number) {
        return +(Math.round((input / 100.0) * 10) / 10).toFixed(1);
    }

    private convertMilesToMeters(value: number): number {
        return Math.round(value * ZonarConvertPipe.MI2M);
    }

    private convertMetersToMiles(value: number): number {
        return +Math.floor(value / ZonarConvertPipe.MI2M).toFixed(2);
    }

    private convertKilometersToMeters(value: number): number {
        return Math.round(value * 1000);
    }

    private convertMetersToKilometers(value: number): number {
        return +Math.floor(value / 1000).toFixed(2);
    }
}
