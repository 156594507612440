/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Availability } from '../models/availability';
import { DriverList } from '../models/driver-list';
import { UnknownDrivingLog } from '../models/unknown-driving-log';
import { VehicleModel } from '../models/vehicle-model';
import { Violations } from '../models/violations';

/**
 * Endpoints that return data for all the entities in a division.
 */
@Injectable({
  providedIn: 'root',
})
class ByDivisionService extends __BaseService {
  static readonly getAvailabilityApiPath = '/division/{divisionId}/availability';
  static readonly getDriverListApiPath = '/division/{divisionId}/list_drivers';
  static readonly getUdlApiPath = '/division/{divisionId}/udls';
  static readonly getVehiclesApiPath = '/division/{divisionId}/vehicles';
  static readonly getAllViolationsApiPath = '/division/{divisionId}/violations';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ByDivisionService.GetAvailabilityApiParams` containing the following parameters:
   *
   * - `legacyAccountCode`: GTC account code (will be deprecated). {error_msg}
   *
   * - `divisionId`: Unused, will be required in later API versions.
   *
   * - `offset`: Offset into the list of drivers
   *
   * - `limit`: Max number of drivers to process
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Driver Availability
   */
  getAvailabilityApiResponse(params: ByDivisionService.GetAvailabilityApiParams): __Observable<__StrictHttpResponse<Array<Availability>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.legacyAccountCode != null) __params = __params.set('legacyAccountCode', params.legacyAccountCode.toString());

    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/division/${encodeURIComponent(String(params.divisionId))}/availability`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Availability>>;
      })
    );
  }
  /**
   * @param params The `ByDivisionService.GetAvailabilityApiParams` containing the following parameters:
   *
   * - `legacyAccountCode`: GTC account code (will be deprecated). {error_msg}
   *
   * - `divisionId`: Unused, will be required in later API versions.
   *
   * - `offset`: Offset into the list of drivers
   *
   * - `limit`: Max number of drivers to process
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Driver Availability
   */
  getAvailabilityApi(params: ByDivisionService.GetAvailabilityApiParams): __Observable<Array<Availability>> {
    return this.getAvailabilityApiResponse(params).pipe(
      __map(_r => _r.body as Array<Availability>)
    );
  }

  /**
   * @param params The `ByDivisionService.GetDriverListApiParams` containing the following parameters:
   *
   * - `legacyAccountCode`: GTC account code (will be deprecated). {error_msg}
   *
   * - `divisionId`: Unused, will be required in later API versions.
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Returns an array of objects containing driver ids for a given legacy account code. The drivers represented are not filtered by any ruleset
   */
  getDriverListApiResponse(params: ByDivisionService.GetDriverListApiParams): __Observable<__StrictHttpResponse<Array<DriverList>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.legacyAccountCode != null) __params = __params.set('legacyAccountCode', params.legacyAccountCode.toString());

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/division/${encodeURIComponent(String(params.divisionId))}/list_drivers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DriverList>>;
      })
    );
  }
  /**
   * @param params The `ByDivisionService.GetDriverListApiParams` containing the following parameters:
   *
   * - `legacyAccountCode`: GTC account code (will be deprecated). {error_msg}
   *
   * - `divisionId`: Unused, will be required in later API versions.
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Returns an array of objects containing driver ids for a given legacy account code. The drivers represented are not filtered by any ruleset
   */
  getDriverListApi(params: ByDivisionService.GetDriverListApiParams): __Observable<Array<DriverList>> {
    return this.getDriverListApiResponse(params).pipe(
      __map(_r => _r.body as Array<DriverList>)
    );
  }

  /**
   * @param params The `ByDivisionService.GetUdlApiParams` containing the following parameters:
   *
   * - `start`:
   *
   * - `legacyAccountCode`: GTC account code (will be deprecated). {error_msg}
   *
   * - `end`:
   *
   * - `divisionId`: Unused, will be required in later API versions.
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return UDL
   */
  getUdlApiResponse(params: ByDivisionService.GetUdlApiParams): __Observable<__StrictHttpResponse<Array<UnknownDrivingLog>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.start != null) __params = __params.set('start', params.start.toString());
    if (params.legacyAccountCode != null) __params = __params.set('legacyAccountCode', params.legacyAccountCode.toString());
    if (params.end != null) __params = __params.set('end', params.end.toString());

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/division/${encodeURIComponent(String(params.divisionId))}/udls`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UnknownDrivingLog>>;
      })
    );
  }
  /**
   * @param params The `ByDivisionService.GetUdlApiParams` containing the following parameters:
   *
   * - `start`:
   *
   * - `legacyAccountCode`: GTC account code (will be deprecated). {error_msg}
   *
   * - `end`:
   *
   * - `divisionId`: Unused, will be required in later API versions.
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return UDL
   */
  getUdlApi(params: ByDivisionService.GetUdlApiParams): __Observable<Array<UnknownDrivingLog>> {
    return this.getUdlApiResponse(params).pipe(
      __map(_r => _r.body as Array<UnknownDrivingLog>)
    );
  }

  /**
   * @param params The `ByDivisionService.GetVehiclesApiParams` containing the following parameters:
   *
   * - `legacyAccountCode`: GTC account code (will be deprecated). {error_msg}
   *
   * - `divisionId`: Unused, will be required in later API versions.
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return List of vehicles (with drivers)
   */
  getVehiclesApiResponse(params: ByDivisionService.GetVehiclesApiParams): __Observable<__StrictHttpResponse<Array<VehicleModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.legacyAccountCode != null) __params = __params.set('legacyAccountCode', params.legacyAccountCode.toString());

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/division/${encodeURIComponent(String(params.divisionId))}/vehicles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<VehicleModel>>;
      })
    );
  }
  /**
   * @param params The `ByDivisionService.GetVehiclesApiParams` containing the following parameters:
   *
   * - `legacyAccountCode`: GTC account code (will be deprecated). {error_msg}
   *
   * - `divisionId`: Unused, will be required in later API versions.
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return List of vehicles (with drivers)
   */
  getVehiclesApi(params: ByDivisionService.GetVehiclesApiParams): __Observable<Array<VehicleModel>> {
    return this.getVehiclesApiResponse(params).pipe(
      __map(_r => _r.body as Array<VehicleModel>)
    );
  }

  /**
   * @param params The `ByDivisionService.GetAllViolationsApiParams` containing the following parameters:
   *
   * - `start`:
   *
   * - `legacyAccountCode`: GTC account code (will be deprecated). {error_msg}
   *
   * - `end`:
   *
   * - `divisionId`: Unused, will be required in later API versions.
   *
   * - `type`: Violation type (can be comma-separated list). {error_msg}
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return HOS Violations
   */
  getAllViolationsApiResponse(params: ByDivisionService.GetAllViolationsApiParams): __Observable<__StrictHttpResponse<Array<Violations>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.start != null) __params = __params.set('start', params.start.toString());
    if (params.legacyAccountCode != null) __params = __params.set('legacyAccountCode', params.legacyAccountCode.toString());
    if (params.end != null) __params = __params.set('end', params.end.toString());

    if (params.type != null) __params = __params.set('type', params.type.toString());
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/division/${encodeURIComponent(String(params.divisionId))}/violations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Violations>>;
      })
    );
  }
  /**
   * @param params The `ByDivisionService.GetAllViolationsApiParams` containing the following parameters:
   *
   * - `start`:
   *
   * - `legacyAccountCode`: GTC account code (will be deprecated). {error_msg}
   *
   * - `end`:
   *
   * - `divisionId`: Unused, will be required in later API versions.
   *
   * - `type`: Violation type (can be comma-separated list). {error_msg}
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return HOS Violations
   */
  getAllViolationsApi(params: ByDivisionService.GetAllViolationsApiParams): __Observable<Array<Violations>> {
    return this.getAllViolationsApiResponse(params).pipe(
      __map(_r => _r.body as Array<Violations>)
    );
  }
}

module ByDivisionService {

  /**
   * Parameters for getAvailabilityApi
   */
  export interface GetAvailabilityApiParams {

    /**
     * GTC account code (will be deprecated). {error_msg}
     */
    legacyAccountCode: string;

    /**
     * Unused, will be required in later API versions.
     */
    divisionId: string;

    /**
     * Offset into the list of drivers
     */
    offset?: number;

    /**
     * Max number of drivers to process
     */
    limit?: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getDriverListApi
   */
  export interface GetDriverListApiParams {

    /**
     * GTC account code (will be deprecated). {error_msg}
     */
    legacyAccountCode: string;

    /**
     * Unused, will be required in later API versions.
     */
    divisionId: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getUdlApi
   */
  export interface GetUdlApiParams {
    start: string;

    /**
     * GTC account code (will be deprecated). {error_msg}
     */
    legacyAccountCode: string;
    end: string;

    /**
     * Unused, will be required in later API versions.
     */
    divisionId: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getVehiclesApi
   */
  export interface GetVehiclesApiParams {

    /**
     * GTC account code (will be deprecated). {error_msg}
     */
    legacyAccountCode: string;

    /**
     * Unused, will be required in later API versions.
     */
    divisionId: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getAllViolationsApi
   */
  export interface GetAllViolationsApiParams {
    start: string;

    /**
     * GTC account code (will be deprecated). {error_msg}
     */
    legacyAccountCode: string;
    end: string;

    /**
     * Unused, will be required in later API versions.
     */
    divisionId: string;

    /**
     * Violation type (can be comma-separated list). {error_msg}
     */
    type?: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { ByDivisionService }
