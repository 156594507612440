import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentCardComponent } from './content-card.component';
import { MaterialModule } from '../material.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { ZonarUiIconModule } from '@zonar-ui/icon';
export {
  ContentCardComponent
};
@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ZonarUiIconModule
  ],
  declarations: [ContentCardComponent],
  exports: [ContentCardComponent],
  providers: [
    MaterialModule
  ]
})
export class ContentCardModule { }
