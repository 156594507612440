import {Action} from '@ngrx/store';
import {DeferralState, DriverClocksState} from '../reducers/driver-clocks.reducer';


export const GET_DRIVER_CLOCKS = '[Get] CALL_DRIVER_CLOCKS_API';
export const GET_DRIVER_CLOCKS_SUCCESS = '[Get] GET_DRIVER_CLOCKS_SUCCESS';

export const GET_DEFERRAL_STATE = '[Get] GET_DEFERRAL_STATE';

export interface DriverLogsParams {
    day: string;
    driverProfileUuid: string;
}

export class GetDriverClocks implements Action {
    readonly type = GET_DRIVER_CLOCKS;
    constructor() {}
}

export class GetDriverClocksSuccess implements Action {
    readonly type = GET_DRIVER_CLOCKS_SUCCESS;
    constructor(public payload: DriverClocksState) {}
}

export class GetDeferralState implements Action {
    readonly type = GET_DEFERRAL_STATE;
    constructor(public payload: DeferralState) {}
}

export type Actions =
    | GetDriverClocks
    | GetDriverClocksSuccess
    | GetDeferralState;
