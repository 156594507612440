import {Actions, GET_DRIVER_CLOCKS, GET_DRIVER_CLOCKS_SUCCESS} from '../actions/driver-clocks.actions';
import {DriverClock} from '../../model/driver-clock';

export interface DriverClocksState {
    clocks: DriverClock[];
    rulesetId: number;
    deferralInfo: DeferralState;
    zone: string;
}

export interface DeferralState {
  deferral_code?: number;
  deferral_code_next?: number;
  deferral_seconds?: number;
  is_available?: boolean;
  is_eligible?: boolean;
  reason?: number
}

export const initialState: DriverClocksState = {
    clocks: [],
    rulesetId: 2,
    deferralInfo: null,
    zone: null
};

export function driverClocksReducer(state = initialState, action: Actions): DriverClocksState {
    switch (action.type) {
        case GET_DRIVER_CLOCKS:
            return state;
        case GET_DRIVER_CLOCKS_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}
