/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Company } from '../models/company';
import { PostCompany } from '../models/post-company';
import { PatchCompany } from '../models/patch-company';

/**
 * Companies lookup.
 */
@Injectable({
  providedIn: 'root',
})
class CompaniesService extends __BaseService {
  static readonly getAllCompaniesApiPath = '/companies';
  static readonly postAllCompaniesApiPath = '/companies';
  static readonly getSingleCompanyApiPath = '/companies/{companyId}';
  static readonly patchSingleCompanyApiPath = '/companies/{companyId}';
  static readonly getSingleCompanyLegacyCodesApiPath = '/companies/{companyId}/legacy-account-codes';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `CompaniesService.GetAllCompaniesApiParams` containing the following parameters:
   *
   * - `q`: https://confluence.zonarsystems.net/display/VHDS/Entity+API+Query+String+Syntax
   *
   * - `productId`:
   *
   * - `per_page`:
   *
   * - `page`:
   *
   * - `locationId`:
   *
   * - `legacyAccountCode`:
   *
   * - `df`: https://confluence.zonarsystems.net/display/VHDS/Entity+API+Query+String+Syntax
   *
   * - `default_operator`: https://confluence.zonarsystems.net/display/VHDS/Entity+API+Query+String+Syntax
   *
   * @return Search results matching criteria.
   */
  getAllCompaniesApiResponse(params: CompaniesService.GetAllCompaniesApiParams): __Observable<__StrictHttpResponse<Array<Company>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.q != null) __params = __params.set('q', params.q.toString());
    if (params.productId != null) __params = __params.set('productId', params.productId.toString());
    if (params.perPage != null) __params = __params.set('per_page', params.perPage.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.locationId != null) __params = __params.set('locationId', params.locationId.toString());
    if (params.legacyAccountCode != null) __params = __params.set('legacyAccountCode', params.legacyAccountCode.toString());
    if (params.df != null) __params = __params.set('df', params.df.toString());
    if (params.defaultOperator != null) __params = __params.set('default_operator', params.defaultOperator.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/companies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Company>>;
      })
    );
  }
  /**
   * @param params The `CompaniesService.GetAllCompaniesApiParams` containing the following parameters:
   *
   * - `q`: https://confluence.zonarsystems.net/display/VHDS/Entity+API+Query+String+Syntax
   *
   * - `productId`:
   *
   * - `per_page`:
   *
   * - `page`:
   *
   * - `locationId`:
   *
   * - `legacyAccountCode`:
   *
   * - `df`: https://confluence.zonarsystems.net/display/VHDS/Entity+API+Query+String+Syntax
   *
   * - `default_operator`: https://confluence.zonarsystems.net/display/VHDS/Entity+API+Query+String+Syntax
   *
   * @return Search results matching criteria.
   */
  getAllCompaniesApi(params: CompaniesService.GetAllCompaniesApiParams): __Observable<Array<Company>> {
    return this.getAllCompaniesApiResponse(params).pipe(
      __map(_r => _r.body as Array<Company>)
    );
  }

  /**
   * @param payload undefined
   * @return Company created.
   */
  postAllCompaniesApiResponse(payload: PostCompany): __Observable<__StrictHttpResponse<Company>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/companies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Company>;
      })
    );
  }
  /**
   * @param payload undefined
   * @return Company created.
   */
  postAllCompaniesApi(payload: PostCompany): __Observable<Company> {
    return this.postAllCompaniesApiResponse(payload).pipe(
      __map(_r => _r.body as Company)
    );
  }

  /**
   * @param companyId undefined
   * @return The company being returned.
   */
  getSingleCompanyApiResponse(companyId: string): __Observable<__StrictHttpResponse<Company>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/companies/${encodeURIComponent(String(companyId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Company>;
      })
    );
  }
  /**
   * @param companyId undefined
   * @return The company being returned.
   */
  getSingleCompanyApi(companyId: string): __Observable<Company> {
    return this.getSingleCompanyApiResponse(companyId).pipe(
      __map(_r => _r.body as Company)
    );
  }

  /**
   * @param params The `CompaniesService.PatchSingleCompanyApiParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `companyId`:
   */
  patchSingleCompanyApiResponse(params: CompaniesService.PatchSingleCompanyApiParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/companies/${encodeURIComponent(String(params.companyId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CompaniesService.PatchSingleCompanyApiParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `companyId`:
   */
  patchSingleCompanyApi(params: CompaniesService.PatchSingleCompanyApiParams): __Observable<null> {
    return this.patchSingleCompanyApiResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param companyId undefined
   */
  getSingleCompanyLegacyCodesApiResponse(companyId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/companies/${encodeURIComponent(String(companyId))}/legacy-account-codes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param companyId undefined
   */
  getSingleCompanyLegacyCodesApi(companyId: string): __Observable<null> {
    return this.getSingleCompanyLegacyCodesApiResponse(companyId).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module CompaniesService {

  /**
   * Parameters for getAllCompaniesApi
   */
  export interface GetAllCompaniesApiParams {

    /**
     * https://confluence.zonarsystems.net/display/VHDS/Entity+API+Query+String+Syntax
     */
    q?: string;
    productId?: string;
    perPage?: number;
    page?: string;
    locationId?: string;
    legacyAccountCode?: string;

    /**
     * https://confluence.zonarsystems.net/display/VHDS/Entity+API+Query+String+Syntax
     */
    df?: string;

    /**
     * https://confluence.zonarsystems.net/display/VHDS/Entity+API+Query+String+Syntax
     */
    defaultOperator?: 'and' | 'or';
  }

  /**
   * Parameters for patchSingleCompanyApi
   */
  export interface PatchSingleCompanyApiParams {
    payload: PatchCompany;
    companyId: string;
  }
}

export { CompaniesService }
