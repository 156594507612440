/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Geo } from '../models/geo';

/**
 * Wrapper around geo-location functions.
 */
@Injectable({
  providedIn: 'root',
})
class GeolocationService extends __BaseService {
  static readonly getGeoReversePath = '/geo_reverse/';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `GeolocationService.GetGeoReverseParams` containing the following parameters:
   *
   * - `lon`:
   *
   * - `lat`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getGeoReverseResponse(params: GeolocationService.GetGeoReverseParams): __Observable<__StrictHttpResponse<Geo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.lon != null) __params = __params.set('lon', params.lon.toString());
    if (params.lat != null) __params = __params.set('lat', params.lat.toString());
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/geo_reverse/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Geo>;
      })
    );
  }
  /**
   * @param params The `GeolocationService.GetGeoReverseParams` containing the following parameters:
   *
   * - `lon`:
   *
   * - `lat`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getGeoReverse(params: GeolocationService.GetGeoReverseParams): __Observable<Geo> {
    return this.getGeoReverseResponse(params).pipe(
      __map(_r => _r.body as Geo)
    );
  }
}

module GeolocationService {

  /**
   * Parameters for getGeoReverse
   */
  export interface GetGeoReverseParams {
    lon: number;
    lat: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { GeolocationService }
