import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AddModalController, DriverModalController, EditModalController} from './driver-modal-controller';
import {Store} from '@ngrx/store';
import {DriverModuleState} from '../state/reducers/driver-logs.reducer';
import {combineLatest} from 'rxjs';
import {
    selectCurrentDate,
    selectDriverGridState,
    selectDSDuration,
    selectDSEvents,
    selectIsKilometers
} from '../state/driver-logs.selector';
import {POST_DRIVER_EDIT_EXISTING_EVENT} from '../state/actions/driver-events.actions';
import {AbstractControl, FormControl, ValidatorFn, Validators} from '@angular/forms';
import {DriverEvent} from '../model/driver-event';
import {ZonarConvertPipe} from '../pipe/zonar-convert.pipe';
import {EventIds} from '../../enums';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(timezone);

export interface EditConfig {
    event?: any;
    action: string;
    title: string;
    pendoClass?: string;
}

export interface TimeBlock {
    start_epoch: number;
    end_epoch?: number;
    event_code_name: string;
    sds?: string;
}

@Component({
    selector: 'app-driver-event-edit',
    templateUrl: './driver-event-edit.component.html',
    styleUrls: ['./driver-event-edit.component.scss']
})
export class DriverEventEditComponent implements OnInit {
    config: EditConfig;
    timeBlock: TimeBlock[] = [];
    date: string; // mm dd yyyy
    dutyStatus: any;
    insertTime: number;
    driverTz = '';

    notesFormControl: FormControl;
    locationFormControl: FormControl;
    odoFormControl: FormControl;
    timeFormControl: FormControl;

    converter: ZonarConvertPipe;
    private controller: DriverModalController;
    previewEvent: DriverEvent;
    public pendoClass: string;

    showDefaultDutyStatusSelect = true;
    showOperatingZoneSelect = false;
    showDeferralSelect = false;
    showDeferralTime = false;
    deferralTime = '';
    public distanceLabel = 'miles';

    constructor(
        private dialogRef: MatDialogRef<DriverEventEditComponent>,
        @Inject(MAT_DIALOG_DATA) public data: EditConfig,
        private driverLogsStore: Store<DriverModuleState>
    ) {
        this.config = data;
        this.converter = new ZonarConvertPipe();
        this.pendoClass = data.pendoClass;

        combineLatest([
            this.driverLogsStore.select(selectCurrentDate),
            this.driverLogsStore.select(selectDriverGridState),
            this.driverLogsStore.select(selectIsKilometers)
        ]).subscribe(([date, grid, isKms]) => {
            this.date = date;
            this.driverTz = grid.driver_time_zone;
            if (isKms) {
                this.distanceLabel = 'kilometers';
            } else {
                this.distanceLabel = 'miles';
            }
        });

        if (this.config.action === POST_DRIVER_EDIT_EXISTING_EVENT) {
            this.controller = new EditModalController(this.config.event);
        } else {
            this.config.event = {
                driver_ts: '',
                odo: 0,
                location: '',
                comments: '',
                is_manual_odo: true,
                is_manual_location: true,
                display_edit: false,
                display_comment: true,
                display_total_miles: true,
                display_accum_miles: true,
                display_location: true,
                display_icon: true,
                accum_odo: 0,
                accum_hrs: 0,
                event_code_name: 'NEW',
                event_type_name: 'DUTY_STATUS',
                event_record_origin_name: 'DRIVER'
            };
            this.controller = new AddModalController(this.config.event);
            this.setupEventTimeCheck();
        }

        this.previewEvent = {
            ...this.config.event,
            display_edit: false,
            is_preview_event: true,
            odo: this.odoConvert(this.config.event.odo, this.config.event.is_manual_odo)
        };

        this.insertTime = this.config.event.epoch;
        this.dutyStatus = this.controller.getDefaultValue(this.data.event.event_code_name);
        this.enableCorrectFormState(this.config.event);
        this.initFormControls(this.config.event);
    }

    private enableCorrectFormState(event: DriverEvent): void {
        this.showDefaultDutyStatusSelect = false;

        if (event.event_type_id === EventIds.CanadaOperatingZone) {
            this.showOperatingZoneSelect = true;
        } else if (event.event_type_id === EventIds.CanadaDeferral) {
            this.showDeferralSelect = true;
            this.showDeferralTime = true;

            // In addition to showing the deferral version of the select,
            // also show a disabled input with the deferral time if it's a day 1 deferral event.
            // If it's not a day 1 deferral event, show 'N/A' instead of a time.
            if (event.event_code_id === 1) {
                this.deferralTime = event.data.time_deferred;
            } else {
                this.deferralTime = 'N/A';
            }
        } else {
            this.showDefaultDutyStatusSelect = true;
        }
    }

    private initFormControls(event: DriverEvent) {
        this.notesFormControl = new FormControl(
            {value: event.comments, disabled: this.annotationDisabled()},
            [Validators.required, Validators.minLength(4)]
        );

        // if we're editing a deferral event, don't validate the location because it's optional for deferral events
        if (this.controller instanceof EditModalController && this.config.event.event_type_id === EventIds.CanadaDeferral) {
            this.locationFormControl = new FormControl(
                {value: event.location, disabled: this.locationDisabled()},
                [Validators.required, Validators.minLength(5)]
            );
        } else {
            this.locationFormControl = new FormControl(
                {value: event.location, disabled: this.locationDisabled()},
                [Validators.required, Validators.minLength(5)]
                );
        }

        // check label, convert depending on label
        const odo = this.odoConvert(event.odo, event.is_manual_odo);
        this.odoFormControl = new FormControl(
            {value: odo, disabled: this.milesDisabled()},
            [Validators.required, Validators.min(0)]
        );
        const time = event.driver_ts;
        this.timeFormControl = new FormControl(
            {value: dayjs(time).format('HH:mm:ss'), disabled: this.timeDisabled()},
            [timeRangeValidator(this.timeBlock, this.date, this.controller.validateTime, this.driverTz)]
        );
    }

    ngOnInit() {
    }

    close() {
        this.dialogRef.close();
    }

    private isSDS() {
        return this.previewEvent.event_type_name === 'SDS';
    }

    setupEventTimeCheck() {
        combineLatest([
            this.driverLogsStore.select(selectDSEvents),
            this.driverLogsStore.select(selectDSDuration)
        ]).subscribe(([dsEvents, duration]) => {
            this.timeBlock = [];
            // there's gonna be issues with Auto D followed by manual D or vice versa
            // can't insert at an existing event start epoch
            // can't insert between an sds event
            // can't insert between an auto d
            duration.forEach((eventDuration) => {
                if (!!eventDuration.sds) {
                    // for sds events
                    this.timeBlock.push(eventDuration);
                }
                if (eventDuration.event_code_name === 'DRIVING') {
                    // for auto d
                    const value = dsEvents.find(e => e.epoch === eventDuration.start_epoch);
                    if (value === undefined || value.event_record_origin_name === 'SYSTEM'
                        || value.event_record_origin_name === 'UNIDENTIFIED_DRIVING') {
                        this.timeBlock.push(eventDuration);
                    }
                }
            });
            dsEvents.forEach((event: DriverEvent) => {
                this.timeBlock.push({
                    start_epoch: event.epoch,
                    event_code_name: event.event_code_name
                });
            });
        });
    }

    timeDisabled(): boolean {
        return this.controller.disabled('time');
    }

    dsDisabled(): boolean {
        return this.controller.disabled('ds');
    }

    milesDisabled(): boolean {
        return this.controller.disabled('miles');
    }

    locationDisabled(): boolean {
        return this.controller.disabled('location');
    }

    annotationDisabled(): boolean {
        return this.controller.disabled('annotation');
    }

    disableEdit(): boolean {
        return !(this.validateAnnotation() && this.validateOdo() && this.validateLocation() && this.validateTime())
            || !this.inputsWereChanged();
    }

    validateAnnotation(): boolean {
        return this.controller.validate('annotation', {annotation: this.notesFormControl.value});
    }

    private validateOdo() {
        return this.controller.validate('miles', {odo: this.odoFormControl.value});
    }

    private validateLocation() {

        // if we're editing a deferral event, don't validate the location because it's optional for deferral events
        if (this.controller instanceof EditModalController && this.data.event.event_type_id === EventIds.CanadaDeferral) {
            return true;
        } else {
            return this.controller.validate('location', {location: this.locationFormControl.value});
        }
    }

    inputsWereChanged(): boolean {
        const checkValues = {
            comments: this.previewEvent.comments,
            event_code_name: this.dutyStatus,
            epoch: this.insertTime,
            location: this.previewEvent.location,
            odo: this.previewEvent.odo
        };
        return this.controller.modelChanged(checkValues);
    }

    submitEvent() {
        if (this.distanceLabel === 'kilometers') {
            // convert to mi
            this.previewEvent.odo = this.converter.transform(this.previewEvent.odo, 'miles', 'kilometers') as number;
        }
        const possibleParams = {
            event_code_name: this.dutyStatus,
            epoch: this.insertTime,
            location: this.previewEvent.location,
            odo: this.previewEvent.odo,
            comments: this.previewEvent.comments,
            duty_status: this.dutyStatus,
        };
        const params = this.controller.buildParams(possibleParams);
        this.driverLogsStore.dispatch({type: this.config.action, params: params});
        this.close();
    }

    convertInputToEpoch(): void {
        const inputTime = this.timeFormControl.value;
        const insertTime = this.date + 'T' + inputTime;
        this.insertTime = dayjs.tz(insertTime, this.driverTz).unix();
    }

    validateTime(): boolean {
        return this.controller.validateTime(this.timeBlock, this.timeFormControl.value, this.date, this.driverTz);
    }

    updateValues() {
        this.previewEvent = {
            ...this.previewEvent,
            comments: this.notesFormControl.value,
            odo: this.odoFormControl.value,
            location: this.locationFormControl.value,
            event_code_name: this.isSDS() ? this.previewEvent.event_code_name : this.dutyStatus,
            driver_ts: this.insertTime.toString()
        };
    }

    odoConvert(odo, isManual) {
        if (isManual) {
            // manual odo are always mi, display as mi/km
            odo = this.converter.transform(odo, this.distanceLabel, 'miles');
        } else {
            // auto odo to miles for input display
            odo = this.converter.transform(odo, this.distanceLabel);
        }
        return odo;
    }
}

function timeRangeValidator(timeBlock: TimeBlock[], date: any, validate: Function, tz: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        if (control.value && control.value.includes('Invalid')) {
            return {'required': true};
        }
        if (!validate(timeBlock, control.value, date, tz)) {
            return {
                'timeRange': true
            };
        }
        return null;
    };
}
