import { Component, Input, OnInit } from '@angular/core';
import { SkeletonAnimation, SkeletonVariant } from './skeleton.types';

@Component({
  selector: 'app-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss']
})
export class SkeletonComponent implements OnInit {

  /**
   * The animation. If `undefined` the animation
   * will be disabled
   *
   * @example <app-skeleton animation="pulse"></app-skeleton>
  */
  @Input() animation?: SkeletonAnimation;

  /**
   * The type of content that will be rendered.
   *
   * @default rectangle
   * @example <app-skeleton variant="circle"></app-skeleton>
  */
  @Input() variant?: SkeletonVariant = 'rectangle';

  /**
   * Width of skeleton.
   *
   * @example <app-skeleton width="100"></app-skeleton>
  */
  @Input() width?: number;

  /**
   * Height of skeleton.
   *
   * @example <app-skeleton height="100"></app-skeleton>
  */
  @Input() height?: number;

  constructor() { }

  ngOnInit(): void {
  }

}
