<div class="certify-controls">
    <div class="certify-top-row">
        <span class="certify-header">
            Certifying daily logs ({{currentIndex + 1}} of {{totalOfUncertifiedDays}})
        </span>
        <button class="outlined-button button-style exit-cert-button" color="accent" mat-stroked-button (click)="exitCertifyLogs()">
            <i class="material-icons back-arrow">arrow_back</i> Exit Certify</button>
    </div>
    <div class="certify-bottom-row">
        <p class="certify-disclaimer">
            I hereby certify that my data entries and my record of duty status for this 24-hour period are true and correct.
        </p>
        <div class="certify-actions">
            <button class="outlined-button button-style do-later"
                    color="accent" mat-stroked-button
                    (click)="doLateAction()"
            >Do Later</button>
            <button class="solid-button button-style accept-certify"
                    color="accent" mat-flat-button
                    (click)="acceptAction()">Agree</button>
        </div>
    </div>
</div>
