<div class="driver-event-card" role="listitem">
    <div class="driver-event-container driver-icon-container">
        <div *ngIf="driverEvent.display_icon">
            <div class="duty-status-circle">{{this.dutyStatusAbbr(driverEvent.event_code_name)}}</div>
        </div>
        <div class="timestamp">{{driverEvent.driver_ts | zonarTimestamp: 'time'}}</div>
    </div>
    <div class="driver-event-container ds-info">
        <div class="driver-event-title font-heavy">
            {{this.getHeader()}}
        </div>
        <div *ngIf="showLocation()" class="driver-event-location">
          <div class="info-display-wrapper">
              <div>
                  <span>Location: </span>
                  <span class="font-heavy">{{locationText(driverEvent.location)}}</span>
              </div>
          </div>
        </div>
        <div *ngIf="showEventCard()" class="driver-event-info">
          <div class="info-display-wrapper">
            <div class="right-info-box">
              <span>Accumulated Vehicle {{distanceLabel}}: </span>
              <span class="font-heavy">{{driverEvent.accum_odo | zonarConvert: distanceLabel}}</span>
            </div>
            <div>
              <span> Elapsed Engine Hours: </span>
              <span class="font-heavy">{{driverEvent.accum_hrs | zonarConvert: 'hrs'}}</span>
            </div>
          </div>
        </div>
        <div class="driver-event-info">
          <div class="info-display-wrapper">
            <div *ngIf="showEventCard() || driverEvent.display_total_miles" class="right-info-box">
                <span>Total Vehicle {{distanceLabel}}: </span>
                <span *ngIf="driverEvent.is_preview_event; else default" class="preview font-heavy">{{driverEvent.odo}}</span>
                <ng-template #default>
                    <span *ngIf="driverEvent.is_manual_odo; else convert" class="font-heavy">{{manualOdoFunction(driverEvent.odo)}}</span>
                    <ng-template #convert>
                        <span class="font-heavy">{{driverEvent.odo | zonarConvert: distanceLabel: 'Meters' }}</span>
                    </ng-template>
                </ng-template>
            </div>
            <div *ngIf="driverEvent.display_total_miles && driverEvent.event_type_name !== 'DEFERRAL_STATE'">
              <span> Total Engine Hours: </span><span class="font-heavy">{{driverEvent.engine_hrs | zonarConvert: 'hrs'}}</span>
            </div>
          </div>
        </div>
        <div *ngIf="showCmvNumber">CMV Number: {{driverEvent.data.cmv_order_number}}</div>
        <div *ngIf="showNotes" class="driver-event-comments">Notes: {{this.getComments()}}</div>
    </div>

    <div *ngIf="driverEvent.display_edit && !isInCertifyMode" class="driver-event-container edit-icon" (click)="openEditModal()">
        <zui-icon icon="create"></zui-icon>
    </div>
</div>
