<div class="export-modal mat-app-background zonar-default-theme">
    <div class="export-modal-title">
        <span class="export-title">Export logs</span>
        <zui-icon class="exit-icon" icon="close" (click)="close()"></zui-icon>
    </div>
    <div class="info-paragraph">Select up to two weeks of your logs to export. A PDF of the logs will be delivered to the email address you specify below.</div>

    <form [formGroup]="exportFormGroup">
        <div *ngIf="this.data.canadaSysvarEnabled">
            <div class="export-type">
                <span class="filter-button-label">Export type</span>
                  <span class="export-checkbox-group" *ngFor="let opt of exportTypes">
                    <mat-checkbox color="accent" class="export-checkbox-button" [value]="opt.value" (change)="onCheckboxChange($event)">{{opt.name}}</mat-checkbox>
                  </span>
            </div>
        </div>

        <div class="export-email">
            <mat-form-field appearance="outline" color="accent">
                <mat-label>Email address</mat-label>
                <input matInput formControlName="email" autocomplete="email" required>
                <mat-error *ngIf="this.exportFormGroup.get('email').invalid">{{getErrorMessage('email')}}</mat-error>
            </mat-form-field>
        </div>

        <div class="export-email-confirm">
            <mat-form-field appearance="outline" color="accent">
                <mat-label>Confirm email address</mat-label>
                <input matInput formControlName="emailConfirm" autocomplete="email" required>
                <mat-error *ngIf="this.exportFormGroup.get('emailConfirm').invalid">{{getErrorMessage('emailConfirm')}}</mat-error>
            </mat-form-field>
        </div>
    </form>

    <div class="export-dates">
        <mat-form-field appearance="outline" class="start-date">
            <mat-label class="export-input">Start date</mat-label>
            <input matInput [matDatepicker]="startDate"
                   (dateInput)="getDate('start', $event)"
                   [min]="exportStartMinDate"
                   [max]="exportStartMaxDate"
                   autocomplete="date">
            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline" class="end-date">
            <mat-label>End date</mat-label>
            <input matInput [matDatepicker]="endDate"
                   (dateInput)="getDate('end', $event)"
                   [min]="exportEndMinDate"
                   [max]="exportEndMaxDate"
                   autocomplete="date">
            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
            <mat-datepicker #endDate ></mat-datepicker>
        </mat-form-field>
        <mat-error></mat-error>
    </div>
    <div class="export-comment">
        <mat-form-field appearance="outline" color="accent">
            <mat-label>Comments (optional)</mat-label>
            <textarea matInput [formControl]="comment" maxlength="60"></textarea>
        </mat-form-field>
    </div>
    <div class="export-button-section">
        <button mat-stroked-button color="accent" class="export-logs-button" (click)="close()">Cancel</button>
        <button mat-button color="accent" [disabled]="disableExport()" class="export-submit-button export-logs-button mat-raised-button" (click)="export()">Export</button>
    </div>
</div>

