import { GetSysvar, SysvarEndpointParams, SysvarEndpointPayload } from '../state/app.actions';
import {Component, Input, OnInit} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {ExportLogContentComponent} from './export-log-content.component';
import { Store } from '@ngrx/store';
import { SysvarState } from '../state/app.reducer';
import { selectSysvars } from '../state/app.selectors';
import {UserInfoService} from '../user-info.service';


@Component({
    selector: 'app-export-button',
    template: `
    <div class='button-container export-button-container'>
        <button class="export-button outlined-button button-style full-width-button" color="accent" mat-stroked-button (click)="openDialog()"
            [disabled]="isDisabled">
            Export logs
        </button>
    </div>`,
    styleUrls: ['./export-log.scss']
})

export class ExportButtonComponent implements OnInit {
    @Input() userName: string;
    @Input() driverUUID: string;


    @Input() isDisabled = false;
    private canadaSysvarEnabled = false;

    constructor(
        private dialog: MatDialog,
        private sysvarStore: Store<SysvarState>
    ) {}

    ngOnInit() {
        const payload: SysvarEndpointPayload = {
            sysvar_name: 'ELD_CANADA',
            convert: 'bool'
        };
        const params: SysvarEndpointParams = {
            payload: payload,
            driverProfileUuid: this.driverUUID
        };
        this.sysvarStore.dispatch(new GetSysvar(params, 'ELD_CANADA'));
        this.sysvarStore.select(selectSysvars).subscribe(sysvars => {
            if (sysvars && sysvars['ELD_CANADA']) {
                this.canadaSysvarEnabled = true;
            }
        });
      UserInfoService.driverWithNoDS.subscribe(val => {
          this.isDisabled = val;
        })
    }

    openDialog() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            userName: this.userName,
            driverUUID: this.driverUUID,
            canadaSysvarEnabled: this.canadaSysvarEnabled
        };
        this.dialog.open(ExportLogContentComponent, dialogConfig);
    }
}

