import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CertifyButtonComponent } from './certify-button/certify-button.component';
import { MatButtonModule } from '@angular/material/button';
import { CertifyLogsControlComponent } from './certify-logs-control/certify-logs-control.component';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {CertifyLogsEffects} from './state/certify-logs.effects';
import {CertifyLogsReducer} from './state/certify-logs.reducer';
import { CertifyLogsWarningComponent } from './certify-logs-warning/certify-logs-warning.component';
import {ZonarUiIconModule} from '@zonar-ui/icon';



@NgModule({
    declarations: [CertifyButtonComponent, CertifyLogsControlComponent, CertifyLogsWarningComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        StoreModule.forFeature('certify-logs', CertifyLogsReducer),
        EffectsModule.forFeature([CertifyLogsEffects]),
        ZonarUiIconModule,
    ],
    exports: [CertifyButtonComponent, CertifyLogsControlComponent, CertifyLogsWarningComponent],
    entryComponents: [CertifyButtonComponent]
})
export class CertifyLogsModule { }
