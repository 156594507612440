import {Component, Input, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {DriverModuleState} from '../state/reducers/driver-logs.reducer';

// will likely move this to a lang service
import lang from '../local/violations.json';
import {selectCertifyStatus, selectCurrentDate, selectDriverGridState} from '../state/driver-logs.selector';
import {selectUncertifiedDays} from '../../certify-logs/state/certify-logs.selectors';
import {CertifyLogsState} from '../../certify-logs/state/certify-logs.reducer';
import {combineLatest} from 'rxjs';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ViolationNotificationComponent} from './violation-notification/violation-notification.component';
import {UserInfoService} from '../../user-info.service';

@Component({
    selector: 'app-driver-grid',
    templateUrl: './driver-grid.component.html',
    styleUrls: ['./driver-grid.component.scss']
})
export class DriverGridComponent implements OnInit {
    public isLegendVisible = false;
    @Input() isInCertifyMode: Boolean;
    public certificationStatus: Boolean;
    isDisabled = false;

    public gridEvents: any[];
    public dutyStatusTotals: any[];
    public driverTimeZone: string;
    public day;
    private postParams: any;
    private workshiftOffset = 0;

    constructor(private driverLogsStore: Store<DriverModuleState>,
                private certifyLogsStore: Store<CertifyLogsState>,
                private dialog: MatDialog) {
    }

    ngOnInit() {
        // TODO when the requirement is made in which we support varying start times for workshifts,
        // the this.workshiftOffset value.set
        combineLatest([
          this.driverLogsStore.select(selectDriverGridState),
          this.certifyLogsStore.select(selectUncertifiedDays),
          this.driverLogsStore.select(selectCurrentDate),
          this.driverLogsStore.select(selectCertifyStatus)
        ])
            .subscribe(([state, days, date, certificationState]) => {
                this.gridEvents = state.grid_events.map((x) => {
                  const sds = x.sds ? { ...x.sds }: null;
                  if(sds) {
                    sds.eventCodeName = sds.event_code_name;
                    sds.eventTypeName = sds.event_type_name;
                  }
                  return {
                    endEpoch: x.end_epoch,
                    startEpoch: x.start_epoch,
                    eventCodeName: x.event_code_name,
                    eventTypeName: x.event_type_name,
                    sds: sds,
                    isUDL: false,
                    isCurrentStatus: false,
                    violations: x.violations ? x.violations.map((y) => ({
                      code: y.code,
                      endEpoch: y.end_epoch,
                      originEpoch: y.origin_epoch,
                      startEpoch: y.start_epoch,
                      rulesetId: y.ruleset_id,
                      triggeredEpoch: y.triggered_epoch
                    })) : null
                  }
                });
                this.dutyStatusTotals = state.duty_status_totals.map((x) => ({seconds: x.seconds, eventCode: x.event_code}));
                this.driverTimeZone = state.driver_time_zone;
                this.postParams = state.params;
                this.day = date;
                this.certificationStatus = certificationState || this.isCertified(this.postParams.day, days);
            });
      UserInfoService.driverWithNoDS.subscribe(val => {
          this.isDisabled = val;
        })
    }

    private isCertified(currentDay: string, uncertifiedDays: string[]) {
        const index = uncertifiedDays.findIndex((day) => {
            return day === currentDay;
        });
        return index === -1;

    }

  clickGrid() {
      if (this.collectViolations() > 0) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
          dutyStatusEvents: this.gridEvents,
          driverTimezone: this.driverTimeZone
        };
        this.dialog.open(ViolationNotificationComponent, dialogConfig);
      }
  }

  private collectViolations() {
    let violations = [];
    if (this.gridEvents) {
      this.gridEvents.forEach((ds) => {
        if (ds.violations) {
          violations = violations.concat(ds.violations);
        }
      });
    }
    return violations.length;
  }
}
