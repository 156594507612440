import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DriverEventListComponent } from './driver-event-list/driver-event-list.component';
import { DriverGridComponent } from './driver-grid/driver-grid.component';
import { DriverLogsService } from './state/driver-logs.service';
import { BrowserModule } from '@angular/platform-browser';
import { StoreModule } from '@ngrx/store';
import { reducers } from './state/reducers/driver-logs.reducer';
import { EffectsModule } from '@ngrx/effects';
import { GetDriverLogsEffects } from './state/effects/driver-logs.effects';
import { DriverClockListComponent } from './driver-clocks/driver-clock-list.component';
import { MatIconModule } from '@angular/material/icon';
import { DriverEventCardComponent } from './driver-event-card/driver-event-card.component';
import { DriverEventGenericCardComponent } from './driver-event-card/driver-event-generic-card.component';
import { ZonarTimestampPipe } from './pipe/zonar-timestamp.pipe';
import { DateControlComponent } from '../shared/date-control/date-control.component';
import { ZonarConvertPipe } from './pipe/zonar-convert.pipe';
import { DriverEventEditComponent } from './driver-event-edit/driver-event-edit.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { AddDriverEventButtonComponent } from './add-driver-event-button/add-driver-event-button.component';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ZonarUiIconModule } from '@zonar-ui/icon';
import { DriverCycleChangeEditComponent } from './driver-event-edit/driver-cycle-change-edit/driver-cycle-change-edit.component';
import { DistanceToggleComponent } from './driver-grid/distance-toggle/distance-toggle.component';
import {ZonarUIMaterialModule} from '@zonar-ui/material';
import { ViolationNotificationComponent } from './driver-grid/violation-notification/violation-notification.component';
import {ZonarUiHosComponentsModule} from '@zonar-ui/hos-components';
import { ZonarUiProgressSpinnerModule} from '@zonar-ui/progress-spinner';
import { SkeletonComponent } from '../shared/skeleton/skeleton.component';
import { DriverEventCardSkeletonComponent } from './driver-event-card/skeleton/driver-event-card-skeleton/driver-event-card-skeleton.component';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        StoreModule.forFeature('driver-logs', reducers),
        EffectsModule.forFeature([GetDriverLogsEffects]),
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule,
        FormsModule,
        ReactiveFormsModule,
        ZonarUiIconModule,
        ZonarUIMaterialModule,
        ZonarUiProgressSpinnerModule,
        ZonarUiHosComponentsModule
    ],
    declarations: [
        DriverEventListComponent,
        DriverGridComponent,
        DriverClockListComponent,
        DriverEventCardComponent,
        DriverEventGenericCardComponent,
        DateControlComponent,
        ZonarTimestampPipe,
        ZonarConvertPipe,
        DriverEventEditComponent,
        AddDriverEventButtonComponent,
        DriverCycleChangeEditComponent,
        DistanceToggleComponent,
        ViolationNotificationComponent,
        SkeletonComponent,
        DriverEventCardSkeletonComponent
    ],
    exports: [
        DriverGridComponent,
        DriverEventListComponent,
        DriverClockListComponent,
    ],
    providers: [
        DatePipe,
        DriverLogsService,
        DateControlComponent,
        ZonarTimestampPipe
    ],
    entryComponents: [DriverEventEditComponent]
})
export class DriverLogsModule { }
