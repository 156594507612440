import {Component, Input, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {DriverModuleState} from '../../state/reducers/driver-logs.reducer';
import {SetKilometers} from '../../state/actions/driver-logs.actions';
import {SysvarState} from '../../../state/app.reducer';
import {selectSysvars} from '../../../state/app.selectors';
import {selectIsKilometers} from '../../state/driver-logs.selector';

@Component({
  selector: 'app-distance-toggle',
  templateUrl: './distance-toggle.component.html',
  styleUrls: ['./distance-toggle.component.scss']
})
export class DistanceToggleComponent implements OnInit {
  @Input() isDisabled = false;
  public checked = false;
  public canadaSysvarEnabled = false;

  constructor(private driverLogsStore: Store<DriverModuleState>,
              private sysvarStore: Store<SysvarState>) {
  }

  ngOnInit(): void {
    this.driverLogsStore.select(selectIsKilometers).subscribe((isKms) => {
      this.checked = isKms;
    });
    this.sysvarStore.select(selectSysvars).subscribe(sysvars => {
      if (sysvars && sysvars['ELD_CANADA']) {
        this.canadaSysvarEnabled = true;
      }
    });
  }

  setIsKilometerState() {
    this.driverLogsStore.dispatch(new SetKilometers(this.checked));
  }

}
