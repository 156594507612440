import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ExportButtonComponent} from './export-log-button.component';
import {ExportLogContentComponent} from './export-log-content.component';
import {ReactiveFormsModule} from '@angular/forms';
import {DatePipe} from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {ZonarUiIconModule} from '@zonar-ui/icon';

@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatCheckboxModule,
        ZonarUiIconModule
    ],
    declarations: [ExportButtonComponent, ExportLogContentComponent],
    exports: [ExportButtonComponent, ExportLogContentComponent],
    entryComponents: [ExportLogContentComponent],
    providers: [DatePipe]
})
export class ExportLogsModule { }
