/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UserResults } from '../models/user-results';
import { User } from '../models/user';
import { PostUser } from '../models/post-user';
import { UserProfile } from '../models/user-profile';
import { PostUserProfile } from '../models/post-user-profile';
@Injectable({
  providedIn: 'root',
})
class ApiService extends __BaseService {
  static readonly findUserPath = '/users';
  static readonly addUserPath = '/users';
  static readonly getUserByIdPath = '/users/{userId}';
  static readonly updateUserPath = '/users/{userId}';
  static readonly getUserProfilesPath = '/userprofiles';
  static readonly addUserProfilePath = '/userprofiles';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Search for user
   *
   * Search for user
   * @param params The `ApiService.FindUserParams` containing the following parameters:
   *
   * - `lastName`: The last name to search for (Can be combined with firstName)
   *
   * - `identityProviderId`: The identity provider id to search for (Can only be used by itself)
   *
   * - `firstName`: The first name to search for (Can be combined with lastName)
   *
   * - `exactMatch`: Flag instructing User API to return entities with specified identityProviderId only
   *
   * - `email`: The email address to search for (Can only be used by itself)
   *
   * @return search results matching criteria
   */
  findUserResponse(params: ApiService.FindUserParams): __Observable<__StrictHttpResponse<UserResults>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.lastName != null) __params = __params.set('lastName', params.lastName.toString());
    if (params.identityProviderId != null) __params = __params.set('identityProviderId', params.identityProviderId.toString());
    if (params.firstName != null) __params = __params.set('firstName', params.firstName.toString());
    if (params.exactMatch != null) __params = __params.set('exactMatch', params.exactMatch.toString());
    if (params.email != null) __params = __params.set('email', params.email.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserResults>;
      })
    );
  }
  /**
   * Search for user
   *
   * Search for user
   * @param params The `ApiService.FindUserParams` containing the following parameters:
   *
   * - `lastName`: The last name to search for (Can be combined with firstName)
   *
   * - `identityProviderId`: The identity provider id to search for (Can only be used by itself)
   *
   * - `firstName`: The first name to search for (Can be combined with lastName)
   *
   * - `exactMatch`: Flag instructing User API to return entities with specified identityProviderId only
   *
   * - `email`: The email address to search for (Can only be used by itself)
   *
   * @return search results matching criteria
   */
  findUser(params: ApiService.FindUserParams): __Observable<UserResults> {
    return this.findUserResponse(params).pipe(
      __map(_r => _r.body as UserResults)
    );
  }

  /**
   * Add a user
   *
   * Adds a user and creates corresponding entry in the Identity Provider
   * @param user undefined
   * @return user created
   */
  addUserResponse(user?: PostUser): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = user;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * Add a user
   *
   * Adds a user and creates corresponding entry in the Identity Provider
   * @param user undefined
   * @return user created
   */
  addUser(user?: PostUser): __Observable<User> {
    return this.addUserResponse(user).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * Get a user
   *
   * Get a user
   * @param userId the user identifier
   * @return the user being returned
   */
  getUserByIdResponse(userId: string): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/users/${encodeURIComponent(String(userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * Get a user
   *
   * Get a user
   * @param userId the user identifier
   * @return the user being returned
   */
  getUserById(userId: string): __Observable<User> {
    return this.getUserByIdResponse(userId).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * Update user
   *
   * Updates a user
   * @param params The `ApiService.UpdateUserParams` containing the following parameters:
   *
   * - `userId`: the user identifier
   *
   * - `user`: Any user fields to update
   */
  updateUserResponse(params: ApiService.UpdateUserParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.user;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/users/${encodeURIComponent(String(params.userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update user
   *
   * Updates a user
   * @param params The `ApiService.UpdateUserParams` containing the following parameters:
   *
   * - `userId`: the user identifier
   *
   * - `user`: Any user fields to update
   */
  updateUser(params: ApiService.UpdateUserParams): __Observable<null> {
    return this.updateUserResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Search for UserProfiles
   *
   * Search for UserProfiles
   * @param params The `ApiService.GetUserProfilesParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `embed`: Using "embed=roles" will include the full Role schema instead of just the IDs.
   *
   * - `divisionId`:
   *
   * - `companyId`:
   *
   * @return Matching user profiles
   */
  getUserProfilesResponse(params: ApiService.GetUserProfilesParams): __Observable<__StrictHttpResponse<Array<UserProfile>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    if (params.embed != null) __params = __params.set('embed', params.embed.toString());
    if (params.divisionId != null) __params = __params.set('divisionId', params.divisionId.toString());
    if (params.companyId != null) __params = __params.set('companyId', params.companyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/userprofiles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserProfile>>;
      })
    );
  }
  /**
   * Search for UserProfiles
   *
   * Search for UserProfiles
   * @param params The `ApiService.GetUserProfilesParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `embed`: Using "embed=roles" will include the full Role schema instead of just the IDs.
   *
   * - `divisionId`:
   *
   * - `companyId`:
   *
   * @return Matching user profiles
   */
  getUserProfiles(params: ApiService.GetUserProfilesParams): __Observable<Array<UserProfile>> {
    return this.getUserProfilesResponse(params).pipe(
      __map(_r => _r.body as Array<UserProfile>)
    );
  }

  /**
   * Create a new UserProfile
   *
   * Create a new UserProfile
   * @param profile undefined
   * @return user created
   */
  addUserProfileResponse(profile?: PostUserProfile): __Observable<__StrictHttpResponse<UserProfile>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = profile;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/userprofiles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserProfile>;
      })
    );
  }
  /**
   * Create a new UserProfile
   *
   * Create a new UserProfile
   * @param profile undefined
   * @return user created
   */
  addUserProfile(profile?: PostUserProfile): __Observable<UserProfile> {
    return this.addUserProfileResponse(profile).pipe(
      __map(_r => _r.body as UserProfile)
    );
  }
}

module ApiService {

  /**
   * Parameters for findUser
   */
  export interface FindUserParams {

    /**
     * The last name to search for (Can be combined with firstName)
     */
    lastName?: string;

    /**
     * The identity provider id to search for (Can only be used by itself)
     */
    identityProviderId?: string;

    /**
     * The first name to search for (Can be combined with lastName)
     */
    firstName?: string;

    /**
     * Flag instructing User API to return entities with specified identityProviderId only
     */
    exactMatch?: string;

    /**
     * The email address to search for (Can only be used by itself)
     */
    email?: string;
  }

  /**
   * Parameters for updateUser
   */
  export interface UpdateUserParams {

    /**
     * the user identifier
     */
    userId: string;

    /**
     * Any user fields to update
     */
    user: PostUser;
  }

  /**
   * Parameters for getUserProfiles
   */
  export interface GetUserProfilesParams {
    userId?: string;

    /**
     * Using "embed=roles" will include the full Role schema instead of just the IDs.
     */
    embed?: string;
    divisionId?: string;
    companyId?: string;
  }
}

export { ApiService }
