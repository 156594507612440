export const environment = {
  name: 'dev',
  auth: {
    clientID: 'Wg1W0gk5rg78hjbF9S8VrTvlRsB6Hgmk',
    domain: 'zonar-dev.auth0.com',
    audience: 'http://apidev.zonarsystems.net/',
  },
  datadog: {
    applicationId: '',
    clientToken: 'pub8a652e107235c257e7f966cc72f1784e',
    site: 'us5.datadoghq.com',
    service: 'driver_dashboard',
  },
  production: false
};
