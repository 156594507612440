import {Action} from '@ngrx/store';
import {DriverEventsState, FallbackInfo} from '../reducers/driver-events.reducer';
import {DriverEvent} from '../../model/driver-event';

export const GET_DRIVER_EVENTS = '[Get] CALL_DRIVER_EVENTS_API';
export const GET_DRIVER_EVENTS_SUCCESS = '[Get] GET_DRIVER_EVENTS_SUCCESS';

export const POST_DRIVER_EDIT_EXISTING_EVENT = '[Post] CALL_EDIT_EXISTING_EVENT_API';
export const POST_DRIVER_EDIT_EXISTING_EVENT_SUCCESS = '[Post] CALL_EDIT_EXISTING_EVENT_SUCCESS';
export const POST_DRIVER_EDIT_EXISTING_EVENT_FAILURE = '[Post] CALL_EDIT_EXISTING_EVENT_FAILURE';

export const POST_DRIVER_ADD_MANUAL_EVENT = '[Post] CALL_ADD_MANUAL_EVENT_API';
export const POST_DRIVER_ADD_MANUAL_EVENT_SUCCESS = '[Post] CALL_ADD_MANUAL_EVENT_SUCCESS';
export const POST_DRIVER_ADD_MANUAL_EVENT_FAILURE = '[Post] CALL_ADD_MANUAL_EVENT_FAILURE';

export const GET_FALLBACK_EVENT = '[Get] GET_FALLBACK_EVENT';

export interface EditEventParams {
    driverProfileUuid: string;
    oid: string;
    currentEventCodeName: string;
    payload: {
        location?: string;
        comments: string;
        odo?: number;
        new_event_code_name?: string;
    };
}

export interface AddEventParams {
    driverProfileUuid: string;
    payload: {
        location: string;
        duty_status: 'OFF_DUTY' | 'SB' | 'ON_DUTY' | 'DRIVING';
        created: number;
        comments: string;
        odo: number;
    };
}

export class GetDriverEvents implements Action {
    readonly type = GET_DRIVER_EVENTS;
    constructor() {}
}

export class GetDriverEventsSuccess implements Action {
    readonly type = GET_DRIVER_EVENTS_SUCCESS;
    constructor(public payload: DriverEventsState) {}
}

export class PostDriverEditExistingEvent implements Action {
    readonly type = POST_DRIVER_EDIT_EXISTING_EVENT;
    constructor(public params: EditEventParams) {}
}

export class PostDriverEditExistingEventSuccess implements Action {
    readonly type = POST_DRIVER_EDIT_EXISTING_EVENT_SUCCESS;
    constructor() {}
}

export class PostDriverAddManualEvent implements Action {
    readonly type = POST_DRIVER_ADD_MANUAL_EVENT;
    constructor(public params: AddEventParams) {}
}

export class PostDriverAddManualEventSuccess implements Action {
    readonly type = POST_DRIVER_ADD_MANUAL_EVENT_SUCCESS;
    constructor() {}
}

export class GetFallbackEvent implements Action {
    readonly type = GET_FALLBACK_EVENT;
    constructor(public payload: FallbackInfo) {}
}

export type Actions =
    | GetDriverEvents
    | GetDriverEventsSuccess
    | PostDriverEditExistingEvent
    | PostDriverEditExistingEventSuccess
    | PostDriverAddManualEvent
    | PostDriverAddManualEventSuccess
    | GetFallbackEvent;
