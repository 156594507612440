import {createFeatureSelector, createSelector} from '@ngrx/store';
import { SysvarState} from './app.reducer';

export const selectSysvarState = createFeatureSelector<SysvarState>('sysvars');

export const getSysvars = (state: SysvarState) => {
    if (state) { return state.sysvars; }
};
export const getDriverProfileUuid = (state: SysvarState) => state.userId;
export const getAuthStatus = (state: SysvarState) => state.authStatus;

export const getUserName = (state: SysvarState) => state.userName;

export const getDriverCompany = (state: SysvarState) => state.company;

export const getCurrentDivision = (state: SysvarState) => state.currentDivision;

export const selectSysvars = createSelector(
    selectSysvarState, getSysvars
);

export const selectDriverProfileUuid = createSelector(
  selectSysvarState, getDriverProfileUuid
);

export const selectDriverAuthStatus = createSelector(
  selectSysvarState, getAuthStatus
);

export const selectDriverName = createSelector(
  selectSysvarState, getUserName
);

export const selectDriverCompany = createSelector(
  selectSysvarState, getDriverCompany
);

export const selectDriverDivision = createSelector(
  selectSysvarState, getCurrentDivision
);



//export const isDriverEventsLoading = (state: DriverModuleState) => state?.driverEvents.isLoading;
