<div class="driver-event-card driverEventCardSkeleton">
    <div class="driver-event-container driver-icon-container">
        <app-skeleton variant="circle" width="48" height="48" animation="wave"></app-skeleton>
        <app-skeleton animation="wave" height="10"></app-skeleton>
    </div>
    <div class="driver-event-container ds-info">
        <app-skeleton animation="wave" width="600" height="10"></app-skeleton>
        <app-skeleton animation="wave" width="600" height="10"></app-skeleton>
        <app-skeleton animation="wave" width="600" height="10"></app-skeleton>
        <app-skeleton animation="wave" width="600" height="10"></app-skeleton>
        <app-skeleton animation="wave" width="600" height="10"></app-skeleton>
    </div>
    <div class="driver-event-container"></div>
</div> 