import {Component, Inject, OnInit} from '@angular/core';
import lang from '../../local/violations.json';
import rulesets from '../../local/rulesets.json';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DutyStatusViolation} from '../../model/duty-status-violation';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(timezone);

interface DSViolationFormat {
  dutyStatusName: string;
  startTime: string;
  violations: any[];
}

@Component({
  selector: 'app-violation-notification',
  templateUrl: './violation-notification.component.html',
  styleUrls: ['./violation-notification.component.scss']
})
export class ViolationNotificationComponent {

  dutyStatuses: DSViolationFormat[] = [];
  driverTimezone: string;
  readableDutyStatus = {
    DRIVING: 'Drive',
    ON_DUTY: 'On Duty',
    OFF_DUTY: 'Off Duty',
    SB: 'Sleeper Berth'
  };
  violationsLang;

  constructor(
    private dialogRef: MatDialogRef<ViolationNotificationComponent>,
  @Inject(MAT_DIALOG_DATA) public data: { driverTimezone: string, dutyStatusEvents: any[] }) {
    this.driverTimezone = data.driverTimezone;
    this.dutyStatuses = this.formatDutyStatuses(data.dutyStatusEvents);
    this.violationsLang = this.setViolationsLang(this.dutyStatuses);
  }

  getViolationLang = (ruleset: number) => {
    if (rulesets.SOUTH_CANADA.includes(ruleset)) {
      return lang.violations.canada_s60;
    } else if (rulesets.NORTH_CANADA.includes(ruleset)) {
      return lang.violations.canada_n60;
    } else {
      return lang.violations.defaults;
    }
  }

  formatDutyStatuses(driverDutyStatus: any[]) {
    const formattedDutyStatuses = [];
    if (driverDutyStatus.length > 0) {
      driverDutyStatus.forEach((dutyStatus) => {
        if (dutyStatus.violations && dutyStatus.violations.length > 0) {
          formattedDutyStatuses.push({
            dutyStatusName: dutyStatus.eventCodeName,
            startTime: dutyStatus.startEpoch,
            violations: dutyStatus.violations
          });
        }
      });
    }
    return formattedDutyStatuses;
  }

  setViolationsLang(driverDutyStatus: DSViolationFormat[]) {
    if (driverDutyStatus.length > 0 && driverDutyStatus[0].violations) {
      const violation = driverDutyStatus[0].violations[0];
      return this.getViolationLang(violation.rulesetId);
    }
  }

  durationText(v: any) {
    return v.endEpoch ? dayjs.unix(v.endEpoch - v.startEpoch).tz(this.driverTimezone).format('hh:mm') : 'In Progress';
  }

  close() {
    this.dialogRef.close();
  }

}
