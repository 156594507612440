import * as fromDriverEvents from './driver-events.reducer';
import * as fromDriverClocks from './driver-clocks.reducer';
import * as fromDriverGridDataReducer from './driver-grid-data.reducer';
import {Actions, SET_DATE, SET_DRIVER_UUID, SET_KILOMETERS} from '../actions/driver-logs.actions';

export interface DriverModuleState {
    driverEvents: fromDriverEvents.DriverEventsState;
    driverClocks: fromDriverClocks.DriverClocksState;
    driverGridData: fromDriverGridDataReducer.DriverGridDataState;
    pageInfo: DriverDataState;
}

export const reducers = {
    driverEvents: fromDriverEvents.driverEventsReducer,
    driverClocks: fromDriverClocks.driverClocksReducer,
    driverGridData: fromDriverGridDataReducer.driverGridDataReducer,
    pageInfo: setReducer
};

export interface DriverDataState {
    driverUuid: string;
    currentDate: string;
    isKilometers: boolean;
}

export const initialState: DriverDataState = {
    driverUuid: null,
    currentDate: null,
    isKilometers: false,
};

export function setReducer(state = initialState, action: Actions ): DriverDataState {
    switch (action.type) {
        case SET_DATE:
            return {
                ...state,
                currentDate: action.date
            };
        case SET_DRIVER_UUID:
            return {
                ...state,
                driverUuid: action.driverId
            };
        case SET_KILOMETERS:
            return {
                ...state,
                isKilometers: action.isKilometers
            };
        default:
            return state;
    }
}
