import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import {AuthService} from '@auth0/auth0-angular';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-tos',
  templateUrl: './tos.component.html',
  styleUrls: ['./tos.component.css']
})
export class TosComponent implements OnInit {
  state: string;
  constructor(private route: ActivatedRoute, public auth: AuthService) { }

  ngOnInit() {
    this.route.queryParams.subscribe((params: Params) => {
        this.state = params.state;
      });
  }
  redirect() {
    window.location.href = 'https://' + environment.auth.domain + '/continue?state=' + this.state;
  }
}
