import {Component, OnInit, OnChanges, OnDestroy} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

import { ContentContainerUtils} from './content-container.utils';
import {AutoLogoutService} from '../auto-logout.service';
import {ZONAR_TIME_FORMAT} from '../driver-logs/pipe/zonar-timestamp.pipe';
import {Store} from '@ngrx/store';
import {DriverModuleState} from '../driver-logs/state/reducers/driver-logs.reducer';
import {SetDate} from '../driver-logs/state/actions/driver-logs.actions';
import {GetDriverEvents} from '../driver-logs/state/actions/driver-events.actions';
import {GetDriverGridData} from '../driver-logs/state/actions/driver-grid-data.actions';
import {GetDriverClocks} from '../driver-logs/state/actions/driver-clocks.actions';
import {CertifyLogsState} from '../certify-logs/state/certify-logs.reducer';
import {GetUncertifiedDays} from '../certify-logs/state/certify-logs.actions';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import {BehaviorSubject, combineLatest, interval, Observable, Subscription} from 'rxjs';
import {UserInfoService} from '../user-info.service';
import {ApiMiddlewareService} from '../api-middleware/api-middleware.service';
import {selectDriverProfileUuid} from '../state/app.selectors';
import {SysvarState} from '../state/app.reducer';
import {AuthService} from '@auth0/auth0-angular';
import {ByDriverProfileService} from '../api-middleware/api-service/compliance/services';
import {DatadogService} from '../datadog.service';
import {delay, take} from 'rxjs/operators';

dayjs.extend(timezone);

@Component({
    selector: 'app-content-container',
    templateUrl: './content-container.component.html',
    styleUrls: ['./content-container.component.scss'],
})
export class ContentContainerComponent implements OnInit, OnDestroy {
    // internal var for data
    contentArray: any;
    data: any;
    // internal var needed for state management
    active: boolean;
    obs: any;
    // util service
    utils = new ContentContainerUtils;
    // todo: selected profile is this needed or can be cleaned up

    SelectedDriverProfile: any;
    SelectedCompany: any;
    ActiveEditCompany: any;
    ActiveEditDivision: any;
    divisions: any[];
    profiles: any[];
    type: string;
    val: any;

    isInCertifyMode: any = false;
    isDriverWithNoDutyStatus = false;
    displayText: BehaviorSubject<string> = new BehaviorSubject<string>('');

    //everything below this is new code

    userID = null;
    dsValue: any = new BehaviorSubject(null);

    driverInfo = {
      userName: '',
      company: '',
      userId: '',
      divisions: [],
      currentDivision: '',
      authStatus: false,
    };

    constructor(
        private snackBar: MatSnackBar,
        private autoLogout: AutoLogoutService,
        private driverLogsStore: Store<DriverModuleState>,
        private certifyLogsStore: Store<CertifyLogsState>,
        private userInfo: UserInfoService,
        private apiService: ApiMiddlewareService,
        private sysvarStore: Store<SysvarState>,
        private authService: AuthService,
        private dutyStatusService: ByDriverProfileService,
        private datadogService: DatadogService
    ) {
      combineLatest([
        UserInfoService.driverWithNoDS,
        this.sysvarStore.select(selectDriverProfileUuid),
        this.authService.isAuthenticated$]).subscribe(([noDS, userId, authStatus]) => {
          this.isDriverWithNoDutyStatus = noDS;
          if (userId && authStatus) {
            this.userID = userId;
            if (!this.isDriverWithNoDutyStatus) {
              this.displayText.next('');
              this.getDSForCard(userId);
            }
            // set Interval insertd to update time card for the duty status
            setInterval(e => {
              if (!this.active) {
                if (!this.isDriverWithNoDutyStatus) {
                    this.updateDutyStatusValue(this.dsValue.value);
                }
              }
            }, 20000);
        }
      });
    }

    getDSForCard(id){
      if (id) {
        this.obs = this.dutyStatusService.getDutyStatusResource({'driverProfileUuid': id}).pipe(delay(1000), take(1)).subscribe(d => {
          this.dsValue.next(d);
          this.updateDutyStatusValue(d);
        }, error => {
          console.log(error);
          this.datadogService.addRumError(error);
          if (error['status'] === 422 && (error?.message || error?.error?.message)) {
            let message = error?.error?.message || error?.message;
            if (message.includes('doesn\'t have a current Duty Status')) {
              UserInfoService.driverWithNoDS.next(!!message);
              this.displayText.next('Tablet login needed for driver dashboard data. Please login now.');
            }
          }
          return error;
        });
      }
    }

    ngOnInit() {
        this.driverInfo = this.userInfo.driverInformation;
        this.contentArray = this.utils.generateContentModel('Loading....', 'loading');
        this.data = {};
    }

    onClick(objct: any) {
        const indexValue = Number(objct.key);
        let profile = this.contentArray[indexValue];
        this.active = true;
        profile = this.utils.returnState(profile, objct.value);
        if (profile.mediaContent && profile.mediaContent.state === 'loading') {
            this.apiService.setDutyStatus(this.driverInfo.userId, objct.value, 'ELD').then((d: any) => {
                if (!d.error) {
                    this.snackBar.open('Saved', '', {duration: 2000, });
                    this.contentArray = this.utils.generateContentModel('Loading....', 'loading');
                    this.getDSForCard(this.userID);
                    if (this.SelectedDriverProfile === this.ActiveEditDivision) {
                        setTimeout(e => this.active = false, 30000);
                    } else {
                        this.active = false;
                    }
                    this.ActiveEditDivision = null;
                    setTimeout(() => {
                        if (!this.isInCertifyMode) {
                            this.driverLogsStore.dispatch(new SetDate(dayjs().format(ZONAR_TIME_FORMAT.yearMonthDay)));
                            this.driverLogsStore.dispatch(new GetDriverEvents());
                            this.driverLogsStore.dispatch(new GetDriverGridData());
                            this.driverLogsStore.dispatch(new GetDriverClocks());
                            this.certifyLogsStore.dispatch(new GetUncertifiedDays());
                        }
                    }, 900);
                } else {
                    this.snackBar.open('Save Failed. Try Reloading the Page', '', {duration: 1000, });
                }
            });
        }
        if (profile === 'CANCEL') {
            setTimeout(e => this.active = false, 400);
            this.ActiveEditDivision = null;
        }
        if (profile === 'clicked') {
            this.ActiveEditCompany = this.SelectedCompany;
            this.ActiveEditDivision = this.SelectedDriverProfile;
        }
    }

    headerOnClick(e: any) {
        this.data.driverProfiles.map(x => {
            if (x.divisionId === e) {
                this.SelectedDriverProfile = x;
                if (this.ActiveEditDivision === null) {
                    this.active = false;
                }
            }
        });
    }

    // make sure card doesnt leave open sub on destroy
    ngOnDestroy() {
        if (this.obs) {
            this.obs.unsubscribe();
        }
    }

    getCompany(id) {
        return this.data.divisions.find(x => {
            if (x.id === id) {
                return x;
            }
        });
    }
    // updates duty status display while aware of special duty status
    updateDutyStatusValue(status) {
      let getHourDelta = (creationTime) => {
        const delta = Math.round(new Date().getTime() - Date.parse(creationTime)) ;
        if (delta > 3600000) {
          return Math.round(delta / 3600000).toString() + ' HR';
        } else {
          return  Math.round(delta / 60000).toString() + ' Min';
        }
      }
        const hourDelta = status ? getHourDelta(status.created) : null;

        if (status && !status.dutyStatus) {
            this.contentArray = this.utils.generateContentModel(
                status.specialDutyStatus,
                status.specialDutyStatus,
                this.driverInfo.company,
                hourDelta,
                'button',
                this.driverInfo.currentDivision
            );
        } else if (status && status.specialDutyStatus) {
            this.contentArray = this.utils.generateContentModel(
                status.specialDutyStatus,
                status.specialDutyStatus,
              this.driverInfo.company,
                hourDelta,
                'status',
              this.driverInfo.currentDivision
            );
        } else if (status) {
            this.contentArray = this.utils.generateContentModel(
                status.dutyStatus,
                status.dutyStatus,
              this.driverInfo.company,
                hourDelta,
                'status',
              this.driverInfo.currentDivision
            );
        }
        this.contentArray = [...this.contentArray];
    }

    toggleCertify(info: any) {
        this.isInCertifyMode = info;
    }
}
