import {Component, Input, OnInit} from '@angular/core';
import {DriverLogsService} from '../../driver-logs/state/driver-logs.service';
import {ZONAR_TIME_FORMAT} from '../../driver-logs/pipe/zonar-timestamp.pipe';
import {Store} from '@ngrx/store';
import {DriverModuleState} from '../../driver-logs/state/reducers/driver-logs.reducer';
import {SetDate} from '../../driver-logs/state/actions/driver-logs.actions';
import {combineLatest} from 'rxjs';
import {selectCurrentDate, selectDriverId} from '../../driver-logs/state/driver-logs.selector';
import {GetDriverEvents} from '../../driver-logs/state/actions/driver-events.actions';
import {GetDriverGridData} from '../../driver-logs/state/actions/driver-grid-data.actions';
import {GetDriverClocks} from '../../driver-logs/state/actions/driver-clocks.actions';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import daysDuration from 'dayjs/plugin/duration';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import {UserInfoService} from '../../user-info.service';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(daysDuration);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const DATE_FORMAT = ZONAR_TIME_FORMAT.yearMonthDay;

@Component({
    selector: 'app-date-control',
    templateUrl: './date-control.component.html',
    styleUrls: ['./date-control.component.scss'],
})
export class DateControlComponent implements OnInit {
    @Input() beginDate = dayjs();
    @Input() isInCertifyMode = false;
    selectedDate;
    minDate = dayjs().subtract(15, 'days');
    isDisabled = false;

    constructor(private driverLogsStore: Store<DriverModuleState>) {
    }

    ngOnInit() {
      combineLatest([
          this.driverLogsStore.select(selectDriverId),
          this.driverLogsStore.select(selectCurrentDate)]).subscribe(([driverId, date]) => {
          if (date !== this.selectedDate) {
              this.selectedDate = date;
          }
          if (driverId) {
              this.getDriverLogs(date);
          }
      });
      if (!this.isInCertifyMode) {
        setTimeout(() => this.dateChanged(this.beginDate), 200);
      };
      UserInfoService.driverWithNoDS.subscribe(val => {
        this.isDisabled = val;
      });
    }

    private dateChanged(date: dayjs.Dayjs): void {
        const data = date.format(DATE_FORMAT);
        this.selectedDate = date;
        this.driverLogsStore.dispatch(new SetDate(data));
    }

    public canGoBack(): boolean {
        const date = dayjs(this.selectedDate).subtract(1, 'day');
        return date.isBefore(this.beginDate) && date.isSameOrAfter(this.minDate) && !this.isInCertifyMode;
    }

    public canGoForward(): boolean {
      const date = dayjs(this.selectedDate).add(1, 'day');
      return date.isSameOrBefore(this.beginDate) && !this.isInCertifyMode;
    }

    public goBack(): void {
      const newDate = dayjs(this.selectedDate).subtract(1, 'day');
      if (this.canGoBack() && !this.isDisabled) {
            this.dateChanged(newDate);
        }
    }

    public goForward(): void {
      const newDate = dayjs(this.selectedDate).add(1, 'day');
      if (this.canGoForward() && !this.isDisabled) {
            this.dateChanged(newDate);
        }
    }

    private getDriverLogs(currentDate: string) {
        this.driverLogsStore.dispatch(new GetDriverEvents());
        this.driverLogsStore.dispatch(new GetDriverGridData());
        if (currentDate === this.beginDate.format(DATE_FORMAT)) {
            this.driverLogsStore.dispatch(new GetDriverClocks());
        }
    }
}
