import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {CertifyLogsService} from '../certify-logs.service';
import {DriverModuleState} from '../../driver-logs/state/reducers/driver-logs.reducer';
import {Store} from '@ngrx/store';
import {selectDriverId} from '../../driver-logs/state/driver-logs.selector';
import {
    GET_UNCERTIFIED_DAYS,
    GET_UNCERTIFIED_DAYS_SUCCESS,
    POST_DRIVER_CERTIFY_DAY,
    POST_DRIVER_CERTIFY_DAY_SUCCESS
} from './certify-logs.actions';
import {catchError, delay, map, mergeMap, switchMap} from 'rxjs/operators';
import {EMPTY} from 'rxjs';
import {
    POST_DRIVER_ADD_MANUAL_EVENT_SUCCESS,
    POST_DRIVER_EDIT_EXISTING_EVENT_SUCCESS
} from '../../driver-logs/state/actions/driver-events.actions';

@Injectable()
export class CertifyLogsEffects {
    driverProfileUuid: string;
    constructor(
        private actions$: Actions,
        private certifyLogsService: CertifyLogsService,
        private driverLogsStore: Store<DriverModuleState>
    ) {
        this.driverLogsStore.select(selectDriverId).subscribe((driverId) => {
            if (driverId) {
                this.driverProfileUuid = driverId;
            }
        });
    }

    @Effect()
    UncertifiedDays = this.actions$.pipe(
        ofType(
            GET_UNCERTIFIED_DAYS,
            POST_DRIVER_EDIT_EXISTING_EVENT_SUCCESS,
            POST_DRIVER_ADD_MANUAL_EVENT_SUCCESS
        ),
        switchMap(() => this.certifyLogsService.getDriverUncertifiedDays(this.driverProfileUuid)
            .pipe(
                map( data => ({
                    type: GET_UNCERTIFIED_DAYS_SUCCESS,
                    payload: {
                        days: data.uncertified_days,
                        oldest_uncertified_day: data.max_delta
                    }
                })),
                catchError(() => EMPTY))
            )
    );

    @Effect()
    CertifyDay = this.actions$.pipe(
        ofType(
            POST_DRIVER_CERTIFY_DAY
        ),
        mergeMap((data: any) => this.certifyLogsService.postDriverCertifyDays(this.driverProfileUuid, data.params)
            .pipe(
                delay(900),
                map( response => ({
                    type: POST_DRIVER_CERTIFY_DAY_SUCCESS,
                    payload: {
                        response: response.result,
                        day: data.params.day
                    }
                })),
                catchError(() => EMPTY)
            )
        )
    );
}
