import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {DriverEventEditComponent} from '../driver-event-edit/driver-event-edit.component';
import {POST_DRIVER_ADD_MANUAL_EVENT} from '../state/actions/driver-events.actions';
import {UserInfoService} from '../../user-info.service';

@Component({
  selector: 'app-add-driver-event-button',
  templateUrl: './add-driver-event-button.component.html',
  styleUrls: ['./add-driver-event-button.component.scss']
})
export class AddDriverEventButtonComponent implements OnInit {

  isDisabled = false;
  constructor(private dialog: MatDialog) { }

  ngOnInit() {
    UserInfoService.driverWithNoDS.subscribe(val => {
      this.isDisabled = val;
    });
  }

  public openAddModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Add Event',
      event: {},
      action: POST_DRIVER_ADD_MANUAL_EVENT,
      pendoClass: 'add-event-submit'
    };
    this.dialog.open(DriverEventEditComponent, dialogConfig);
  }

}
