/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Availability } from '../models/availability';
import { PostCertifyLogs } from '../models/post-certify-logs';
import { PostCreateDriverEvent } from '../models/post-create-driver-event';
import { EditedDriverEvent } from '../models/edited-driver-event';
import { PostEditDriverEvent } from '../models/post-edit-driver-event';
import { DriverEvent } from '../models/driver-event';
import { DriverEventSummary } from '../models/driver-event-summary';
import { DutyStatus } from '../models/duty-status';
import { PostDutyStatus } from '../models/post-duty-status';
import { LiveStatusModel } from '../models/live-status-model';
import { PDFExport } from '../models/pdfexport';
import { PostDriverPDFExport } from '../models/post-driver-pdfexport';
import { Sysvar } from '../models/sysvar';
import { PostSysvar } from '../models/post-sysvar';
import { UncertifiedDates } from '../models/uncertified-dates';
import { Violations } from '../models/violations';

/**
 * Endpoints that are specific to a particular driver.
 */
@Injectable({
  providedIn: 'root',
})
class ByDriverProfileService extends __BaseService {
  static readonly getAvailabilityByDriverPath = '/driverprofile/{driverProfileUuid}/availability';
  static readonly postCertifyLogsPath = '/driverprofile/{driverProfileUuid}/certify_logs';
  static readonly postAddDriverEventPath = '/driverprofile/{driverProfileUuid}/driver_event';
  static readonly postEditDriverEventPath = '/driverprofile/{driverProfileUuid}/driver_event/{oid}/{current_event_code_name}';
  static readonly getGetDriverEventListPath = '/driverprofile/{driverProfileUuid}/driver_event_list/{day}';
  static readonly getGetDriverEventSummaryPath = '/driverprofile/{driverProfileUuid}/driver_event_summary/{day}';
  static readonly getDutyStatusResourcePath = '/driverprofile/{driverProfileUuid}/duty_status';
  static readonly postDutyStatusByTypeResourcePath = '/driverprofile/{driverProfileUuid}/duty_status/{type}';
  static readonly getDutyStatusByTypeResourcePath = '/driverprofile/{driverProfileUuid}/duty_status/{type}';
  static readonly getLiveStatusApiPath = '/driverprofile/{driverProfileUuid}/livestatus';
  static readonly postLogDriverOutByDriverResourcePath = '/driverprofile/{driverProfileUuid}/log_driver_out_of_tablet';
  static readonly postPdfExportByDriverResourcePath = '/driverprofile/{driverProfileUuid}/pdf_export';
  static readonly postGetSysvarPath = '/driverprofile/{driverProfileUuid}/sysvar';
  static readonly getGetUncertifiedDaysPath = '/driverprofile/{driverProfileUuid}/uncertified';
  static readonly getViolationsByDriverPath = '/driverprofile/{driverProfileUuid}/violations';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ByDriverProfileService.GetAvailabilityByDriverParams` containing the following parameters:
   *
   * - `legacyDriverId`: Numeric driver_gtc_id, for efficiency, will be unused in later API versions.
   *
   * - `legacyAccountCode`: GTC account code (will be deprecated). {error_msg}
   *
   * - `driverProfileUuid`: Unused, will be required in later API versions.
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Driver Availability
   */
  getAvailabilityByDriverResponse(params: ByDriverProfileService.GetAvailabilityByDriverParams): __Observable<__StrictHttpResponse<Array<Availability>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.legacyDriverId != null) __params = __params.set('legacyDriverId', params.legacyDriverId.toString());
    if (params.legacyAccountCode != null) __params = __params.set('legacyAccountCode', params.legacyAccountCode.toString());

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/driverprofile/${encodeURIComponent(String(params.driverProfileUuid))}/availability`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Availability>>;
      })
    );
  }
  /**
   * @param params The `ByDriverProfileService.GetAvailabilityByDriverParams` containing the following parameters:
   *
   * - `legacyDriverId`: Numeric driver_gtc_id, for efficiency, will be unused in later API versions.
   *
   * - `legacyAccountCode`: GTC account code (will be deprecated). {error_msg}
   *
   * - `driverProfileUuid`: Unused, will be required in later API versions.
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Driver Availability
   */
  getAvailabilityByDriver(params: ByDriverProfileService.GetAvailabilityByDriverParams): __Observable<Array<Availability>> {
    return this.getAvailabilityByDriverResponse(params).pipe(
      __map(_r => _r.body as Array<Availability>)
    );
  }

  /**
   * @param params The `ByDriverProfileService.PostCertifyLogsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `driverProfileUuid`: UUID of driverProfile entity
   */
  postCertifyLogsResponse(params: ByDriverProfileService.PostCertifyLogsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/driverprofile/${encodeURIComponent(String(params.driverProfileUuid))}/certify_logs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ByDriverProfileService.PostCertifyLogsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `driverProfileUuid`: UUID of driverProfile entity
   */
  postCertifyLogs(params: ByDriverProfileService.PostCertifyLogsParams): __Observable<null> {
    return this.postCertifyLogsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ByDriverProfileService.PostAddDriverEventParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `driverProfileUuid`: UUID of driverProfile entity.
   */
  postAddDriverEventResponse(params: ByDriverProfileService.PostAddDriverEventParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/driverprofile/${encodeURIComponent(String(params.driverProfileUuid))}/driver_event`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ByDriverProfileService.PostAddDriverEventParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `driverProfileUuid`: UUID of driverProfile entity.
   */
  postAddDriverEvent(params: ByDriverProfileService.PostAddDriverEventParams): __Observable<null> {
    return this.postAddDriverEventResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ByDriverProfileService.PostEditDriverEventParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `oid`: Unique identifier of the event
   *
   * - `driverProfileUuid`: UUID of driverProfile entity.
   *
   * - `current_event_code_name`: Event code name of the event that needs to be edited (case insensitive)
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  postEditDriverEventResponse(params: ByDriverProfileService.PostEditDriverEventParams): __Observable<__StrictHttpResponse<EditedDriverEvent>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;



    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/driverprofile/${encodeURIComponent(String(params.driverProfileUuid))}/driver_event/${encodeURIComponent(String(params.oid))}/${encodeURIComponent(String(params.currentEventCodeName))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EditedDriverEvent>;
      })
    );
  }
  /**
   * @param params The `ByDriverProfileService.PostEditDriverEventParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `oid`: Unique identifier of the event
   *
   * - `driverProfileUuid`: UUID of driverProfile entity.
   *
   * - `current_event_code_name`: Event code name of the event that needs to be edited (case insensitive)
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  postEditDriverEvent(params: ByDriverProfileService.PostEditDriverEventParams): __Observable<EditedDriverEvent> {
    return this.postEditDriverEventResponse(params).pipe(
      __map(_r => _r.body as EditedDriverEvent)
    );
  }

  /**
   * @param params The `ByDriverProfileService.GetGetDriverEventListParams` containing the following parameters:
   *
   * - `driverProfileUuid`: UUID of driverProfile entity.
   *
   * - `day`: Target date
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getGetDriverEventListResponse(params: ByDriverProfileService.GetGetDriverEventListParams): __Observable<__StrictHttpResponse<DriverEvent>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/driverprofile/${encodeURIComponent(String(params.driverProfileUuid))}/driver_event_list/${encodeURIComponent(String(params.day))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DriverEvent>;
      })
    );
  }
  /**
   * @param params The `ByDriverProfileService.GetGetDriverEventListParams` containing the following parameters:
   *
   * - `driverProfileUuid`: UUID of driverProfile entity.
   *
   * - `day`: Target date
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getGetDriverEventList(params: ByDriverProfileService.GetGetDriverEventListParams): __Observable<DriverEvent> {
    return this.getGetDriverEventListResponse(params).pipe(
      __map(_r => _r.body as DriverEvent)
    );
  }

  /**
   * @param params The `ByDriverProfileService.GetGetDriverEventSummaryParams` containing the following parameters:
   *
   * - `driverProfileUuid`: UUID of driverProfile entity.
   *
   * - `day`: Target date
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getGetDriverEventSummaryResponse(params: ByDriverProfileService.GetGetDriverEventSummaryParams): __Observable<__StrictHttpResponse<DriverEventSummary>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/driverprofile/${encodeURIComponent(String(params.driverProfileUuid))}/driver_event_summary/${encodeURIComponent(String(params.day))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DriverEventSummary>;
      })
    );
  }
  /**
   * @param params The `ByDriverProfileService.GetGetDriverEventSummaryParams` containing the following parameters:
   *
   * - `driverProfileUuid`: UUID of driverProfile entity.
   *
   * - `day`: Target date
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getGetDriverEventSummary(params: ByDriverProfileService.GetGetDriverEventSummaryParams): __Observable<DriverEventSummary> {
    return this.getGetDriverEventSummaryResponse(params).pipe(
      __map(_r => _r.body as DriverEventSummary)
    );
  }

  /**
   * @param params The `ByDriverProfileService.GetDutyStatusResourceParams` containing the following parameters:
   *
   * - `driverProfileUuid`: UUID of driverProfile entity.
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getDutyStatusResourceResponse(params: ByDriverProfileService.GetDutyStatusResourceParams): __Observable<__StrictHttpResponse<DutyStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/driverprofile/${encodeURIComponent(String(params.driverProfileUuid))}/duty_status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DutyStatus>;
      })
    );
  }
  /**
   * @param params The `ByDriverProfileService.GetDutyStatusResourceParams` containing the following parameters:
   *
   * - `driverProfileUuid`: UUID of driverProfile entity.
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getDutyStatusResource(params: ByDriverProfileService.GetDutyStatusResourceParams): __Observable<DutyStatus> {
    return this.getDutyStatusResourceResponse(params).pipe(
      __map(_r => _r.body as DutyStatus)
    );
  }

  /**
   * @param params The `ByDriverProfileService.PostDutyStatusByTypeResourceParams` containing the following parameters:
   *
   * - `type`: Regulatory mode, deprecated AOBRD
   *
   * - `payload`:
   *
   * - `driverProfileUuid`: UUID of driverProfile entity.
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  postDutyStatusByTypeResourceResponse(params: ByDriverProfileService.PostDutyStatusByTypeResourceParams): __Observable<__StrictHttpResponse<DutyStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/driverprofile/${encodeURIComponent(String(params.driverProfileUuid))}/duty_status/${encodeURIComponent(String(params.type))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DutyStatus>;
      })
    );
  }
  /**
   * @param params The `ByDriverProfileService.PostDutyStatusByTypeResourceParams` containing the following parameters:
   *
   * - `type`: Regulatory mode, deprecated AOBRD
   *
   * - `payload`:
   *
   * - `driverProfileUuid`: UUID of driverProfile entity.
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  postDutyStatusByTypeResource(params: ByDriverProfileService.PostDutyStatusByTypeResourceParams): __Observable<DutyStatus> {
    return this.postDutyStatusByTypeResourceResponse(params).pipe(
      __map(_r => _r.body as DutyStatus)
    );
  }

  /**
   * @param params The `ByDriverProfileService.GetDutyStatusByTypeResourceParams` containing the following parameters:
   *
   * - `type`: Regulatory mode, deprecated AOBRD
   *
   * - `driverProfileUuid`: UUID of driverProfile entity.
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getDutyStatusByTypeResourceResponse(params: ByDriverProfileService.GetDutyStatusByTypeResourceParams): __Observable<__StrictHttpResponse<DutyStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/driverprofile/${encodeURIComponent(String(params.driverProfileUuid))}/duty_status/${encodeURIComponent(String(params.type))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DutyStatus>;
      })
    );
  }
  /**
   * @param params The `ByDriverProfileService.GetDutyStatusByTypeResourceParams` containing the following parameters:
   *
   * - `type`: Regulatory mode, deprecated AOBRD
   *
   * - `driverProfileUuid`: UUID of driverProfile entity.
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getDutyStatusByTypeResource(params: ByDriverProfileService.GetDutyStatusByTypeResourceParams): __Observable<DutyStatus> {
    return this.getDutyStatusByTypeResourceResponse(params).pipe(
      __map(_r => _r.body as DutyStatus)
    );
  }

  /**
   * @param params The `ByDriverProfileService.GetLiveStatusApiParams` containing the following parameters:
   *
   * - `legacyDriverId`: Numeric driver_gtc_id, for efficiency, will be unused in later API versions.
   *
   * - `legacyAccountCode`: GTC account code (will be deprecated). {error_msg}
   *
   * - `driverProfileUuid`: Unused, will be required in later API versions.
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Live Status
   */
  getLiveStatusApiResponse(params: ByDriverProfileService.GetLiveStatusApiParams): __Observable<__StrictHttpResponse<LiveStatusModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.legacyDriverId != null) __params = __params.set('legacyDriverId', params.legacyDriverId.toString());
    if (params.legacyAccountCode != null) __params = __params.set('legacyAccountCode', params.legacyAccountCode.toString());

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/driverprofile/${encodeURIComponent(String(params.driverProfileUuid))}/livestatus`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LiveStatusModel>;
      })
    );
  }
  /**
   * @param params The `ByDriverProfileService.GetLiveStatusApiParams` containing the following parameters:
   *
   * - `legacyDriverId`: Numeric driver_gtc_id, for efficiency, will be unused in later API versions.
   *
   * - `legacyAccountCode`: GTC account code (will be deprecated). {error_msg}
   *
   * - `driverProfileUuid`: Unused, will be required in later API versions.
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Live Status
   */
  getLiveStatusApi(params: ByDriverProfileService.GetLiveStatusApiParams): __Observable<LiveStatusModel> {
    return this.getLiveStatusApiResponse(params).pipe(
      __map(_r => _r.body as LiveStatusModel)
    );
  }

  /**
   * @param driverProfileUuid UUID of driverProfile entity.
   */
  postLogDriverOutByDriverResourceResponse(driverProfileUuid: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/driverprofile/${encodeURIComponent(String(driverProfileUuid))}/log_driver_out_of_tablet`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param driverProfileUuid UUID of driverProfile entity.
   */
  postLogDriverOutByDriverResource(driverProfileUuid: string): __Observable<null> {
    return this.postLogDriverOutByDriverResourceResponse(driverProfileUuid).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ByDriverProfileService.PostPdfExportByDriverResourceParams` containing the following parameters:
   *
   * - `start_day`: Start of export
   *
   * - `payload`:
   *
   * - `end_day`: End date of export
   *
   * - `driverProfileUuid`: UUID of driverProfile entity.
   *
   * - `units`: Choose the units to display
   *
   * - `show_violations`: Show violation colors on report
   *
   * - `country`: ELD PDF Export country (us or ca)
   *
   * - `comment`: User's comment
   *
   * - `black_and_white`: Show colors on report
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  postPdfExportByDriverResourceResponse(params: ByDriverProfileService.PostPdfExportByDriverResourceParams): __Observable<__StrictHttpResponse<PDFExport>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDay != null) __params = __params.set('start_day', params.startDay.toString());
    __body = params.payload;
    if (params.endDay != null) __params = __params.set('end_day', params.endDay.toString());

    if (params.units != null) __params = __params.set('units', params.units.toString());
    if (params.showViolations != null) __params = __params.set('show_violations', params.showViolations.toString());
    if (params.country != null) __params = __params.set('country', params.country.toString());
    if (params.comment != null) __params = __params.set('comment', params.comment.toString());
    if (params.blackAndWhite != null) __params = __params.set('black_and_white', params.blackAndWhite.toString());
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/driverprofile/${encodeURIComponent(String(params.driverProfileUuid))}/pdf_export`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PDFExport>;
      })
    );
  }
  /**
   * @param params The `ByDriverProfileService.PostPdfExportByDriverResourceParams` containing the following parameters:
   *
   * - `start_day`: Start of export
   *
   * - `payload`:
   *
   * - `end_day`: End date of export
   *
   * - `driverProfileUuid`: UUID of driverProfile entity.
   *
   * - `units`: Choose the units to display
   *
   * - `show_violations`: Show violation colors on report
   *
   * - `country`: ELD PDF Export country (us or ca)
   *
   * - `comment`: User's comment
   *
   * - `black_and_white`: Show colors on report
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  postPdfExportByDriverResource(params: ByDriverProfileService.PostPdfExportByDriverResourceParams): __Observable<PDFExport> {
    return this.postPdfExportByDriverResourceResponse(params).pipe(
      __map(_r => _r.body as PDFExport)
    );
  }

  /**
   * @param params The `ByDriverProfileService.PostGetSysvarParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `driverProfileUuid`: UUID of driverProfile entity.
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  postGetSysvarResponse(params: ByDriverProfileService.PostGetSysvarParams): __Observable<__StrictHttpResponse<Sysvar>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/driverprofile/${encodeURIComponent(String(params.driverProfileUuid))}/sysvar`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Sysvar>;
      })
    );
  }
  /**
   * @param params The `ByDriverProfileService.PostGetSysvarParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `driverProfileUuid`: UUID of driverProfile entity.
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  postGetSysvar(params: ByDriverProfileService.PostGetSysvarParams): __Observable<Sysvar> {
    return this.postGetSysvarResponse(params).pipe(
      __map(_r => _r.body as Sysvar)
    );
  }

  /**
   * @param driverProfileUuid UUID of driverProfile entity
   * @return Success
   */
  getGetUncertifiedDaysResponse(driverProfileUuid: string): __Observable<__StrictHttpResponse<UncertifiedDates>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/driverprofile/${encodeURIComponent(String(driverProfileUuid))}/uncertified`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UncertifiedDates>;
      })
    );
  }
  /**
   * @param driverProfileUuid UUID of driverProfile entity
   * @return Success
   */
  getGetUncertifiedDays(driverProfileUuid: string): __Observable<UncertifiedDates> {
    return this.getGetUncertifiedDaysResponse(driverProfileUuid).pipe(
      __map(_r => _r.body as UncertifiedDates)
    );
  }

  /**
   * @param params The `ByDriverProfileService.GetViolationsByDriverParams` containing the following parameters:
   *
   * - `start`:
   *
   * - `legacyDriverId`: Numeric driver_gtc_id, for efficiency, will be unused in later API versions.
   *
   * - `legacyAccountCode`: GTC account code (will be deprecated). {error_msg}
   *
   * - `end`:
   *
   * - `driverProfileUuid`: Unused, will be required in later API versions.
   *
   * - `type`: Violation type (can be comma-separated list). {error_msg}
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return HOS Violations
   */
  getViolationsByDriverResponse(params: ByDriverProfileService.GetViolationsByDriverParams): __Observable<__StrictHttpResponse<Array<Violations>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.start != null) __params = __params.set('start', params.start.toString());
    if (params.legacyDriverId != null) __params = __params.set('legacyDriverId', params.legacyDriverId.toString());
    if (params.legacyAccountCode != null) __params = __params.set('legacyAccountCode', params.legacyAccountCode.toString());
    if (params.end != null) __params = __params.set('end', params.end.toString());

    if (params.type != null) __params = __params.set('type', params.type.toString());
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/driverprofile/${encodeURIComponent(String(params.driverProfileUuid))}/violations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Violations>>;
      })
    );
  }
  /**
   * @param params The `ByDriverProfileService.GetViolationsByDriverParams` containing the following parameters:
   *
   * - `start`:
   *
   * - `legacyDriverId`: Numeric driver_gtc_id, for efficiency, will be unused in later API versions.
   *
   * - `legacyAccountCode`: GTC account code (will be deprecated). {error_msg}
   *
   * - `end`:
   *
   * - `driverProfileUuid`: Unused, will be required in later API versions.
   *
   * - `type`: Violation type (can be comma-separated list). {error_msg}
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return HOS Violations
   */
  getViolationsByDriver(params: ByDriverProfileService.GetViolationsByDriverParams): __Observable<Array<Violations>> {
    return this.getViolationsByDriverResponse(params).pipe(
      __map(_r => _r.body as Array<Violations>)
    );
  }
}

module ByDriverProfileService {

  /**
   * Parameters for getAvailabilityByDriver
   */
  export interface GetAvailabilityByDriverParams {

    /**
     * Numeric driver_gtc_id, for efficiency, will be unused in later API versions.
     */
    legacyDriverId: number;

    /**
     * GTC account code (will be deprecated). {error_msg}
     */
    legacyAccountCode: string;

    /**
     * Unused, will be required in later API versions.
     */
    driverProfileUuid: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for postCertifyLogs
   */
  export interface PostCertifyLogsParams {
    payload: PostCertifyLogs;

    /**
     * UUID of driverProfile entity
     */
    driverProfileUuid: string;
  }

  /**
   * Parameters for postAddDriverEvent
   */
  export interface PostAddDriverEventParams {
    payload: PostCreateDriverEvent;

    /**
     * UUID of driverProfile entity.
     */
    driverProfileUuid: string;
  }

  /**
   * Parameters for postEditDriverEvent
   */
  export interface PostEditDriverEventParams {
    payload: PostEditDriverEvent;

    /**
     * Unique identifier of the event
     */
    oid: string;

    /**
     * UUID of driverProfile entity.
     */
    driverProfileUuid: string;

    /**
     * Event code name of the event that needs to be edited (case insensitive)
     */
    currentEventCodeName: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getGetDriverEventList
   */
  export interface GetGetDriverEventListParams {

    /**
     * UUID of driverProfile entity.
     */
    driverProfileUuid: string;

    /**
     * Target date
     */
    day: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getGetDriverEventSummary
   */
  export interface GetGetDriverEventSummaryParams {

    /**
     * UUID of driverProfile entity.
     */
    driverProfileUuid: string;

    /**
     * Target date
     */
    day: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getDutyStatusResource
   */
  export interface GetDutyStatusResourceParams {

    /**
     * UUID of driverProfile entity.
     */
    driverProfileUuid: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for postDutyStatusByTypeResource
   */
  export interface PostDutyStatusByTypeResourceParams {

    /**
     * Regulatory mode, deprecated AOBRD
     */
    type: 'ELD' | 'AOBRD';
    payload: PostDutyStatus;

    /**
     * UUID of driverProfile entity.
     */
    driverProfileUuid: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getDutyStatusByTypeResource
   */
  export interface GetDutyStatusByTypeResourceParams {

    /**
     * Regulatory mode, deprecated AOBRD
     */
    type: 'ELD' | 'AOBRD';

    /**
     * UUID of driverProfile entity.
     */
    driverProfileUuid: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getLiveStatusApi
   */
  export interface GetLiveStatusApiParams {

    /**
     * Numeric driver_gtc_id, for efficiency, will be unused in later API versions.
     */
    legacyDriverId: number;

    /**
     * GTC account code (will be deprecated). {error_msg}
     */
    legacyAccountCode: string;

    /**
     * Unused, will be required in later API versions.
     */
    driverProfileUuid: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for postPdfExportByDriverResource
   */
  export interface PostPdfExportByDriverResourceParams {

    /**
     * Start of export
     */
    startDay: string;
    payload: PostDriverPDFExport;

    /**
     * End date of export
     */
    endDay: string;

    /**
     * UUID of driverProfile entity.
     */
    driverProfileUuid: string;

    /**
     * Choose the units to display
     */
    units?: string;

    /**
     * Show violation colors on report
     */
    showViolations?: string;

    /**
     * ELD PDF Export country (us or ca)
     */
    country?: string;

    /**
     * User's comment
     */
    comment?: string;

    /**
     * Show colors on report
     */
    blackAndWhite?: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for postGetSysvar
   */
  export interface PostGetSysvarParams {
    payload: PostSysvar;

    /**
     * UUID of driverProfile entity.
     */
    driverProfileUuid: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getViolationsByDriver
   */
  export interface GetViolationsByDriverParams {
    start: string;

    /**
     * Numeric driver_gtc_id, for efficiency, will be unused in later API versions.
     */
    legacyDriverId: number;

    /**
     * GTC account code (will be deprecated). {error_msg}
     */
    legacyAccountCode: string;
    end: string;

    /**
     * Unused, will be required in later API versions.
     */
    driverProfileUuid: string;

    /**
     * Violation type (can be comma-separated list). {error_msg}
     */
    type?: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { ByDriverProfileService }
