/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DriverProfile } from '../models/driver-profile';
import { DriverProfilePatch } from '../models/driver-profile-patch';

/**
 * Driver Profile API
 */
@Injectable({
  providedIn: 'root',
})
class DriverprofilesService extends __BaseService {
  static readonly getDriverProfilesApiListPath = '/driverprofiles';
  static readonly postDriverProfilesApiListPath = '/driverprofiles';
  static readonly getDriverProfilesApiResourcePath = '/driverprofiles/{profileId}';
  static readonly patchDriverProfilesApiResourcePath = '/driverprofiles/{profileId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Retrieve all DriverProfiles
   *
   * Get all driver profiles, or all profiles by driverId.
   * Handles GET /driverprofiles endpoint with URL parameters.
   *
   * URL Parameters:
   *     driverId (str): The UUID of the Driver
   *
   * On success returns an array of driver profile payloads constrained
   * any query parameters.
   * @param params The `DriverprofilesService.GetDriverProfilesApiListParams` containing the following parameters:
   *
   * - `driverId`:
   *
   * - `divisionId`:
   *
   * @return Success
   */
  getDriverProfilesApiListResponse(params: DriverprofilesService.GetDriverProfilesApiListParams): __Observable<__StrictHttpResponse<Array<DriverProfile>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.driverId != null) __params = __params.set('driverId', params.driverId.toString());
    if (params.divisionId != null) __params = __params.set('divisionId', params.divisionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/driverprofiles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DriverProfile>>;
      })
    );
  }
  /**
   * Retrieve all DriverProfiles
   *
   * Get all driver profiles, or all profiles by driverId.
   * Handles GET /driverprofiles endpoint with URL parameters.
   *
   * URL Parameters:
   *     driverId (str): The UUID of the Driver
   *
   * On success returns an array of driver profile payloads constrained
   * any query parameters.
   * @param params The `DriverprofilesService.GetDriverProfilesApiListParams` containing the following parameters:
   *
   * - `driverId`:
   *
   * - `divisionId`:
   *
   * @return Success
   */
  getDriverProfilesApiList(params: DriverprofilesService.GetDriverProfilesApiListParams): __Observable<Array<DriverProfile>> {
    return this.getDriverProfilesApiListResponse(params).pipe(
      __map(_r => _r.body as Array<DriverProfile>)
    );
  }

  /**
   * Create a DriverProfile
   *
   * Creates a profile for a driver under a given company and division.
   * If a profile for a driver already exists under a given company and
   * division a 409 status will be returned.
   *
   * Uses the driver_api.apis.api_v1.api_models.driver_profile_create_model
   * for incoming data.
   * @return Success
   */
  postDriverProfilesApiListResponse(): __Observable<__StrictHttpResponse<DriverProfile>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/driverprofiles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DriverProfile>;
      })
    );
  }
  /**
   * Create a DriverProfile
   *
   * Creates a profile for a driver under a given company and division.
   * If a profile for a driver already exists under a given company and
   * division a 409 status will be returned.
   *
   * Uses the driver_api.apis.api_v1.api_models.driver_profile_create_model
   * for incoming data.
   * @return Success
   */
  postDriverProfilesApiList(): __Observable<DriverProfile> {
    return this.postDriverProfilesApiListResponse().pipe(
      __map(_r => _r.body as DriverProfile)
    );
  }

  /**
   * Retrieve a single DriverProfile
   *
   * Retrieve a single driver profile by ID.
   * @param profile_id undefined
   * @return Success
   */
  getDriverProfilesApiResourceResponse(profileId: string): __Observable<__StrictHttpResponse<DriverProfile>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/driverprofiles/${encodeURIComponent(String(profileId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DriverProfile>;
      })
    );
  }
  /**
   * Retrieve a single DriverProfile
   *
   * Retrieve a single driver profile by ID.
   * @param profile_id undefined
   * @return Success
   */
  getDriverProfilesApiResource(profileId: string): __Observable<DriverProfile> {
    return this.getDriverProfilesApiResourceResponse(profileId).pipe(
      __map(_r => _r.body as DriverProfile)
    );
  }

  /**
   * Patch a DriverProfile
   *
   * Update an existing DriverProfile.
   * Uses the driver_api.apis.api_v1.api_models.driver_profile_patch_model
   * for incoming data.
   * @param params The `DriverprofilesService.PatchDriverProfilesApiResourceParams` containing the following parameters:
   *
   * - `profile_id`:
   *
   * - `payload`:
   */
  patchDriverProfilesApiResourceResponse(params: DriverprofilesService.PatchDriverProfilesApiResourceParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/driverprofiles/${encodeURIComponent(String(params.profileId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Patch a DriverProfile
   *
   * Update an existing DriverProfile.
   * Uses the driver_api.apis.api_v1.api_models.driver_profile_patch_model
   * for incoming data.
   * @param params The `DriverprofilesService.PatchDriverProfilesApiResourceParams` containing the following parameters:
   *
   * - `profile_id`:
   *
   * - `payload`:
   */
  patchDriverProfilesApiResource(params: DriverprofilesService.PatchDriverProfilesApiResourceParams): __Observable<null> {
    return this.patchDriverProfilesApiResourceResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module DriverprofilesService {

  /**
   * Parameters for getDriverProfilesApiList
   */
  export interface GetDriverProfilesApiListParams {
    driverId?: string;
    divisionId?: string;
  }

  /**
   * Parameters for patchDriverProfilesApiResource
   */
  export interface PatchDriverProfilesApiResourceParams {
    profileId: string;
    payload: DriverProfilePatch;
  }
}

export { DriverprofilesService }
