import {
  GET_DRIVER_COMPANY_SUCCESS,
  GET_DRIVER_DIVISION_SUCCESS, GET_DRIVER_PROFILE_UUID_SUCCESS,
  GET_DRIVER_USERNAME_SUCCESS,
  GET_SYSVAR,
  GET_SYSVAR_SUCCESS, SysvarAction,
} from './app.actions';

export const SysvarReducer = setReducer;

//todo: is it better to save the whole object or just the properties used in the app?
export interface SysvarState {
  sysvars: any;
  userName: string;
  company: string;
  userId: string;
  divisions: Array<string>;
  currentDivision: string;
  authStatus: boolean;
}

export const initialState: SysvarState = {
  sysvars: {},
  userName: '',
  company: '',
  userId: '',
  divisions: [],
  currentDivision: '',
  authStatus: false,
};

export function setReducer(state = initialState, action: SysvarAction): SysvarState {
  switch (action.type) {
    case GET_SYSVAR:
      return state;

    case GET_SYSVAR_SUCCESS:
      const newSysvarObject = {};
      newSysvarObject[action.sysvarName] = action.payload.data === 'True' ? true : false;
      return {
        ...state,
        sysvars: newSysvarObject
      };
    case GET_DRIVER_PROFILE_UUID_SUCCESS:
      return {
        ...state,
        userId: action.driverProfileUuid
      };
    case GET_DRIVER_DIVISION_SUCCESS:
      return {
        ...state,
        currentDivision: action.division
      };
    case GET_DRIVER_USERNAME_SUCCESS:
      return {
        ...state,
        userName: action.userName
      };
    case GET_DRIVER_COMPANY_SUCCESS:
      return {
        ...state,
        company: action.company
      };
    default:
      return state;
  }
}
