import { CardModel, ContentCardMediaModel } from '../content-card/content-card';
export interface ContentContainerUtilsInterface {
    ContentContainerUtils: ContentContainerUtils;
}

export class ContentContainerUtils {
    returnState(profile, objct) {
        let mediaContent: any;
        switch (objct) {
            // user switching to off but not saved
            case 'OFF':
              mediaContent = {...profile.mediaContent, state: 'OFF', title: 'OFF' };
              profile = {...profile, mediaType: 'status', mediaContent};
              break;
            // user is switching to on but not saved
            case 'ON':
              mediaContent = {...profile.mediaContent, state: 'ON', title: 'ON' };
              profile = {...profile, mediaType: 'status', mediaContent};
              break;
            case 'clicked':
              return 'clicked';
            case 'CANCEL':
              return 'CANCEL';
            default:
              // if input is status update, save
              if (typeof(objct) === 'object') {
                mediaContent = {...profile.mediaContent, state: 'loading', title: objct.dutyStatus, subTitle: 'NOW' };
                profile = {...profile, mediaType: 'status', mediaContent};
                // switching
              }  else {
                  profile = {...profile, mediaType: 'button'};
                }
                break;
          }
        return profile;
    }
    generateContentModel(title?, state?, description?, subTitle?, mediaType= 'status', subDesc?) {
        return [
          new CardModel(
            {
              'title' : ['Current duty status'],
              'description': description,
              'subDesc' : subDesc,
              'mediaType': mediaType,
              'mediaContent': new ContentCardMediaModel({
                'title' : title,
                'subTitle': subTitle,
                'state': state
              })
            })
        ];
      }
}
