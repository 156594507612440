import { Component, OnInit} from '@angular/core';
import {DriverClock} from '../model/driver-clock';

// will likely move this to a lang service
import lang from '../local/rulesets.json';
import {
    DriverModuleState,
} from '../state/reducers/driver-logs.reducer';
import {Store} from '@ngrx/store';
import {selectDriverClocksState} from '../state/driver-logs.selector';
import {SysvarState} from '../../state/app.reducer';
import {selectSysvars} from '../../state/app.selectors';
import {combineLatest} from 'rxjs';
import {CANADA_OPERATING_ZONE} from '../../driver-logs/driver-event-card/driver-event-header';
import {DeferralState} from '../state/reducers/driver-clocks.reducer';
import {UserInfoService} from '../../user-info.service';

@Component({
  selector: 'app-driver-clocks-list',
  templateUrl: './driver-clock-list.component.html',
  styleUrls: ['./driver-clock-list.component.scss'],
})
export class DriverClockListComponent implements OnInit {
    rulesetLang;
    driverClocks: DriverClock[];
    ruleset: number;
    driverUUID: string;
    driverZone: string;
    canadaSysvar = false;
    isHidden = false;
    deferralState: DeferralState;

    constructor(private driverLogsStore: Store<DriverModuleState>,
                private sysvarStore: Store<SysvarState>) {
    }

    ngOnInit() {
        combineLatest([this.driverLogsStore.select(selectDriverClocksState),
            this.sysvarStore.select(selectSysvars)])
            .subscribe(([state, sysvars]) => {
                this.ruleset = state.rulesetId;
                this.rulesetLang = lang['rulesets'][`${this.getRuleset()}`];
                this.driverClocks = state.clocks.map((x) => ({...x, violationState: x.violation_state, violationCode: x.violation_code}));
                this.driverZone = state.zone;
                if (sysvars && sysvars['ELD_CANADA']) {
                    this.canadaSysvar = true;
                }
                this.deferralState = state.deferralInfo;
        });
        UserInfoService.driverWithNoDS.subscribe(val => {
          this.isHidden = val;
        });
    }
    getRuleset(): number {
        return this.ruleset;
    }

    getRulesetText(): string {
        const rulesetLangData = lang.rulesets[this.getRuleset()];
        return  rulesetLangData && rulesetLangData.name || '';
    }

    getZoneText(): string {
        return CANADA_OPERATING_ZONE[this.driverZone] || 'United States';
    }
}
