import { Title } from '@angular/platform-browser';
import {Component, OnInit, OnChanges, OnDestroy} from '@angular/core';
import {AuthService} from '@auth0/auth0-angular';
import {DatadogService} from './datadog.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnChanges, OnDestroy {
  title = 'Driver Dashboard';
  constructor(
    public auth: AuthService,
    private titleService: Title,
    private datadogService: DatadogService
     ) {
    let url = window.location.pathname.split('/');
    auth.isAuthenticated$.subscribe((x) => {
      //new users may not be auth'd but are allowed to view the tos page
      if (!x && !url.includes('terms-of-service')) {
        auth.loginWithRedirect();
      }
    })

  }
  ngOnInit() {
    this.titleService.setTitle( 'Driver Dashboard' );
    this.datadogService.startSessionReplayRecording();
   }
  ngOnChanges() { }

  ngOnDestroy() {
    this.datadogService.stopSessionReplayRecording();
  }
}
