import {Component, Input, Output, EventEmitter} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { rotateChevron } from '../../shared/animations/animations';
import {AuthService} from '@auth0/auth0-angular';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    animations: [rotateChevron]
})
export class HeaderComponent {
    @Input() userName: string;
    @Input() company: string;
    // @Input() userId: string;
    @Input() logoUrl: string;
    @Input() logoutUrl: string;
    @Input() isAuthorized: boolean;
    @Input() divisions: any;
    @Input() selectedDivision: any;
    @Output() selectedDivisionChange = new EventEmitter<any>();
    accounts: any;
    public expanded = false;

    constructor(
        private snackBar: MatSnackBar,
        public auth: AuthService,
        public http: HttpClient
    ) {
        if (!this.isAuthorized) {
            this.isAuthorized = false;
        }
    }

    logOut() {
        this.auth.logout();
    }

    onClick(e) {
        this.selectedDivisionChange.emit(e);
    }

    resetPassword() {
        this.http.get('/api/v1/users/auth/reset').subscribe(data => {
            window.location.href = data['ticket'];
        }, err => {
            console.log(err);
            this.snackBar.open('failed to request password reset', '', {duration: 2000});
        });
    }

    onMenuOpened() {
      this.expanded = true;
    }

    onMenuClosed() {
      this.expanded = false;
    }
}
