<div class="driver-event-list" role="list" [attr.aria-busy]="isLoading">
  <ng-container *ngIf="isLoading">
    <app-driver-event-card-skeleton></app-driver-event-card-skeleton>
  </ng-container>
  <div *ngFor="let event of events$">
    <div *ngIf="event.single_line; else multi_line">
      <app-driver-event-generic-card
        [driverEvent]="event"
      ></app-driver-event-generic-card>
    </div>
    <ng-template #multi_line>
      <app-driver-event-card
        [driverEvent]="event"
        [isInCertifyMode]="isInCertifyMode"
      ></app-driver-event-card>
    </ng-template>
  </div>
</div>
