import {createFeatureSelector, createSelector} from '@ngrx/store';
import {DriverModuleState} from './reducers/driver-logs.reducer';

export const selectDriverLogModuleState = createFeatureSelector<DriverModuleState>('driver-logs');

export const getDriverEvents = (state: DriverModuleState) => state.driverEvents.events;
export const getDriverClocks = (state: DriverModuleState) => state.driverClocks;
export const getDriverGridData = (state: DriverModuleState) => state ? state.driverGridData : null;
export const getDriverUuid = (state: DriverModuleState) => state.pageInfo.driverUuid;
export const getCurrentDate = (state: DriverModuleState) => state.pageInfo.currentDate;
export const getIsKilometers = (state: DriverModuleState) => state.pageInfo.isKilometers;
export const isDriverEventsLoading = (state: DriverModuleState) => state?.driverEvents.isLoading;
export const getDSEvents = (state: DriverModuleState) => {
    const dsEvents = [];
    state.driverEvents.events.forEach((e) => {
        if (e.event_type_name === 'DUTY_STATUS' || e.event_type_name === 'SDS') {
            dsEvents.push(e);
        }
    });
    return dsEvents;
};
const getDSDuration = (state: DriverModuleState) => state.driverGridData.grid_events;
const getFallbackInfo = (state: DriverModuleState) => state.driverEvents.fallbackInfo;
const getCertifiedStatus = (state: DriverModuleState) => state.driverGridData.isCertified;
const getDeferralInfo = (state: DriverModuleState) => state.driverClocks.deferralInfo;

export const selectDriverEventsLoading = createSelector(
    selectDriverLogModuleState, isDriverEventsLoading
);

export const selectDriverEventsState = createSelector(
    selectDriverLogModuleState, getDriverEvents
);

export const selectDriverClocksState = createSelector(
    selectDriverLogModuleState, getDriverClocks
);

export const selectDriverGridState = createSelector(
    selectDriverLogModuleState, getDriverGridData
);

export const selectDriverId = createSelector(
    selectDriverLogModuleState, getDriverUuid
);

export const selectCurrentDate = createSelector(
    selectDriverLogModuleState, getCurrentDate
);

export const selectDSEvents = createSelector(
    selectDriverLogModuleState, getDSEvents
);

export const selectDSDuration = createSelector(
    selectDriverLogModuleState, getDSDuration
);

export const selectFallbackInfo = createSelector(
    selectDriverLogModuleState, getFallbackInfo
);

export const selectCertifyStatus = createSelector(
    selectDriverLogModuleState, getCertifiedStatus
);

export const selectDeferralInfo = createSelector(
    selectDriverLogModuleState, getDeferralInfo
);

export const selectIsKilometers = createSelector(
    selectDriverLogModuleState, getIsKilometers
);
