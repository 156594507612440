/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Product } from '../models/product';
import { PostProduct } from '../models/post-product';

/**
 * Product lookup.
 */
@Injectable({
  providedIn: 'root',
})
class ProductsService extends __BaseService {
  static readonly deleteAllCompanyProductsApiPath = '/companies/{companyId}/products';
  static readonly getAllCompanyProductsApiPath = '/companies/{companyId}/products';
  static readonly postAllCompanyProductsApiPath = '/companies/{companyId}/products';
  static readonly getSingleCompanyProductApiPath = '/companies/{companyId}/products/{productId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ProductsService.DeleteAllCompanyProductsApiParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `companyId`:
   */
  deleteAllCompanyProductsApiResponse(params: ProductsService.DeleteAllCompanyProductsApiParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/companies/${encodeURIComponent(String(params.companyId))}/products`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProductsService.DeleteAllCompanyProductsApiParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `companyId`:
   */
  deleteAllCompanyProductsApi(params: ProductsService.DeleteAllCompanyProductsApiParams): __Observable<null> {
    return this.deleteAllCompanyProductsApiResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param companyId undefined
   * @return The list of all products from the given company.
   */
  getAllCompanyProductsApiResponse(companyId: string): __Observable<__StrictHttpResponse<Array<Product>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/companies/${encodeURIComponent(String(companyId))}/products`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Product>>;
      })
    );
  }
  /**
   * @param companyId undefined
   * @return The list of all products from the given company.
   */
  getAllCompanyProductsApi(companyId: string): __Observable<Array<Product>> {
    return this.getAllCompanyProductsApiResponse(companyId).pipe(
      __map(_r => _r.body as Array<Product>)
    );
  }

  /**
   * @param params The `ProductsService.PostAllCompanyProductsApiParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `companyId`:
   */
  postAllCompanyProductsApiResponse(params: ProductsService.PostAllCompanyProductsApiParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/companies/${encodeURIComponent(String(params.companyId))}/products`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProductsService.PostAllCompanyProductsApiParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `companyId`:
   */
  postAllCompanyProductsApi(params: ProductsService.PostAllCompanyProductsApiParams): __Observable<null> {
    return this.postAllCompanyProductsApiResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ProductsService.GetSingleCompanyProductApiParams` containing the following parameters:
   *
   * - `productId`:
   *
   * - `companyId`:
   *
   * @return The company-product association.
   */
  getSingleCompanyProductApiResponse(params: ProductsService.GetSingleCompanyProductApiParams): __Observable<__StrictHttpResponse<Product>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/companies/${encodeURIComponent(String(params.companyId))}/products/${encodeURIComponent(String(params.productId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Product>;
      })
    );
  }
  /**
   * @param params The `ProductsService.GetSingleCompanyProductApiParams` containing the following parameters:
   *
   * - `productId`:
   *
   * - `companyId`:
   *
   * @return The company-product association.
   */
  getSingleCompanyProductApi(params: ProductsService.GetSingleCompanyProductApiParams): __Observable<Product> {
    return this.getSingleCompanyProductApiResponse(params).pipe(
      __map(_r => _r.body as Product)
    );
  }
}

module ProductsService {

  /**
   * Parameters for deleteAllCompanyProductsApi
   */
  export interface DeleteAllCompanyProductsApiParams {
    payload: Array<string>;
    companyId: string;
  }

  /**
   * Parameters for postAllCompanyProductsApi
   */
  export interface PostAllCompanyProductsApiParams {
    payload: PostProduct;
    companyId: string;
  }

  /**
   * Parameters for getSingleCompanyProductApi
   */
  export interface GetSingleCompanyProductApiParams {
    productId: string;
    companyId: string;
  }
}

export { ProductsService }
