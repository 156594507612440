import {Pipe, PipeTransform} from '@angular/core';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

export const ZONAR_TIME_FORMAT = {
    datetime: 'MM/DD/YYYY HH:mm',
    datetimeSec: 'MM/DD/YYYY HH:mm:ss',
    date: 'MM/DD/YYYY',
    dateFullDay: 'MM/DD/YYYY - dddd',
    dateLetterFull: 'MMMM Do YYYY',
    time: 'HH:mm:ss',
    timeOnDate: 'HH:mm:ss on MM/DD/YY',
    yearMonthDay: 'YYYY-MM-DD',
    yearMonthDayTime: 'YYYY-MM-DD HH:mm:ss',
    full: 'dddd, MM/DD/YYYY',
    twelveHourTime: 'h A',
    twentyFourTime: 'H'
};

@Pipe({name: 'zonarTimestamp'})
export class ZonarTimestampPipe implements PipeTransform {
    /**
     * @param input
     * @param format
     * @param milisec: if the input is unix epoc, specify this as true will make sure that moment parse with millisec
     * @param timeZone
     * @return {any}
     */
    public transform(input: string|number, format: string, milisec = false, timeZone?: any) {
      const mInput = timeZone ? dayjs.tz(input, timeZone) : dayjs(input);
        if (!input || !ZONAR_TIME_FORMAT[format] || !mInput.isValid()) {
            return '';
        }
        return mInput.format(ZONAR_TIME_FORMAT[format]);
    }

    public static changeToTwelveHrTimeFormat(dataCondition: string) {
      return dayjs(dayjs().set('hour', Number(dataCondition))).format(ZONAR_TIME_FORMAT.twelveHourTime);
    }
}
