import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Store} from '@ngrx/store';
import {CertifyLogsState} from '../state/certify-logs.reducer';
import {selectCertifyMode, selectUncertifiedDays} from '../state/certify-logs.selectors';
import {DriverModuleState} from '../../driver-logs/state/reducers/driver-logs.reducer';
import {SetDate} from '../../driver-logs/state/actions/driver-logs.actions';
import {GetUncertifiedDays, PostDriverCertifyDay, SetCertifyMode} from '../state/certify-logs.actions';
import {combineLatest} from 'rxjs';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(timezone);

@Component({
    selector: 'app-certify-logs-control',
    templateUrl: './certify-logs-control.component.html',
    styleUrls: ['../certify-logs.scss']
})
export class CertifyLogsControlComponent implements OnInit {

    @Output() toggleCertify: EventEmitter<boolean> = new EventEmitter();

    private uncertifiedDays = [];
    public totalOfUncertifiedDays;
    public currentUncertifiedDay;
    public currentIndex;
    constructor(private certifyLogsStore: Store<CertifyLogsState>,
                private driverLogsStore: Store<DriverModuleState>) {
        this.certifyLogsStore.dispatch(new SetCertifyMode(true));
        combineLatest([
            this.certifyLogsStore.select(selectUncertifiedDays),
            this.certifyLogsStore.select(selectCertifyMode)
        ]).subscribe(([days, isInCertifyMode]) => {
            if (isInCertifyMode) {
                this.uncertifiedDays = days;
                this.totalOfUncertifiedDays = this.uncertifiedDays.length;
                this.setUnCertifyDay(0);
            }
        });
    }

    ngOnInit() {
    }

    doLateAction() {
        const newIndex = this.currentIndex + 1;
        if (newIndex === this.totalOfUncertifiedDays) {
            this.exitCertifyLogs();
        } else {
            if ( newIndex < this.totalOfUncertifiedDays) {
                this.setUnCertifyDay(newIndex);
            }
        }
    }

    acceptAction() {
        const param = {
            day: this.currentUncertifiedDay,
            certification_time: +dayjs().unix() // unix timestamp
        };
        this.certifyLogsStore.dispatch(new PostDriverCertifyDay(param));
        if (this.currentIndex + 1 === this.totalOfUncertifiedDays) {
            this.exitCertifyLogs();
        } else {
            this.doLateAction();
        }
    }

    exitCertifyLogs() {
        this.toggleCertify.emit(false);
        this.certifyLogsStore.dispatch(new SetCertifyMode(false));
        this.certifyLogsStore.dispatch(new GetUncertifiedDays());
        this.driverLogsStore.dispatch(new SetDate(dayjs().format('YYYY-MM-DD')));
    }

    private setUnCertifyDay(index: number) {
        this.currentUncertifiedDay = this.uncertifiedDays[index];
        this.currentIndex = index;
        this.driverLogsStore.dispatch(new SetDate(this.currentUncertifiedDay));
    }
}
