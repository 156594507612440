/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Driver } from '../models/driver';
import { DriverCreate } from '../models/driver-create';
import { DriverPatch } from '../models/driver-patch';

/**
 * Driver API
 */
@Injectable({
  providedIn: 'root',
})
class DriversService extends __BaseService {
  static readonly getDriversApiListPath = '/drivers';
  static readonly postDriversApiListPath = '/drivers';
  static readonly getDriversApiResourcePath = '/drivers/{driverId}';
  static readonly patchDriversApiResourcePath = '/drivers/{driverId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Retrieve all Drivers
   *
   * Get all drivers, or a list containing a single driver by userId.
   * URL Parameters:
   *     userId (str): The UUID of the User the driver represents
   *
   * On success returns an array of driver payloads constrained
   * by any query parameters.
   * @param userId undefined
   * @return Success
   */
  getDriversApiListResponse(userId?: string): __Observable<__StrictHttpResponse<Array<Driver>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (userId != null) __params = __params.set('userId', userId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/drivers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Driver>>;
      })
    );
  }
  /**
   * Retrieve all Drivers
   *
   * Get all drivers, or a list containing a single driver by userId.
   * URL Parameters:
   *     userId (str): The UUID of the User the driver represents
   *
   * On success returns an array of driver payloads constrained
   * by any query parameters.
   * @param userId undefined
   * @return Success
   */
  getDriversApiList(userId?: string): __Observable<Array<Driver>> {
    return this.getDriversApiListResponse(userId).pipe(
      __map(_r => _r.body as Array<Driver>)
    );
  }

  /**
   * Create a Driver
   *
   * Create a driver.
   * Uses the driver_api.apis.api_v1.api_models.driver_create_model
   * for incoming data.
   * @param payload undefined
   * @return Success
   */
  postDriversApiListResponse(payload: DriverCreate): __Observable<__StrictHttpResponse<Driver>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/drivers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Driver>;
      })
    );
  }
  /**
   * Create a Driver
   *
   * Create a driver.
   * Uses the driver_api.apis.api_v1.api_models.driver_create_model
   * for incoming data.
   * @param payload undefined
   * @return Success
   */
  postDriversApiList(payload: DriverCreate): __Observable<Driver> {
    return this.postDriversApiListResponse(payload).pipe(
      __map(_r => _r.body as Driver)
    );
  }

  /**
   * Retrieve a single Driver
   *
   * Retrieve a single driver by ID.
   * @param driverId undefined
   * @return Success
   */
  getDriversApiResourceResponse(driverId: string): __Observable<__StrictHttpResponse<Driver>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/drivers/${encodeURIComponent(String(driverId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Driver>;
      })
    );
  }
  /**
   * Retrieve a single Driver
   *
   * Retrieve a single driver by ID.
   * @param driverId undefined
   * @return Success
   */
  getDriversApiResource(driverId: string): __Observable<Driver> {
    return this.getDriversApiResourceResponse(driverId).pipe(
      __map(_r => _r.body as Driver)
    );
  }

  /**
   * Patch a Driver
   *
   * Update an existing Driver.
   * Uses the driver_api.apis.api_v1.api_models.driver_patch_model
   * for incoming data.
   * @param params The `DriversService.PatchDriversApiResourceParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `driverId`:
   *
   * @return Success
   */
  patchDriversApiResourceResponse(params: DriversService.PatchDriversApiResourceParams): __Observable<__StrictHttpResponse<Driver>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/drivers/${encodeURIComponent(String(params.driverId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Driver>;
      })
    );
  }
  /**
   * Patch a Driver
   *
   * Update an existing Driver.
   * Uses the driver_api.apis.api_v1.api_models.driver_patch_model
   * for incoming data.
   * @param params The `DriversService.PatchDriversApiResourceParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `driverId`:
   *
   * @return Success
   */
  patchDriversApiResource(params: DriversService.PatchDriversApiResourceParams): __Observable<Driver> {
    return this.patchDriversApiResourceResponse(params).pipe(
      __map(_r => _r.body as Driver)
    );
  }
}

module DriversService {

  /**
   * Parameters for patchDriversApiResource
   */
  export interface PatchDriversApiResourceParams {
    payload: DriverPatch;
    driverId: string;
  }
}

export { DriversService }
