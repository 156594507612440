/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Location } from '../models/location';
import { PostLocation } from '../models/post-location';
import { PatchLocation } from '../models/patch-location';

/**
 * Location lookup.
 */
@Injectable({
  providedIn: 'root',
})
class LocationsService extends __BaseService {
  static readonly getAllLocationsApiPath = '/companies/{companyId}/locations';
  static readonly postAllLocationsApiPath = '/companies/{companyId}/locations';
  static readonly getSingleLocationsApiPath = '/companies/{companyId}/locations/{locationId}';
  static readonly patchSingleLocationsApiPath = '/companies/{companyId}/locations/{locationId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param companyId undefined
   * @return The list of all locations within the given company.
   */
  getAllLocationsApiResponse(companyId: string): __Observable<__StrictHttpResponse<Array<Location>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/companies/${encodeURIComponent(String(companyId))}/locations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Location>>;
      })
    );
  }
  /**
   * @param companyId undefined
   * @return The list of all locations within the given company.
   */
  getAllLocationsApi(companyId: string): __Observable<Array<Location>> {
    return this.getAllLocationsApiResponse(companyId).pipe(
      __map(_r => _r.body as Array<Location>)
    );
  }

  /**
   * @param params The `LocationsService.PostAllLocationsApiParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `companyId`:
   *
   * @return The location to add to the given company.
   */
  postAllLocationsApiResponse(params: LocationsService.PostAllLocationsApiParams): __Observable<__StrictHttpResponse<Array<Location>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/companies/${encodeURIComponent(String(params.companyId))}/locations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Location>>;
      })
    );
  }
  /**
   * @param params The `LocationsService.PostAllLocationsApiParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `companyId`:
   *
   * @return The location to add to the given company.
   */
  postAllLocationsApi(params: LocationsService.PostAllLocationsApiParams): __Observable<Array<Location>> {
    return this.postAllLocationsApiResponse(params).pipe(
      __map(_r => _r.body as Array<Location>)
    );
  }

  /**
   * @param params The `LocationsService.GetSingleLocationsApiParams` containing the following parameters:
   *
   * - `locationId`:
   *
   * - `companyId`:
   *
   * @return A specific location from a specific company, by uuid.
   */
  getSingleLocationsApiResponse(params: LocationsService.GetSingleLocationsApiParams): __Observable<__StrictHttpResponse<Location>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/companies/${encodeURIComponent(String(params.companyId))}/locations/${encodeURIComponent(String(params.locationId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Location>;
      })
    );
  }
  /**
   * @param params The `LocationsService.GetSingleLocationsApiParams` containing the following parameters:
   *
   * - `locationId`:
   *
   * - `companyId`:
   *
   * @return A specific location from a specific company, by uuid.
   */
  getSingleLocationsApi(params: LocationsService.GetSingleLocationsApiParams): __Observable<Location> {
    return this.getSingleLocationsApiResponse(params).pipe(
      __map(_r => _r.body as Location)
    );
  }

  /**
   * @param params The `LocationsService.PatchSingleLocationsApiParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `locationId`:
   *
   * - `companyId`:
   */
  patchSingleLocationsApiResponse(params: LocationsService.PatchSingleLocationsApiParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;


    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/companies/${encodeURIComponent(String(params.companyId))}/locations/${encodeURIComponent(String(params.locationId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `LocationsService.PatchSingleLocationsApiParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `locationId`:
   *
   * - `companyId`:
   */
  patchSingleLocationsApi(params: LocationsService.PatchSingleLocationsApiParams): __Observable<null> {
    return this.patchSingleLocationsApiResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module LocationsService {

  /**
   * Parameters for postAllLocationsApi
   */
  export interface PostAllLocationsApiParams {
    payload: PostLocation;
    companyId: string;
  }

  /**
   * Parameters for getSingleLocationsApi
   */
  export interface GetSingleLocationsApiParams {
    locationId: string;
    companyId: string;
  }

  /**
   * Parameters for patchSingleLocationsApi
   */
  export interface PatchSingleLocationsApiParams {
    payload: PatchLocation;
    locationId: string;
    companyId: string;
  }
}

export { LocationsService }
