import {Component, Input, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {DriverEvent} from '../model/driver-event';
import {DriverModuleState} from '../state/reducers/driver-logs.reducer';
import {selectCurrentDate, selectDriverEventsLoading, selectDriverEventsState, selectFallbackInfo} from '../state/driver-logs.selector';
import {combineLatest} from 'rxjs';
import {FallbackInfo} from '../state/reducers/driver-events.reducer';
import lang from '../local/rulesets.json';
import * as _ from 'lodash';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(timezone);

@Component({
    selector: 'app-driver-event-list',
    templateUrl: './driver-event-list.component.html',
    styleUrls: ['./driver-event-list.component.css']
})
export class DriverEventListComponent implements OnInit {
    @Input() isInCertifyMode: Boolean = false;
    events$: DriverEvent[];
    driverUUID: string;
    isLoading: boolean = true;

    constructor(private driverLogsStore: Store<DriverModuleState>) {
    }

    ngOnInit() {
        this.driverLogsStore.select(selectDriverEventsLoading).subscribe(x => this.isLoading = x);

        combineLatest([
            this.driverLogsStore.select(selectDriverEventsState),
            this.driverLogsStore.select(selectFallbackInfo),
            this.driverLogsStore.select(selectCurrentDate)
        ])
        .subscribe(([events, fallbackInfo, date]: [DriverEvent[], FallbackInfo, string]) => {
            if (this.aFallbackEventShouldBeCreated(fallbackInfo, events, date)) {
                this.events$ = this.createFallbackEvent(fallbackInfo, events, date);
            } else {
                this.events$ = events;
            }
        });
    }

    private aFallbackEventShouldBeCreated(fallbackInfo: FallbackInfo, events: DriverEvent[], date: string): Boolean {
        if (fallbackInfo) {
            if (!fallbackInfo.fallback_history || fallbackInfo.fallback_history.length === 0) {
                return false;
            }
            const fallbackState = fallbackInfo.fallback_history[0];
            const fallbackDate = dayjs.unix(fallbackState.triggered_epoch).format('YYYY-MM-DD');
            if (fallbackDate !== date) {
                return false;
            }
            return true;
        } else {
            return false;
        }
    }

    private createFallbackEvent(fallbackInfo: FallbackInfo, events: DriverEvent[], date: string): DriverEvent[] {
        const fallbackState = fallbackInfo.fallback_history[0];
        const fallbackEvent = new DriverEvent();
        fallbackEvent.event_type_name = 'FALLBACK';
        fallbackEvent.event_code_id = fallbackState.reason;
        fallbackEvent.driver_ts = dayjs.unix(fallbackState.triggered_epoch).format('YYYY-MM-DD HH:mm:ss');
        fallbackEvent.epoch = fallbackState.start_epoch;
        fallbackEvent.data = {
            ruleset: lang['rulesets'][fallbackState.to_ruleset]['name'],
            distance: fallbackInfo.radius_monitor.miles_from_center,
            time: dayjs.unix(fallbackState.start_epoch).format('HH:mm:ss')
        };
        fallbackEvent.display_icon = true;
        fallbackEvent.single_line = true;
        const hasFallback = events.findIndex((e) => {
            return e.event_type_name === 'FALLBACK';
        });
        if (hasFallback === -1) {
            events.push(fallbackEvent);
        }
        return _.sortBy(events, (x) => {
            return x.driver_ts;
        }).reverse();
    }
}
