import {
  Actions,
  GET_DRIVER_GRID_DATA,
  GET_DRIVER_GRID_DATA_SUCCESS
} from '../actions/driver-grid-data.actions';
import {DriverGridDutyStatus} from '../../model/driver-grid-duty-status';
import {DutyStatusTotal} from '../../model/driver-duty-status-total';

export interface DriverGridDataState {
    grid_events: DriverGridDutyStatus[];
    duty_status_totals: DutyStatusTotal[];
    driver_time_zone: string;
    params: any;
    isCertified: boolean;
    zone: 'S_60' | 'N_60' | 'US' | null;
}

export const initialState: DriverGridDataState = {
    params: {},
    grid_events: [],
    duty_status_totals: [],
    driver_time_zone: '',
    isCertified: false,
    zone: null
};

export function driverGridDataReducer(state = initialState, action: Actions): DriverGridDataState {
    switch (action.type) {
        case GET_DRIVER_GRID_DATA:
            return state;
        case GET_DRIVER_GRID_DATA_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}
