import * as lang from './driver-event-header';
import {DriverEvent} from '../model/driver-event';
import dayjs from 'dayjs';
import {ZonarTimestampPipe} from '../pipe/zonar-timestamp.pipe';

/** utility function to replace source string with some replacement strings
 * @param {string} str: source text with target code(s) to replace
 * @param {object} params: key-value pair of target and replacement texts,
 * e.g, {'origin': 'Drive', 'code': 'Off Duty'}
 * @return {string} new string with original replaced with target strings from params
 */
export function replaceAll(str: string, params: any) {
    if (!params) {
        return str;
    }
    const re = new RegExp(Object.keys(params)
        .map(function (key) {
            return ':' + key;
        })
        .join('|'), 'g');
    return str.replace(re, function (matched: any) {
        return params[matched.slice(1)];
    });
}

function getOrigin(originName: string) {
    return lang.ORIGIN[originName];
}

export function getEventHeader(event: DriverEvent): string {
  try {
      const event_type_name = event.event_type_name;
      switch (event_type_name) {
        case 'SLEEPER_BERTH_SPLIT':
          return getSleeperBerthSplitHeader(event);
        case 'DRIVER_DAY_START_CHANGE':
          return getDriverDayStart(event);
        case 'METADATA':
          return getMetadataHeader(event);
        case 'SYSTEM_DESCRIPTOR':
          return getSystemDescriptorHeader(event);
        case 'DIAG_MAL':
          return getDiagMalHeaders(event);
        case 'SDS':
          return getSpecialDutyStatusHeader(event);
        case 'ASSET_CHANGE':
          return getAssetChangeHeader(event);
        case 'CERTIFICATION':
          return getCertificationHeader(event);
        case 'FALLBACK':
          return getFallbackHeader(event);
        case 'DUTY_STATUS':
          return getDutyStatusHeader(event);
        case 'APPLIED_USER_DESCRIPTOR':
          return adverseWeatherHeader(event);
        case 'CANADA_OPERATING_ZONE':
          return getZoneChangeHeader(event);
        case 'DEFERRAL_STATE':
          return getDeferralHeader(event);
        default:
          return genericHeader(event);
      }
    } catch (e) {
    console.log(e);
    return '';
  }
}

function getSleeperBerthSplitHeader(event: DriverEvent): string {
  const splitSleeperBerthLang = lang.SLEEPER_BERTH_SPLIT[event.event_code_name];
  return splitSleeperBerthLang.header;
}

function getDriverDayStart(event: DriverEvent): string {
  const driverStartLang = lang.DRIVER_DAY_START_CHANGE[event.event_code_name];
  let header;
  let dataCondition;
  if (event.data && event.data.condition) {
    header = driverStartLang.special;
    dataCondition = ZonarTimestampPipe.changeToTwelveHrTimeFormat(event.data.condition) || event.data.condition;
  } else {
    header = driverStartLang.header;
  }
  return replaceAll(header, { data: dataCondition });
}


function genericHeader(event: DriverEvent): string {
    const origin = getOrigin(event.event_record_origin_name);
    const header = lang[event.event_type_name].header;
    const params = lang[event.event_type_name][event.event_code_name];
    return replaceAll(header, {...params, origin: origin});
}

function getCertificationHeader(event: DriverEvent): string {
    const header = genericHeader(event);
    let log_date: 1;
    let formattedDate;
    if (event.data && event.data.log_day) {
        log_date = event.data.log_day; // todo reformat this
        formattedDate = dayjs.unix(log_date).format('MM/DD/YYYY');
    }
    return replaceAll(header, {date: formattedDate});
}

function getMetadataHeader(event: DriverEvent): string {
    let header;
    let params;
    if (event.event_code_name.includes('CO_DRIVER')) {
        header = lang.METADATA['CO_DRIVER'].header;
        params = {
            ...lang.METADATA['CO_DRIVER'][event.event_code_name],
            name: event.data.co_driver_first_name + ' ' + event.data.co_driver_last_name
        };
    } else {
        header = lang.METADATA['TRAILER_DOLLY_LOAD'].header;
        params = lang.METADATA['TRAILER_DOLLY_LOAD'][event.event_code_name];
        if (event.event_code_name.includes('LOAD')) {
            params = {
                ...params,
                name: event.data.doc_num
            };
        } else {
            params = {
                ...params,
                name: event.data.num
            };
        }
    }
    return replaceAll(header, params);
}

function getDiagMalHeaders(event: DriverEvent): string {
    let params;
    let diagMalLang;
    const action = event.event_code_name.includes('SET') ? 'set' : 'clear';
    if (event.event_code_name.includes('MALFUNCTION')) {
        diagMalLang = lang.DIAG_MAL.MALFUNCTION;
        params = diagMalLang[event.mcode_name];
    } else {
        diagMalLang = lang.DIAG_MAL.DIAGNOSTIC;
        params = diagMalLang[event.dcode_name];
    }
    return replaceAll(diagMalLang[action], params);
}

function getSystemDescriptorHeader(event: DriverEvent): string {
    const sysDescLang = lang.SYSTEM_DESCRIPTOR[event.event_code_name];
    let header;
    let params;
    if (event.data && event.data.condition) {
        header = sysDescLang.special;
        params = { data: event.data.condition };
    } else {
        header = sysDescLang.header;
    }
    return replaceAll(header, params);
}

function getSpecialDutyStatusHeader(event: DriverEvent): string {
    const event_code_name = event.event_code_name;
    const origin = getOrigin(event.event_record_origin_name);
    let header;
    let params;
    if (event_code_name.includes('CLEAR')) {
        header = lang.SDS.clear_sds.header;
        params = lang.SDS.clear_sds[event_code_name];
    } else {
        header = lang.SDS.on_sds.header;
        params = lang.SDS.on_sds[event_code_name];
    }
    return replaceAll(header, {...params, origin: origin});
}

function getAssetChangeHeader(event: DriverEvent): string {
    const header = lang.ASSET_CHANGE;
    const origin = getOrigin(event.event_record_origin_name);
    let eventData = {} as any;
    if (event.data) {
        eventData = event.data;
    }
    let value;
    switch (origin) {
        case 'ELD': // aka System
            value =  `${header.login} PUN: ${eventData.pun || 'N/A' }, VIN: ${eventData.vin || 'N/A'},` +
            ` License Plate: ${eventData.license_plate_no || 'N/A'}, ` +
            `Asset Number: ${eventData.asset_no || 'N/A'}`;
            break;
        case 'Driver':
            value = `${header.header} New PUN: ${eventData.pun || 'N/A'}`;
            break;
        case 'Carrier':
            value = `${header.asset} ${eventData.extra_header}`;
            break;
        case 'Unidentified':
            value = `${header.udl} PUN: ${eventData.pun || 'N/A'}`;
            break;
        default:
            value = header.asset;
            break;
    }
    return replaceAll(value, {origin: origin});
}

function getFallbackHeader(event: DriverEvent): string {
    const fallbackReasons = lang.FALLBACK_REASONS;
    const header = fallbackReasons[event.event_code_id];
    return replaceAll(header, event.data);
}

function getDutyStatusHeader(event: DriverEvent): string {
    if (event.event_record_origin_name === 'UNIDENTIFIED_DRIVING') {
        return 'Assumed from Unidentified Driving';
    }
    return genericHeader(event);
}

function adverseWeatherHeader (event: DriverEvent) {
    if (event.event_code_name === 'ADVERSE_WEATHER') {
        return event.data.user_notes;
    }
    return genericHeader(event);
}

// todo: this function is getting called constantly, when it should be called once-ish
function getZoneChangeHeader(event: DriverEvent): string {
    const eventRecordOriginName: string = event.event_record_origin_name.toUpperCase();
    const origin = getOrigin(eventRecordOriginName) || 'Driver';
    const header = lang[event.event_type_name].header;
    const operatingZoneName = lang[event.event_type_name][event.event_code_name] || 'United States';
    return replaceAll(header, {origin: origin, operating_zone: operatingZoneName});
}

function getDeferralHeader(event: DriverEvent): string {
    switch (event.event_code_id) {
        case 0:
            return `Driver entered Day 0 of Off Duty Deferral`;
            break;

        case 1:
            return `Driver declared intent to defer ${getDeferralTimeStringForHeaderText(event.data.time_deferred)}, and entered Day 1 of Off Duty Deferral`;
            break;

        case 2:
            return `Driver entered Day 2 of Off Duty Deferral`;
            break;

        default:
            return `Deferral event - unknown event code ID`;
    }
}

// "X hour/hours and X minute/minutes"
export function getDeferralTimeStringForHeaderText(timeDeferred: string): string {
    const timePieces: string[] = timeDeferred.split(':');
    const hours: number = Number(timePieces[0]);
    const minutes: number = Number(timePieces[1]);

    let deferralTimeString = '';

    switch (hours) {
        case 0:
            // intentionally do nothing
            break;

        case 1:
            deferralTimeString += minutes > 0 ? '1 hour and ' : '1 hour';
            break;

        case 2:
            deferralTimeString += '2 hours'; // max amount of time
            break;
    }

    switch (minutes) {
        case 0:
            // intentionally do nothing
            break;

        case 1:
            deferralTimeString += '1 minute';
            break;

        default:
            deferralTimeString += `${minutes} minutes`;
    }

    return deferralTimeString;
}
