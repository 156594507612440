import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-driver-event-card-skeleton',
  templateUrl: './driver-event-card-skeleton.component.html',
  styleUrls: ['../../driver-event-card.component.scss', './driver-event-card-skeleton.component.scss']
})
export class DriverEventCardSkeletonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
