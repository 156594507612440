import { Injectable } from '@angular/core';
import {AuthService} from '@auth0/auth0-angular';
import {ApiMiddlewareService} from './api-middleware/api-middleware.service';
import {catchError, delay, finalize, map, switchMap, take, takeUntil} from 'rxjs/operators';
import {NgxPendoService} from 'ngx-pendo';
import {Store} from '@ngrx/store';
import {SysvarState} from './state/app.reducer';
import {
  GetDriverCompanySucess,
  GetDriverDivisionSucess,
  GetDriverProfileUuidSucess,
  GetDriverUserNameSucess
} from './state/app.actions';
import {DriverModuleState} from './driver-logs/state/reducers/driver-logs.reducer';
import {SetDriverUuid} from './driver-logs/state/actions/driver-logs.actions';
import {BehaviorSubject, interval, Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {
  driverProfileUuid;
  companyId;
  //todo: put this into state for others to grab from?
  driverInformation = {
    userName: '',
    company: '',
    userId: '',
    divisions: [],
    currentDivision: '',
    authStatus: false,
  }

  static driverWithNoDS = new BehaviorSubject<boolean>(false);

  userId = new BehaviorSubject<string>('');

  constructor(
    private authService: AuthService,
    private apiService: ApiMiddlewareService,
    protected ngxPendoService: NgxPendoService,
    private sysvarStore: Store<SysvarState>,
    private driverLogsStore: Store<DriverModuleState>,
  ) {

    authService.isAuthenticated$.pipe(take(1)).subscribe((authStatus) => {
      this.driverInformation.authStatus = authStatus;
    });

    this.getUserSubPromise().then(f => {
      if (f && f['sub']) {
        this.userId.next(f['sub']);
        this.getUserInfo();
      }
    }).catch(e => {
      console.error(e);
      //Todo: determine error handling and/or page routing
    });
  }

  private getUserSubPromise() {
    return new Promise(resolve => {
      this.authService.user$.subscribe(e => {
        resolve(e);
      });
    });
  }

  private getUserInfo() {
    this.userId.pipe(
      map((user) => {
        if (user && user !== '') {
          localStorage.setItem('sub', user);
          return user;
        }
      }),
      switchMap((user) => {
        return this.apiService.getUser(user)}),
      map((driver) => {
        this.sysvarStore.dispatch(new GetDriverUserNameSucess({}, `${driver[0].firstName} ${driver[0].lastName}`));
        this.driverInformation.userName = `${driver[0].firstName} ${driver[0].lastName}`;
        return driver[0]
      }),
      switchMap((driver) => this.apiService.getDriver(driver.id)),
      map((result) => {
        return result;
      }),
      switchMap((r) => this.apiService.getDriverProfile(r.id)),
      map((a: any) => {
        this.sysvarStore.dispatch(new GetDriverProfileUuidSucess({}, a[0].id));
        this.driverLogsStore.dispatch(new SetDriverUuid(a[0].id));
        this.driverInformation.userId = a[0].id;
        this.companyId = a[0].companyId;
        this.driverInformation.divisions.push(a[0].divisionId);
        return a[0];
      }),
      switchMap(() => this.apiService.getCompanyProfile(this.companyId)),
      map((company) => {
        this.driverInformation.company = company.name;
        this.sysvarStore.dispatch(new GetDriverCompanySucess({}, company.name));
        return;
      }),
      switchMap(() => this.apiService.getDivisions(this.companyId, this.driverInformation.divisions)),
      map((divisions) => {
        this.driverInformation.currentDivision = divisions[0].name;
        this.sysvarStore.dispatch(new GetDriverDivisionSucess({}, divisions[0].name));
        this.initalizePendo(divisions[0]);
        return;
      }),
      switchMap(() => this.apiService.logDriverOutOfTablet(this.driverInformation.userId)),
      catchError(err => {
        console.error(err);
        //Todo: determine error handling and/or page routing
        return err;
      })
    ).subscribe();
  }


  private initalizePendo(division ) {
    const accountName = division.name;
    const id = division.legacyAccountCode;
    // initalize pendo
    this.ngxPendoService.initialize({
      id: localStorage.getItem('sub'),
      role: 'user',
      name: this.driverInformation.userId
    }, {
      id: id,
      name: accountName
    });
  }
}
