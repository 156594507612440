import {animate, state, style, transition, trigger} from '@angular/animations';

export const rotateChevron = trigger('rotateChevron', [
  state('true',
    style({
      transform: 'rotate(180deg)'
    })
  ),
  state('false',
    style({
      transform: 'rotate(0deg)'
    })
  ),
  transition('true <=> false', animate('200ms ease-out')),
]);
