import {Action} from '@ngrx/store';

export const GET_UNCERTIFIED_DAYS = '[Get] CALL_UNCERTIFIED_DAYS_API';
export const GET_UNCERTIFIED_DAYS_SUCCESS = '[Get] GET_UNCERTIFIED_DAYS_SUCCESS';

export const POST_DRIVER_CERTIFY_DAY = '[Post] CALL_CERTIFY_DAY_API';
export const POST_DRIVER_CERTIFY_DAY_SUCCESS = '[Post] CALL_CERTIFY_DAY_SUCCESS';

export const SET_CERTIFY_MODE = '[Set] SET_CERTIFY_MODE';

export interface DriverCertifyDay {
    day: string;
    certification_time: number;
}

export class GetUncertifiedDays implements Action {
    readonly type = GET_UNCERTIFIED_DAYS;
    constructor() {}
}

export class GetUncertifiedDaysSuccess implements Action {
    readonly type = GET_UNCERTIFIED_DAYS_SUCCESS;
    constructor(public payload: { days: string[], oldest_uncertified_day: number}) {}
}

export class PostDriverCertifyDay implements Action {
    readonly type = POST_DRIVER_CERTIFY_DAY;
    constructor(public params: DriverCertifyDay) {}
}

export class PostDriverCertifyDaySuccess implements Action {
    readonly type = POST_DRIVER_CERTIFY_DAY_SUCCESS;
    constructor(public payload: { response: string, day: string}) {}
}

export class SetCertifyMode implements Action {
    readonly type = SET_CERTIFY_MODE;
    constructor(public isInCertifyMode: boolean) {}
}

export type CertifyLogsAction =
    | GetUncertifiedDays
    | GetUncertifiedDaysSuccess
    | PostDriverCertifyDay
    | PostDriverCertifyDaySuccess
    | SetCertifyMode;
