import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiMiddlewareService } from './api-middleware.service';

import { ApiModule as UserApiModule } from './api-service/user/api.module';
import { ApiModule as CompanyApiModule } from './api-service/company/api.module';
import { ApiModule as DriverApiModule } from './api-service/driver/api.module';
import { ApiModule as ComplianceApiModule } from './api-service/compliance/api.module';
export { ApiMiddlewareService };

@NgModule({
  imports: [
    CommonModule,
    UserApiModule,
    CompanyApiModule,
    DriverApiModule,
    ComplianceApiModule
  ],
  declarations: [],
})
export class ApiMiddlewareModule {
  static forRoot(): ModuleWithProviders<ApiMiddlewareModule> {
    return {
      ngModule: ApiMiddlewareModule,
      providers: [ ApiMiddlewareService ]
    };
  }
 }
