export class CardModel {
    public constructor(init?: Partial<CardModel>) {
        Object.assign(this, init);
    }
    public title: Array<any>;
    public description: string;
    public subDesc: string;
    public mediaType: string;
    public mediaContent?: ContentCardMediaModel;
}

export class ContentCardMediaModel {
    public constructor(init?: Partial<ContentCardMediaModel>) {
        Object.assign(this, init);
    }
    public title?: string;
    public subTitle?: string;
    public color?: string;
    public imgUrl?: string;
    public state?: string;
}
